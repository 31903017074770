export const dropdownSportOptions = [
  'All', "Texas Hold'em"
];

export const dropdownMarketOptions = [
  'All', 'Win'
];

export const sportOptionsId = [
  { title: 'All', id: -1 },
  { title: "Texas Hold'em", id: 4 }
];

export const marketOptionsId = [
  { title: 'All', id: "ALL" },
  { title: 'Win', id: "WIN_ONLY" }
];

export const defaultFilterValues = {
  sportDropdown: "All",
  marketTypeDropdown: "All",
  IDInput: "",
  nameInput: ""
}