import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1 from '../../../static/images/help/account_statement_agency.png'

export default () => {
  const { userLevel } = useHelpStorage()

  return <div>
    <h1>Account Statement</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to view your account statement.</p>

      <p className="last-p">
        The account statement lists all the changes that have affected your balance.
            <strong>P&L Statement</strong> shows your profit and loss based on market settlement,
            whilst <strong>Credit Statement</strong> shows the changes in your credit limit as well as your available credit.
            </p>

      <Image src={Img1} />

      <p className="last-p">
        To view the account statement, follow this procedure.<br />
                Navigate to the <strong>Account</strong> &gt; <strong>Statement</strong> menu item.
                By default, today's taken place today are displayed.<br />
                Specify a time frame using the <strong>From</strong> and <strong>To</strong> drop-down menus and click the <strong>Search</strong>
                &nbsp;button to display the P&L statement for that time frame.
                The <strong>Description</strong> field explains why your account balance has changed.<br />
                Betting P&L - Settlement by market or by selection.<br />
                Betting P&L Comm Adjustment - Commission adjustment. Only happens for outright markets once the market is closed.<br />
                Betting P&L Adjustment - Market resettlement or invalidation of settled bets.<br />
                Click a market or selection to see the breakdown of the bets.
            </p>
    </div>
  </div>
}