import { useState } from 'react'
import './index.scss'

export default ({ options = ["PENDING", "APPROVED"], title = 'Status', changeValue}) => {
  let [showOption, setShowOption] = useState(false)
  let [selectedOption, setSelectedOption] = useState('')

  const handleListDisplay = () => {
    setShowOption(prevState => !prevState)
  }
  const handleOptionClick = e => {
    setSelectedOption(e.target.innerHTML);
    setShowOption(false);
    changeValue(e.target.innerHTML, "status" )
  };

  return (
    <div className="status-dropdown">
      <div className={showOption ? "status-dropdown-selected active" : "status-dropdown-selected"} onClick={handleListDisplay}>
        {selectedOption || title}
      </div>
      {showOption && (
        <ul className="status-dropdown-options">
          {options.map(item => <li className="status-dropdown-option" key={item} onClick={handleOptionClick}>{item}</li>)}
        </ul>
      )}
    </div>
  )
}
