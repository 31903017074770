import React, { memo } from 'react'
import { Table } from "react-bootstrap"
import CurrentTime from '../../components/CurrentTime'
import StringToRecolorNumber from "../../components/StringToRecolorNumber"


export default memo(({ data }) => {
  return <>
    <Table striped hover variant="dark">
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>To/From</th>
          <th>Amount</th>
          <th>Credit Balance</th>

        </tr>
      </thead>
      <tbody>
        {data && data.statement && data.statement.length ? data.statement.map((item) => {
          return <tr key={item.date}>
            <td>{<CurrentTime format='DD/MM/YYYY HH:mm:ss'>{item.date}</CurrentTime>}</td>
            <td>{item.actionDisplayName}</td>
            <td>{item.description}</td>
            <td><StringToRecolorNumber>{item.amount}</StringToRecolorNumber></td>
            <td><StringToRecolorNumber>{item.balance}</StringToRecolorNumber></td>
          </tr>
        }) : <></>}
      </tbody>
    </Table>
  </>
})
