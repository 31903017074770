import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1Agent from '../../../static/images/help/transfer_agent.png'
import Img1MA from '../../../static/images/help/transfer_ma.png'
import Img1SMA from '../../../static/images/help/transfer_sma.png'
import Img2Agent from '../../../static/images/help/menu_transfer_agent.png'
import Img2MA from '../../../static/images/help/menu_transfer_ma.png'
import Img2SMA from '../../../static/images/help/menu_transfer_sma.png'
import Img3Agent from '../../../static/images/help/button_transfer_agent.png'
import Img3MA from '../../../static/images/help/button_transfer_ma.png'
import Img3SMA from '../../../static/images/help/button_transfer_sma.png'
import Img4Agent from '../../../static/images/help/popup_transfer_amount_agent.png'
import Img4MA from '../../../static/images/help/popup_transfer_amount_ma.png'
import Img4SMA from '../../../static/images/help/popup_transfer_amount_sma.png'
import Img5Agent from '../../../static/images/help/transfer_new_balance_agent.png'
import Img5MA from '../../../static/images/help/transfer_new_balance_ma.png'
import Img5SMA from '../../../static/images/help/transfer_new_balance_sma.png'
import HelpMenuLink from "../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const childrenAppeal = userLevel === "sma" ?
    "an MA" : userLevel === "ma" ? "an Agent" : "a Member";

  return <div>
    <h1>Transfer</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to carry out a transfer between you and {childrenAppeal}.</p>
      <br />

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <p>In the <strong>Transfer</strong> window, you can get an overview of the credit status of your {childrenLevel}s.</p>

      <p>
        The green number in the <strong>Take</strong> column shows how much the {childrenLevel} owes you,
                while the red in the <strong>Give</strong> column shows how much you owe the specific {childrenLevel}.
            </p>

      <p>
        If the transfer between you and the {childrenLevel} has taken place, you can make a note of this by following this procedure:
            </p>

      <p className="last-p">Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>Transfer</strong> menu.</p>

      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">Click the <strong>Transfer</strong> link next to the {childrenLevel} you have performed a transfer with.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p>
        Enter the transferred amount in the <strong>Transfer amount</strong> field and click the <strong>Transfer</strong> button.
            By default, the total balance is shown in the <strong>Transfer amount</strong> field but you can freely change it.
            </p>

      <Image src={userLevel === "agent" ? Img4Agent :
        userLevel === "ma" ? Img4MA : Img4SMA} />

      <p className="last-p">
        You can see an updated value for the specific user in the <strong>Bal.(Up)</strong> column.
            </p>

      <Image src={userLevel === "agent" ? Img5Agent :
        userLevel === "ma" ? Img5MA : Img5SMA} />


      <p className="last-p">
        <HelpMenuLink strong>Undo Transfer</HelpMenuLink><br />
                This section describes how to undo a transfer between you and {childrenAppeal}.
            </p>
    </div>
  </div>
}