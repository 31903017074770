import { positiveOrNegative } from "../../../utils/positiveOrNegative"
const Ladder = ({ response }) => {
  return (
    <td colSpan="2" style={{ padding: "0" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr className="title-ladder">
            <td>Runs</td>
            <td>P&L</td>
          </tr>
          {response?.map(el => (
            <tr key={el.start} className="body-ladder">
              <td>
                {el.start}
                {el.end ? ` -  ${el.end}` : "+"}
              </td>
              <td className={positiveOrNegative(el.exposure)}>{parseFloat(el.exposure).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </td>
  )
}
export default Ladder
