import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/undo_transfer_agent.png'
import Img1MA from '../../../../static/images/help/undo_transfer_ma.png'
import Img1SMA from '../../../../static/images/help/undo_transfer_sma.png'
import Img2Agent from '../../../../static/images/help/menu_transfer_agent.png'
import Img2MA from '../../../../static/images/help/menu_transfer_ma.png'
import Img2SMA from '../../../../static/images/help/menu_transfer_sma.png'
import Img3 from '../../../../static/images/help/button_undo_transfer.png'
import Img4Agent from '../../../../static/images/help/button_undo_transfer_agent.png'
import Img4MA from '../../../../static/images/help/button_undo_transfer_ma.png'
import Img4SMA from '../../../../static/images/help/button_undo_transfer_sma.png'
import Img5Agent from '../../../../static/images/help/popup_undo_transfer_agent.png'
import Img5MA from '../../../../static/images/help/popup_undo_transfer_ma.png'
import Img5SMA from '../../../../static/images/help/popup_undo_transfer_sma.png'
import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const childrenAppeal = userLevel === "sma" ?
    "an MA" : userLevel === "ma" ? "an Agent" : "a Member";

  return <div>
    <h1>Undo Transfer</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to undo a transfer between you and an {childrenAppeal}.</p>
      <br />

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <p>
        In the <strong>Undo Transfer</strong> window, you can see a list of transfers made between you and your {childrenLevel}s.
                If you wish you can undo the most recent transfer with any of them.<br />
        <strong>Note:</strong> You can undo the most recent transfer per {childrenLevel} only. Also, you cannot partially undo a transfer.
            </p>

      <p>To undo a transfer, follow this procedure:</p>

      <p className="last-p">Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>Transfer</strong> menu.</p>


      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">Click the <strong>Undo Transfer</strong> button in the top right corner of the <strong>Transfer</strong> pane.</p>

      <Image src={Img3} />

      <p>Click the <strong>Undo Transfer</strong> link next to the {childrenLevel} whose most recent transfer you want to undo.</p>
      <br />

      <Image src={userLevel === "agent" ? Img4Agent :
        userLevel === "ma" ? Img4MA : Img4SMA} />

      <br />

      <p className="last-p">
        Click <strong>Yes</strong> to confirm to undo the transfer.
            </p>

      <Image src={userLevel === "agent" ? Img5Agent :
        userLevel === "ma" ? Img5MA : Img5SMA} />

      <p className="last-p">
        <strong>Parent topic:</strong> <HelpMenuLink>Transfer</HelpMenuLink>
      </p>
    </div>
  </div>
}