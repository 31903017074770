import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useAuth } from "../../../Providers/AuthProvider"
import DateTimeRangePicker from "../../../components/DateTimeRangePicker"
import ButtonCustom from "../../../components/ButtonCustom"

import moment from "moment"
import { useStorage } from "../../../Providers/StorageProvider"
import CurrentTime from "../../../components/CurrentTime"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { numberWithCommas } from "../../../utils/dataTransform"

export default ({ userId, userLevel }) => {
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading } = useStorage()
  const [responseData, setResponseData] = useState(null)
  const [dataTypeToggle, setDataTypeToggle] = useState("void")
  const [activePage, setActivePage] = useState(1)
  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  const [alert, setAlert] = useState({
    isDisplayed: false,
    message: "There is no data for selected filters."
  })

  const getPageDataRequest = async () => {
    try {
      setIsLoading(true)
      setResponseData(null)
      setAlert({ ...alert, isDisplayed: false })
      const url = `agency/${userName}/report/order-list?type=${dataTypeToggle}&from=${dateTimeRange.start.valueOf()}&to=${dateTimeRange.end.valueOf()}&sportId=-1&marketType=ALL&whiteLabelSkinId=allSkins&providerId=-1&page=1`
      const response = await requestsToApi.get(url)
      setAlert({ ...alert, isDisplayed: true })
      setActivePage(activePage)
      setResponseData(response.data.result)
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPageDataRequest()
  }, [dateTimeRange, dataTypeToggle])

  useEffect(() => {}, [dataTypeToggle])

  const radioToggle = e => {
    setResponseData(null)
    setDataTypeToggle(e.target.attributes["status"].value)
  }

  return (
    <div className="voidInvalid-report">
      <div className="page-header">
        <div className="page-header__main">
          <div>
            <div>
              <DateTimeRangePicker
                dateTimeRange={dateTimeRange}
                onChangeFunc={result => {
                  setResponseData(null)
                  setDateTimeRange(result)
                }}
              />
            </div>
            <div className="voidInvalid-report__radios">
              <RadioCustom name="category" status="void" type="green" onChange={radioToggle} checked>
                Void
              </RadioCustom>
              <RadioCustom name="category" status="invalid" type="green" onChange={radioToggle}>
                Invalid
              </RadioCustom>
            </div>
          </div>

          <ButtonCustom className="refresh__button" color="cyan">
            Download CSV
          </ButtonCustom>
        </div>
      </div>
      {alert.isDisplayed && (
        <div className="voidInvalid-report__alert">
          <span>{alert.message}</span>
        </div>
      )}
      <div className="voidInvalid-report__main">
        <div className="main-report-content">
          <div className="voidInvalid-report__past">
            <div className="past__body">
              <Table striped hover variant="dark">
                <thead>
                  <tr>
                    {["Member", "Settled", "Description", "Type", "Placed"].map(title => (
                      <th key={Math.random()} scope="col" className="text-align-left">
                        {title}
                      </th>
                    ))}
                    {["Odds", "Stake", "Status", "MEMBER Win/Loss"].map(title => (
                      <th key={Math.random()} scope="col">
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {responseData && responseData.length ? (
                    responseData.map(item => (
                      <tr key={item.apolloBetId}>
                        <td className="text-align-left">{`${item.loginName} (${item.userName})`}</td>
                        <td className="text-align-left">
                          <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.lastSettledDate}</CurrentTime>
                        </td>
                        <td className="text-align-left">
                          <p>{item.eventName}</p>
                          <p>{`${item?.line ? item?.line : ""}`}</p>
                          <p>{`${item.marketName}`}</p>
                          <p>{`Bet ID ${item.apolloBetId}`}</p>
                          <p>
                            Placed Date &nbsp;
                            <CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>
                          </p>
                        </td>
                        <td className="text-align-left">
                          {item.marketBettingType === "ODDS"
                            ? !!!item.side
                              ? "Back"
                              : "Lay"
                            : !!!item.side
                            ? "Yes"
                            : "No"}
                        </td>
                        <td className="text-align-left">{item.placedOn}</td>
                        <td className="text-align-vertical">{item.averagePrice}</td>
                        <td className="text-align-vertical">{numberWithCommas(item.sizeMatched)}</td>
                        <td
                          className={
                            item.outcome === "WON" ? "text-align-vertical -positive" : "text-align-vertical -negative"
                          }
                        >
                          {item.outcome}
                        </td>
                        <td className="text-align-vertical">
                          <StringToRecolorNumber>{item.memberWin}</StringToRecolorNumber>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
