import { useEffect, useState } from 'react'
import { useAuth } from '../../Providers/AuthProvider'
import { Table } from 'react-bootstrap'
import StringToRecolorNumber from '../../components/StringToRecolorNumber'
import { numberWithCommas } from '../../utils/dataTransform'

const AcSummary = () => {
  const { requestsToApi } = useAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAccountSummary = async () => {
    const url = `/agency/MOGAMBOHKD/report/user-summary?dl=${sessionStorage.getItem('myUserName')}`;
    const response = await requestsToApi.get(url);
    if (response.status === 200) {
      setData(response.data.result.user);
      setLoading(false);
    }
  }

  useEffect(() => { getAccountSummary() }, []);

  const tableTemplate = [
    ["win", "Win", true],
    ["comm", "Comm", true],
    ["pnl", "P&L", true],
    ["turnover", "Turnover", false]
  ]

  const formatData = (num, recolor) => {
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div>
      <div className="header-plsummary d-flex align-items-center justify-content-between">
        <h1>Account Summary</h1>
      </div>
      <div className=' w-full pt-4 bg-gray-dark'>
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th style={{ width: "6rem" }} scope="col"></th>
              <th scope="col">Today</th>
              <th scope="col">3 days</th>
              <th scope="col">7 days</th>
              <th scope="col">30 days</th>
              <th scope="col">Lifetime</th>
            </tr>
          </thead>
          <tbody>
            {
              (data && !loading) ? (
                <>
                  {
                    tableTemplate.map(([key, title, recolor]) => (
                      <tr key={title}>
                        <th>{title}</th>
                        {["1", "3", "7", "30", "all"].map(fieldName => (
                          <td key={fieldName}>{formatData(-data[key][fieldName], recolor)}</td>
                        ))}
                      </tr>
                    ))
                  }
                </>
              ) : (<></>)
            }
            {
              loading && (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div class="spinner-grow text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )
            }

          </tbody>
        </Table>
      </div>
    </div >
  )
}

export default AcSummary;