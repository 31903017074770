import TextInputCustom from '../../components/TextInputCustom'
import DropdownRange from '../../components/DropdownRange'
import SearchUserInput from '../../components/SearchUserInput'
import DropdownCustom from '../../components/DropdownCustom'
import {
  dropdownMarketOptions,
  dropdownSportOptions,
} from './betTickerData'
import ButtonCustom from '../../components/ButtonCustom'
import { useEffect, useState } from 'react'

export default ({ options, resetOptions, updateOptions, isPnl }) => {

  const [newOptions, setNewOptions] = useState(options);

  const changeValue = (value, filterTitle) => {
    if (filterTitle === "sportDropdown") {
      setNewOptions({ ...newOptions, sportDropdown: value, marketTypeDropdown: 'All' })
    } else {
      setNewOptions({ ...newOptions, [filterTitle]: value })
    }
  }

  useEffect(() => {
    setNewOptions(options)
  }, [options])

  return <div className="page-header__filters">
    <div>
      <DropdownCustom
        title="Sport: "
        options={dropdownSportOptions}
        activeOption={newOptions.sportDropdown}
        onChange={(value) => changeValue(value, "sportDropdown")}
      />
      <DropdownCustom
        title="Market Type:"
        options={dropdownMarketOptions.filter(item => item[0] === newOptions.sportDropdown)[0][1]}
        activeOption={newOptions.marketTypeDropdown}
        onChange={(value) => changeValue(value, "marketTypeDropdown")}
      />
      <TextInputCustom
        onChange={(value) => changeValue(value, "eventInput")}
        placeholder="Event"
      >
        {newOptions.eventInput}
      </TextInputCustom>
    </div>
    {isPnl ? <div>
      <TextInputCustom
        onChange={(value) => changeValue(value, "nameInput")}
        placeholder="Agency/Member Name"
      >
        {newOptions.nameInput}
      </TextInputCustom>
    </div> :
      <div>
        <DropdownRange
          title="Stake"
          fromValue={newOptions.stakeFrom}
          onChangeFromValue={(value) => changeValue(value, "stakeFrom")}
          toValue={newOptions.stakeTo}
          onChangeToValue={(value) => changeValue(value, "stakeTo")}
        />
        <SearchUserInput
          color="black"
          placeholder="Member Name"
          dropdownClickFunc={(value) => setNewOptions({ ...newOptions, nameInput: value.loginName, userCode: value.userCode })}
          defaultInputValue={newOptions.nameInput}
        />
      </div>}
    <div className="filters__control-buttons">
      <ButtonCustom onClick={() => updateOptions(newOptions)} color="cyan">Apply</ButtonCustom>
      <ButtonCustom onClick={() => { setNewOptions(options); resetOptions() }} color="red">Clear</ButtonCustom>
    </div>
  </div>
}
