
export const getPathOfLink = (childrenLevel, title) => {
  const sidebarData = [
    {
      title: `${childrenLevel} Management`,
      path: "/agency_management"
    },
    {
      title: `Creating a New ${childrenLevel} Account`,
      path: "/creating_agency_user"
    },
    {
      title: `Unlocking an ${childrenLevel} Account`,
      path: "/unlocking_account"
    },
    {
      title: `Changing the Status of an ${childrenLevel} Account`,
      path: "/changing_agency_status"
    },
    {
      title: `Closing an ${childrenLevel} Account`,
      path: "/closing_agency_user"
    },
    {
      title: `Changing the Credit Limit for an ${childrenLevel} Account`,
      path: "/changing_credit_limit"
    },
    {
      title: "Position Taking",
      path: "/position_taking",
    },
    {
      title: "Position Taking Management",
      path: "/pt_management",
    },
    {
      title: "Viewing a Downline User's Summary",
      path: "/viewing_downline_summary",
    },
    {
      title: "Risk Management",
      path: "/risk_management"
    },
    {
      title: "Net Exposure",
      path: "/net_exposure"
    },
    {
      title: "Bet Ticker",
      path: "/bet_ticker"
    },
    {
      title: "Transfer",
      path: "/transfer"
    },
    {
      title: "Undo Transfer",
      path: "/undo_transfer"
    },
    {
      title: "Reports",
      path: "/reports"
    },
    {
      title: "P&L Report by Market",
      path: "/profit_and_loss_by_market"
    },
    {
      title: "P&L Report by Agent",
      path: "/profit_and_loss_by_agent"
    },
    {
      title: "Bet List",
      path: "/bet_list"
    },
    {
      title: "Account Statement",
      path: "/account_statement"
    },
    {
      title: "Admin Users",
      path: "/user_management"
    },
    {
      title: "Creating a New Admin Account",
      path: "/creating_user"
    },
    {
      title: "Setting Up Access Rights",
      path: "/setting_up_access_rights"
    }
  ]

return sidebarData.filter(item => item.title === title)[0].path
};