import "./index.scss"
import { useState } from "react"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import FilterIcon from "mdi-react/FilterIcon"
import { defaultFilterValues } from "./options"
import moment from "moment"
import ButtonCustom from "../../components/ButtonCustom"
import PnlReport from "./PnlReport"
import Filters from "../BetTickerPage/filters"
import { useHistory } from "react-router-dom"
import ViewMarketBets from "./ViewMarketBets"
import { downloadCsv } from '../../utils/createCsv'

export default () => {
  const [isShowFilters, setIsShowFilters] = useState(false)
  const [filtersOptions, setFilterOptions] = useState(defaultFilterValues)

  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  const history = useHistory()
  return (
    <div className="pnl-by-market">
      <div className="page-header">
        <div className="page-header__main">
          <div>
            <h1>P&L Report by Market</h1>
            <div style={history.location.pathname.includes("bet-breakdown") ? { display: "none" } : null}>
              <DateTimeRangePicker dateTimeRange={dateTimeRange} onChangeFunc={setDateTimeRange} />
              <input
                type="checkbox"
                onChange={() => setIsShowFilters(!isShowFilters)}
                id="pnl-by-market__filter"
                style={{ display: "none" }}
              />
              <label className="filter__toggle" htmlFor="pnl-by-market__filter">
                <FilterIcon size={20} />
              </label>
            </div>
          </div>

          <ButtonCustom className="refresh__button" color="cyan" onClick={() => downloadCsv()}>
            Download CSV
          </ButtonCustom>
        </div>
        <div
          className={
            "page-header__filters " +
            (isShowFilters && !history.location.pathname.includes("bet-breakdown") ? "" : "invisible")
          }
        >
          <Filters
            options={filtersOptions}
            updateOptions={setFilterOptions}
            resetOptions={() => setFilterOptions(defaultFilterValues)}
            isPnl
          />
        </div>
      </div>

      <div className="pnl-by-market__main">
        {history.location.pathname === '/reports/pnl-by-market' ?
          <PnlReport dateTimeRange={dateTimeRange} filtersOptions={filtersOptions} /> :
          <ViewMarketBets />}
      </div>
    </div>
  )
}
