import TextInputCustom from '../../../components/TextInputCustom'
import DropdownRange from '../../../components/DropdownRange'
import SearchUserInput from '../../../components/SearchUserInput'
import DropdownCustom from '../../../components/DropdownCustom'
import {
  dropdownMarketOptions,
  dropdownSportOptions,
} from './betListData'
import ButtonCustom from '../../../components/ButtonCustom'
import { useEffect, useState } from 'react'

export default ({ className, options, sportCasinoToggle, resetOptions, updateOptions }) => {

  const [newOptions, setNewOptions] = useState(options);

  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value })
  }

  useEffect(() => {
    setNewOptions(options)
  }, [options])

  return <div className={"page-header__filters " + (className ? className : "")}>
    <div>
      <DropdownCustom
        title={sportCasinoToggle === "sports" ? "Sport" : "Game"}
        options={dropdownSportOptions[sportCasinoToggle]}
        activeOption={newOptions.sportDropdown}
        onChange={(value) => changeValue(value, "sportDropdown")}
      />
      <DropdownCustom
        title="Market Type"
        options={dropdownMarketOptions}
        activeOption={newOptions.marketTypeDropdown}
        onChange={(value) => changeValue(value, "marketTypeDropdown")}
      />
      <TextInputCustom
        onChange={(value) => changeValue(value, "eventInput")}
        placeholder={sportCasinoToggle === "sports" ? "Event" : "Game ID"}
      >
        {newOptions.eventInput}
      </TextInputCustom>
    </div>
    <div>
      <DropdownRange
        title="Odds"
        fromValue={newOptions.oddsFrom}
        onChangeFromValue={(value) => changeValue(value, "oddsFrom")}
        toValue={newOptions.oddsTo}
        onChangeToValue={(value) => changeValue(value, "oddsTo")}
      />
      <DropdownRange
        title="Stake"
        fromValue={newOptions.stakeFrom}
        onChangeFromValue={(value) => changeValue(value, "stakeFrom")}
        toValue={newOptions.stakeTo}
        onChangeToValue={(value) => changeValue(value, "stakeTo")}
      />
      <SearchUserInput
        color="black"
        placeholder="Agency/Member Name"
        dropdownClickFunc={(value) => (changeValue(value, "user"))}
        defaultInputValue={newOptions.user.loginName}
      />
    </div>
    <div>
      <ButtonCustom onClick={() => updateOptions(newOptions)} color="cyan">Apply</ButtonCustom>
      <ButtonCustom onClick={resetOptions} color="red">Clear</ButtonCustom>
    </div>
  </div>
}