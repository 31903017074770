import "./index.scss"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import ModalCustom from "../ModalCustom"
import TextInputCustom from "../TextInputCustom"
import { passValidation } from "../../utils/validation"
import RadioCustom from "../RadioCustom"
import { Button, Table } from "react-bootstrap"
import ButtonCustom from "../ButtonCustom"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import AlertCustom from "../AlertCustom"
import axios from "axios"
import SwitchCustom from "../SwitchCustom"

const modalRoot = document.querySelector("#modal-root");

export default ({ settings, closeFunc }) => {
  const history = useHistory()
  const { requestsToApi } = useAuth();
  const [userStatus,setUserStatus] = useState(false);
  const [betStatus,setBetStatus] = useState(false);
  const [isSumbitting,setSubmitting] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  });

  useEffect(()=>{
    // console.log(settings,"setting")
    if(settings?.data?.child){
        setUserStatus(settings?.data?.child.userStatus);
        setBetStatus(settings?.data?.child.betStatus);
    }
  },[])

  const onSubmit = async (e) => {
    e.preventDefault()
//    await validateInput("amount", amount)
      try {
        // console.log({
        //     "action": settings.action,
        //     "user":  settings.user.user.name,
        //     "userStatus": userStatus,
        //     "betStatus": betStatus,
        //   });
        // const response = await axios.post( `https://dadm.gemexch.bet/api/agency/${settings.master}/agency-mgmt/downline/actions`,
        setSubmitting(true);
        const response = await requestsToApi.post( `/agency/${settings.master}/agency-mgmt/downline/actions`,
          {
            "action": settings.action,
            "user":  settings.user.user.name,
            "userStatus": userStatus,
            "betStatus": betStatus,
          }
        )
        setAlertOptions({ message: `Status was updated successfully.`, type: "green" })
        setTimeout(() => closeFunc(true), 1000)
      } catch (error) {
        console.log("error", error)
        setAlertOptions({
          message: "Something went wrong",
            type: "red"
        })
        setSubmitting(false);
        setSubmitting(false);
      }
      // history.push("/agency-management/downline")
  };


  return (
    <ModalCustom visible className="modal-child-info" closeFunc={() => closeFunc()} width={"500px"}>
      <form onSubmit={onSubmit}>
      <div className="deposit-pop-up">
        <span className="deposit-pop-up-title">Change Status</span>
        <div className="deposit-pop-up-info">
            <label className="deposit-pop-up-info-title">User Id: {settings?.data?.child.loginName}</label>
        </div>
        <div className="deposit-pop-up-info">
            <div className="deposit-pop-up-info-row deposit-pop-up-info-title">
                <label className="">User Status:</label>
                <SwitchCustom className="agency-settings__switch" defaultValue={userStatus} onChange={()=>setUserStatus(!userStatus)}  />
            </div>
        </div>
        <div className="deposit-pop-up-info">
            <div className="deposit-pop-up-info-row deposit-pop-up-info-title">
                <label className="">Bet Status:</label>
                <SwitchCustom className="agency-settings__switch" defaultValue={betStatus} onChange={()=>setBetStatus(!betStatus)} />
            </div>
        </div>

        <div className="deposit-pop-up-buttonsRow">
          <div className="deposit-pop-up-buttonsRow-back" onClick={() => closeFunc()}>Back</div>
          <button disabled={isSumbitting} className="deposit-pop-up-button">
            Submit
          </button>
        </div>
        <AlertCustom disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
          {alertOptions.message}
        </AlertCustom>
      </div>
      </form>
  </ModalCustom>
  )
}
