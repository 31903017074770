import { Image } from "react-bootstrap"
import { useHelpStorage } from "../../../../Providers/HelpProvider"
import Img1Agent from "../../../../static/images/help/net_exposure_agent.png"
import Img1MA from "../../../../static/images/help/net_exposure_ma.png"
import Img1SMA from "../../../../static/images/help/net_exposure_coy.png"
import Img2Agent from "../../../../static/images/help/button_total_book_agent.png"
import Img2MA from "../../../../static/images/help/button_total_book_ma.png"
import Img2SMA from "../../../../static/images/help/button_total_book_coy_sma.png"
import Img3Agent from "../../../../static/images/help/button_agency_breakdown_net_exposure_agent.png"
import Img3MA from "../../../../static/images/help/button_bet_breakdown_net_exposure_ma.png"
import Img3SMA from "../../../../static/images/help/button_bet_breakdown_net_exposure_coy.png"
import Img4Agent from "../../../../static/images/help/net_exposure_agency_breakdown_agent.png"
import Img4MA from "../../../../static/images/help/net_exposure_bet_breakdown_ma.png"
import Img4SMA from "../../../../static/images/help/net_exposure_bet_breakdown_sma.png"
import Img5 from "../../../../static/images/help/icon_downline.svg"
import Img6 from "../../../../static/images/help/icon_bet_breakdown.png"
import Img7MA from "../../../../static/images/help/button_agency_breakdown_net_exposure_ma.png"
import Img7SMA from "../../../../static/images/help/button_agency_breakdown_net_exposure_coy.png"
import Img8MA from "../../../../static/images/help/net_exposure_agency_breakdown_ma.png"
import Img8SMA from "../../../../static/images/help/net_exposure_agency_breakdown_sma.png"
import HelpMenuLink from "../../../../components/HelpMenuLink"

export default () => {
  const { userLevel, childrenLevel } = useHelpStorage()
  return (
    <div>
      <h1>Net Exposure</h1>
      <div className="body">
        <p className="shortdesc">
          This section describes how you can find out about your and your downline's net exposure.
        </p>

        <p className="last-p">
          By default, this table shows your Net Exposure according to your position taking on all the markets&nbsp;
          where your downline Members have placed bets.
        </p>

        <Image src={userLevel === "agent" ? Img1Agent : userLevel === "ma" ? Img1MA : Img1SMA} />

        <p className="last-p">
          To show the summary of all the bets on a market, click the <strong>Total Book</strong> radio button.
        </p>

        <Image src={userLevel === "agent" ? Img2Agent : userLevel === "ma" ? Img2MA : Img2SMA} />

        <p className="last-p">
          To show all the bets separately that were placed on a given market, click the{" "}
          <img style={{ width: "15px", margin: 0 }} src={Img6} className="transform-rotate" /> button.
        </p>

        <Image src={userLevel === "agent" ? Img3Agent : userLevel === "ma" ? Img3MA : Img3SMA} />

        <br />
        <br />

        <Image src={userLevel === "agent" ? Img4Agent : userLevel === "ma" ? Img4MA : Img4SMA} />

        <p className="last-p">
          To find out about the Net Exposure of your downline, click the{" "}
          <img style={{ width: "15px", margin: 0 }} src={Img5} alt="" /> button.
        </p>

        <Image src={userLevel === "agent" ? Img3Agent : userLevel === "ma" ? Img7MA : Img7SMA} />
        <br />
        <br />
        <Image src={userLevel === "agent" ? Img4Agent : userLevel === "ma" ? Img8MA : Img8SMA} />

        <p className="last-p">
          You can also click the <img style={{ width: "15px", margin: 0 }} className="transform-rotate" src={Img6} alt="" /> button next to the{" "}
          {userLevel === "agent" ? "login name of the Member(s)" : `${childrenLevel}'s login name`} to display the bets
          placed by{" "}
          {userLevel === "agent" ? (
            "them separately"
          ) : (
            <>
              their Members, or
              <br />
              You may click the <img style={{ width: "15px", margin: 0 }} src={Img5} alt="" /> button to drill further
              down and find out about the net exposure of their {userLevel === "sma" ? "Agents" : "Members"}.
            </>
          )}
          <br />
          To close the agency net exposure breakdown view, click the{" "}
          <img style={{ width: "15px", margin: 0 }} src={Img6} alt="" className="transform-rotate"/> button.
          <br />
          <strong>Parent Topic:</strong> <HelpMenuLink>Risk Management</HelpMenuLink>
        </p>
      </div>
    </div>
  )
}
