import "./index.scss";
import DateTimeRangePicker from "../../components/DateTimeRangePicker";
import moment from "moment";
import { useState } from "react";
import ButtonCustom from "../../components/ButtonCustom";
import TextInputCustom from "../../components/TextInputCustom";
import DropdownStatus from "../../components/DropdownStatus";
import { reports } from "./reports";
import PaginationCustom from "../../components/PaginationCustom";
import SearchCustom from "../../components/SearchCustom";
import DepositEntryPopUp from "../../components/DepositEntryPopUp";
import PaginationToTableTop from "../../components/PaginationToTableTop"

export default () => {
  const now = new Date();
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds");

  const [dateTimeRange, setDateTimeRange] = useState({ start: startOfToday, end: endOfToday });
  const [newOptions, setNewOptions] = useState({ utrInput: "", amountInput: "", status: "", idInput: "" });
  const [search, setSearch] = useState("");
  const [showDepositPopUp, setShowDepositPopUp] = useState(false);
  const [choseReport, setChoseReport] = useState({});

  const changeValue = (value, filterTitle) => {
    setNewOptions({ ...newOptions, [filterTitle]: value });
  };
  const openDepositPopUp = (report) => {
    setChoseReport(report);
    setShowDepositPopUp(prevState => !prevState);
  };

  return (
    <div className="payment-reports">
      <div className="payment-reports__header">
        <div className="payment-reports__header_date-rows">
          <div className="payment-reports__header_date-row">
            <h1>Reports</h1>
            <DateTimeRangePicker dateTimeRange={dateTimeRange} onChangeFunc={result => console.log(result)} />
          </div>
          <div className="payment-reports__header_date-unput-row">
            <TextInputCustom onChange={(value) => changeValue(value, "utrInput")} placeholder="UTR">
              {newOptions.utrInput}
            </TextInputCustom>
            <TextInputCustom onChange={(value) => changeValue(value, "amountInput")} placeholder="Amount">
              {newOptions.amountInput}
            </TextInputCustom>
            <div className="payment-reports__header_date-input-row-drop-down-wrapper">
              <DropdownStatus changeValue={changeValue}/>
            </div>
            <TextInputCustom onChange={(value) => changeValue(value, "idInput")} placeholder="ID">
              {newOptions.idInput}
            </TextInputCustom>
          </div>
        </div>
        <div className="payment-reports__header-search-column">
          <div className="payment-reports__header-search-column-buttons">
            <ButtonCustom onClick={() => console.log("Apply")} color="cyan">Apply</ButtonCustom>
            <ButtonCustom onClick={() => console.log("Clear")} color="red">Clear</ButtonCustom>
          </div>
          <div className="payment-reports__header-search-column-search-wrapper">
            <SearchCustom setSearch={setSearch} />
          </div>
        </div>
      </div>
      <div className="payment-reports__table">
        <div className="payment-reports__table-row-date">
          <div className="payment-reports__table-date">{moment(now).format("DD.MM.YY")}</div>
          <div className="payment-reports__table-paginaton">
            <PaginationToTableTop/>
          </div>
        </div>
        <ul className="payment-reports__table-header"><li>CreatedAt</li><li>CompletedAt</li><li>Bank manager</li><li>Username</li><li>Supervisor</li><li>ID</li><li>Amount</li><li>UTR_NO</li><li>Status</li>
        </ul>
        <div className="payment-reports__table-body">
          {reports.map((report) =>
            <ul className="payment-reports__table-body-row">
              <li className="payment-reports__table-body-row-date">
                <div>{moment(report.created).format("DD.MM.YY")}</div>
                <div className="time">{moment(report.created).format("LTS")}</div>
              </li>
              <li className="payment-reports__table-body-row-date">
                <div>{moment(report.completed).format("DD.MM.YY")}</div>
                <div className="time">{moment(report.completed).format("LTS")}</div>
              </li>
              <li>{report.bankManager}</li>
              <li>{report.username}</li>
              <li>{report.supervisor}</li>
              <li className="payment-reports__table-body-row-id" onClick={() => openDepositPopUp(report)}>{report.id}</li>
              <li>{report.amount}</li>
              <li>{report.utrNo}</li>
              <li>{report.status}</li>
            </ul>
          )}
          <DepositEntryPopUp
            visible={showDepositPopUp}
            closeFunc={openDepositPopUp}
            data={choseReport}
          />
        </div>
      </div>
    </div>
  )
}
