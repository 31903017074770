import Modal from "../ModalCustom"
import { useState } from "react"
import TextInputCustom from "../TextInputCustom"
import "./index.scss"
import ButtonCustom from "../ButtonCustom"
import { Button } from "react-bootstrap"
import { useAuth } from "../../Providers/AuthProvider"
import { numInputFormat } from "../../utils/validation"
import { useStorage } from "../../Providers/StorageProvider"

export default ({ closeFunc, user, successFunc }) => {
  const { requestsToApi } = useAuth()
  const { userName } = useStorage()
  const [inputValues, setInputValues] = useState({
    amount: Math.abs(user.balanceUp).toString(),
    comment: ""
  })
  const [validStatus, setValidStatus] = useState(true)

  const changeInputToggle = value => setInputValues({ ...inputValues, amount: numInputFormat(value) })

  const checkInputValue = () => {
    let isValid = Math.abs(user.balanceUp) >= parseFloat(inputValues.amount)
    setValidStatus(isValid)
    return isValid
  }

  const submitToggle = async () => {
    try {
      if (validStatus) {
        const response = await requestsToApi.put(`agency/${userName}/agency-mgmt/transfer/${user.name}`, {
          comment: inputValues.comment,
          displayAmt: Math.abs(user.balanceUp),
          transferAmt: parseFloat(inputValues.amount)
        })
        successFunc()
        closeFunc()
      } else {
        //ShowMessage
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  return (
    <Modal className="transfer-modal" visible={true} closeFunc={closeFunc} width="500px">
      <div className="transfer-modal__content">
        <h2>{"Transfer for " + user.loginName}</h2>
        <TextInputCustom label="Transfer amount" color="white" onChange={changeInputToggle} checkFunc={checkInputValue}>
          {inputValues.amount}
        </TextInputCustom>
        <textarea
          onChange={e => setInputValues({ ...inputValues, comment: e.target.value })}
          value={inputValues.comment}
        />

        <div className="transfer-modal__controls">
          <Button className="cansel-button" variant="link" onClick={() => closeFunc(false)}>
            Cancel
          </Button>
          <ButtonCustom onClick={submitToggle} height="42px" color="green">
            Transfer
          </ButtonCustom>
        </div>
      </div>
    </Modal>
  )
}
