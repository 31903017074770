// export const tableTemplate = [
//     ["win", "Win", true],
//     ["comm", "Comm", true],
//     ["pnl", "P&L", true],
//     ["turnover", "Turnover", false]
// ]
// const toggleDropdown = () => { }

export const dropdownSportOptions = {
  sports: [
    'All',
    'Cricket',
    'Football',
    'Tennis',
    'Horse Racing',
    'Greyhound Racing'
  ],
  casino: [
    'All',
    "Texas Hold'em"
  ]
};

export const dropdownMarketOptions = [
  'All',
  'Match Odds',
  'Session Runs',
  'Session Runs Ex',
  'Tied Match',
  'Completed Match',
  'Win',
  'To Qualify',
  'Over/Under',
  'Tennis Fancy',
  'Super Over',
  'Outright',
  'Others'
];

export const sportOptionsId = {
  sports: [
    { title: 'All', id: -1 },
    { title: 'Cricket', id: 4 },
    { title: 'Football', id: 1 },
    { title: 'Tennis', id: 2 },
    { title: 'Horse Racing', id: 7 },
    { title: 'Greyhound Racing', id: 4339 }
  ],
  casino: [
    { title: 'All', id: 1444000 },
    { title: "Texas Hold'em", id: 1444000 }
  ]
};

export const marketOptionsId = {
  sports: [
    { title: 'All', id: "ALL" },
    { title: 'Match Odds', id: "MATCH_ODDS" },
    { title: 'Session Runs', id: "INNINGS_RUNS" },
    { title: 'Session Runs Ex', id: "INNINGS_RUNS_EX" },
    { title: 'Tied Match', id: "TIED_MATCH" },
    { title: 'Completed Match', id: "COMPLETED_MATCH" },
    { title: 'Win', id: "WIN" },
    { title: 'To Qualify', id: "TO_QUALIFY" },
    { title: 'Over/Under', id: "OVER_UNDER" },
    { title: 'Tennis Fancy', id: "TENNIS_FANCY" },
    { title: 'Super Over', id: "SUPER_OVER" },
    { title: 'Outright', id: "WINNER" },
    { title: 'Others', id: "OTHERS" },
  ],
  casino: [
    { title: 'All', id: "ALL" },
    { title: "Win", id: "WIN_ONLY" }
  ]
};

export const defaultFilterValues = {
  sportDropdown: "All",
  marketTypeDropdown: "All",
  eventInput: "",
  oddsFrom: "",
  oddsTo: "",
  stakeFrom: "",
  stakeTo: "",
  user: {
    
  }
}