import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/menu_member_listing_agent.png'
import Img1MA from '../../../../static/images/help/menu_agent_listing_ma.png'
import Img1SMA from '../../../../static/images/help/menu_ma_listing_sma.png'
import Img2Agent from '../../../../static/images/help/member_listing_agent.png'
import Img2MA from '../../../../static/images/help/agent_listing_ma.png'
import Img2SMA from '../../../../static/images/help/ma_listing_sma.png'
import Img3Agent from '../../../../static/images/help/popup_credit_limit_agent.png'
import Img3MA from '../../../../static/images/help/popup_credit_limit_ma.png'
import Img3SMA from '../../../../static/images/help/popup_credit_limit_sma.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const childrenAppeal = userLevel === "sma" ?
    "an MA" : userLevel === "ma" ? "an Agent" : "a Member";
  return <div>
    <h1>Changing the Credit Limit for {childrenAppeal} Account</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to change the credit limit for {childrenAppeal} </p>

      <p>
        When changing the credit limit of {childrenAppeal}, credit will be transferred to their balance or withdrawn from it.
            </p>
      <p>
        Therefore, when increasing the credit limit for them, you must make sure that you have enough credit on your balance to transfer from.<br />
                Similarly, you should check the balance of the {childrenLevel} to make sure they have enough credit before decreasing their credit limit.
            </p>
      <p>
        Also, you cannot withdraw more credit from {childrenAppeal} than the sum you have distributed to them.
            </p>

      <p>
        To change the credit limit, follow this procedure:
            </p>
      <p className="last-p">
        Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu item and click the <strong>Login Name</strong>
            &nbsp;or the <strong>ID</strong> of the {childrenLevel} to open their settings pane.</p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">In the settings pane, go to the <strong>Credit</strong> section and set a new credit limit&nbsp;
            in the <strong>Credit Limit</strong> field.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">
        Click the <strong>Save</strong> button to confirm the new values for the {childrenLevel}.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink><br />
      </p>
    </div>
  </div>
}