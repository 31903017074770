import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1 from '../../../../static/images/help/popup_date_picker_bet_list.png'

import Img2Agent from '../../../../static/images/help/member_listing_agent.png'
import Img2MA from '../../../../static/images/help/current_bet_list_ma.png'
import Img2SMA from '../../../../static/images/help/current_bet_list_sma.png'
import Img3Agent from '../../../../static/images/help/unmatched_bets_agent.png'
import Img3MA from '../../../../static/images/help/unmatched_bets_ma.png'
import Img3SMA from '../../../../static/images/help/unmatched_bets_sma.png'
import Img4Agent from '../../../../static/images/help/past_bet_list_agent.png'
import Img4MA from '../../../../static/images/help/past_bet_list_ma.png'
import Img4SMA from '../../../../static/images/help/past_bet_list_sma.png'
import Img5 from '../../../../static/images/help/button_download_csv_bet_list.png'
import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage()

  return <div>
    <h1>Bet List</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to list all the bets the Members have placed in a given period of time.</p>

      <p className="last-p">Specify a date range using the date picker drop-down and click the <strong>Apply</strong> button to display the Bet List.</p>

      <Image src={Img1} />

      <p className="last-p">
        By default, the current day is selected in the date picker.
                You can also specify a time range or select one of the pre-defined date ranges.<br />
                The bet list shows the matched bets that are currently active in the system.
                Bets that are invalidated are highlighted in red.
            </p>

      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">Clicking the <strong>Unmatched</strong> radio button displays the list of bets that are not completely matched.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">To display the list of past bets, click the <strong>Past</strong> tab on the bet list screen. Invalidated bets are highlighted in red.</p>
      <br />
      <Image src={userLevel === "agent" ? Img4Agent :
        userLevel === "ma" ? Img4MA : Img4SMA} />

      {userLevel === "agent" ? null : <>
        <br /><br />
        <p className="last-p">You can download all the bets in the selected date range by clicking the <strong>Download CSV</strong> button.</p>
        <Image src={Img5} />
      </>}

      <p className="last-p">
        <strong>Parent topic:</strong> <HelpMenuLink>Reports</HelpMenuLink>
      </p>
    </div>
  </div>
}