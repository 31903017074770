import "./index.scss"
import VoidInvalidReport from "../SecretRoom/VoidInvalidReport"
import { useStorage } from "../../Providers/StorageProvider"

export default () => {
  const { userLevel } = useStorage()
  return (
    <div className="bet-list-page">
      <h1>Void & Invalid Page</h1>
      <VoidInvalidReport userLevel={userLevel} />
    </div>
  )
}
