import './index.scss'
import ModalCustom from "../ModalCustom"
import { Button, Table } from 'react-bootstrap'
import ButtonCustom from '../ButtonCustom';
import TextInputCustom from '../TextInputCustom';
import RadioCustom from '../RadioCustom';
import { useState } from 'react';
import { passValidation } from '../../utils/validation';
import AlertCustom from '../AlertCustom';
import { useAuth } from '../../Providers/AuthProvider';
import { useStorage } from '../../Providers/StorageProvider';

export default ({ settings, closeFunc }) => {
  const { personalInformation: personalInfo, betSettings, creditLimits, posTakings, commissions } = settings.data.downlineFormInfo.downlineDetails;
  const { creditLimits: { data: maxCreditLimits }, posTakingSettings: { static: maxPosTaking }, betSettings: { data: limitBetSettings } } = settings.data.formInfo;
  const titlesForBetSettings = [
    "Football",
    "Cricket",
    "Tennis",
    "Horce Racing",
    "Greyhound Racing",
    "Fancy Markets",
    "Exch Runs",
    "Casino"
  ]
  const parentLevel = personalInfo.level === "Member" ? "Agent" :
    personalInfo.level === "Agent" ? "MA" : "CUS"
  const { requestsToApi } = useAuth();
  const { userName, userLevel } = useStorage();
  const [editData, setEditData] = useState({
    personalInfo, creditLimits, posTakings,
    newPassword: "",
    password: "",
    repeatPassword: "",
    status: personalInfo.status,
    creditLimit: creditLimits.creditLimit.toString(),
    maxAgentCredit: creditLimits.maxAgentCredit.toString(),
    maxMemberCredit: creditLimits.maxMemberCredit.toString(),
    paymentThreshold: creditLimits.paymentThreshold.toString(),
    betSettingsSameForAllActive: false,
    myPTSameForAllActive: false,
    notes: personalInfo.notes
  });
  const [editBetSettings, setEditBetSettings] = useState(betSettings)
  const [editPosTakings, setEditPosTakings] = useState(posTakings)
  const [warning, setWarning] = useState(true)
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "red"
  });

  const [shopwPassword, setShowPassword] = useState(false);

  const changeEditData = (key) => (value) => {
    setEditData({ ...editData, [key]: value })
  }

  const checkboxToggle = (key) => () => {
    setEditData({ ...editData, [key]: !editData[key] })
  }


  const radioToggle = (e) => {
    const status = e.target.attributes["status"].value
    setEditData({ ...editData, status })
  }

  const editBetSettingsToggle = (value, index, row) => {
    const editedBetSettings = [...editBetSettings]
      .map((item, itemIndex) => itemIndex === index ? { ...item, [row]: value } : item)
    setEditBetSettings(editedBetSettings)
  }

  const editPosTakingsToggle = (value, index) => {
    const editedPosTakings = [...editPosTakings]
      .map((item, itemIndex) => itemIndex === index ? { ...item, parentPosition: value } : item)
    setEditPosTakings(editedPosTakings)
  }

  const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    while (rgx.test(x2)) {
      x2 = x2.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr)  > 100)
      return x1 + x2;
    else
      return x1
  }

  const oneBetCheck = (target) => {
    const localLimits = limitBetSettings.find(limit => limit.group === target.group);
    const validResult = localLimits.minBet <= target.minBet &&
      localLimits.maxWinPerMarket >= target.maxWinPerMarket &&
      target.minBet <= localLimits.maxWinPerMarket
    if (!validResult) {
      const message = localLimits.minBet > target.minBet ?
        "Min Bet cannot be less than " + localLimits.minBet :
        localLimits.maxWinPerMarket < target.maxWinPerMarket ?
          "Max Market cannot be greater than " + localLimits.maxWinPerMarket :
          target.minBet > localLimits.maxWinPerMarket ?
            "Min Bet cannot be greater than Max Bet" :
            "Max Bet cannot be greater than " + 100000
      setAlertOptions({ ...alertOptions, message: `[${target.group}] ${message}` })
    }
    return validResult
  }

  const betLimitsCheck = () => {
    if (editData.betSettingsSameForAllActive) {
      oneBetCheck(editBetSettings[0])
    } else {
      return editBetSettings.every(item => oneBetCheck(item))
    }
  }

  const onePtCheck = (target) => {
    const localLimits = maxPosTaking.find(max => max.name === target.posTakingGroup);
    if (localLimits.maxAllowablePT < target.parentPosition) {
      setAlertOptions({ ...alertOptions, message: `Unhandled Exception.` })
      return false
    } else return true
  }

  const ptLimitsCheck = () => {
    if (editData.myPTSameForAllActive) {
      return onePtCheck(editPosTakings[0])
    } else {
      return editPosTakings.every(item => onePtCheck(item))
    }
  }

  const saveNewData = async (data) => {
    try {
      const response = await requestsToApi.put(`agency/${userName}/agency-mgmt/downline/${editData.personalInfo.name}`, data)
      if (response.data.success) {
        console.log(response);
        setAlertOptions({ message: `Changes Done Successfully`, type: "green" });
        setTimeout(() => closeFunc(response.data.success), 1000)
      }
      else{
        setAlertOptions({ ...alertOptions, message: response?.data?.error?.maxAgentCredit[0].description })
      }
    } catch (error) {
      // console.log(error)
      setAlertOptions({ ...alertOptions, message: error.message })
    }
  }

  const submitToggle = async () => {
    let message = 'Sorry, some of the submitted data was wrong. Please check your input and try again.'
    if ((editData.password !== editData.newPassword)) {
      message = 'New Password and Confirm Password should be same.'
      setAlertOptions({ message: message, type: 'red' });
      return;
    } else {
      const reqData = {
        betSettings: editBetSettings,
        commissions,
        creditLimits: {
          creditLimit: editData.creditLimit,
          maxAgentCredit: editData.maxAgentCredit,
          maxMemberCredit: editData.maxMemberCredit,
          paymentThreshold: editData.paymentThreshold
        },
        personalInformation: {
          name: editData.personalInfo.name,
          loginName: editData.personalInfo.loginName,
          status: editData.status,
          level: editData.personalInfo.level,
          master: editData.personalInfo.level !== "Member",
          notes: editData.personalInfo.notes,
          password: editData.password,
        },
        posTakings: editPosTakings
      }
      saveNewData(reqData)
    }
  }

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const [message, setMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const checkForPasswordValidation = (value) => {
    if (value !== editData.newPassword) {
      setMessage('New Password and Confirm Password should be same.');
    } else {
      setMessage('');
    }
  }

  const checkPassValidation = (value) => {
    if (value === '') {
      setValidationMessage('');
    } else {
      const validation = passValidation(value);
      if (validation) {
        setValidationMessage('');
      } else {
        setValidationMessage('The Password must contain at least one alphabetical character, at least one digit, and should not contain underscores or non-word characters.');
      }
    }

  }

  return (settings.isMyChild && personalInfo.status !== 'CLOSED') ?
    <>
      <ModalCustom visible className="modal-child-info" closeFunc={() => closeFunc()}>
        <div className="modal-child-info__containers">
          <div className="container information">
            <h3>Information</h3>
            <div>
              <h4>Username</h4>
              <span>{personalInfo.name}</span>
            </div>
            <div>
              <h4>Login Name</h4>
              <span>{personalInfo.loginName}</span>
            </div>
            <div className='password-sect'>
              <TextInputCustom
                label="New Password"
                type="text"
                color="white"
                onChange={(value) => {
                  setEditData({ ...editData, newPassword: value });
                  checkPassValidation(value);
                }}
              >
                {editData.newPassword}
              </TextInputCustom>
              {validationMessage && <p className='password-message'>{validationMessage}</p>}
            </div>
            <div className='password-sect'>
              {/* {editData.password && <div onClick={generatePass}
                className={generate ? "information__password-generate active" : "information__password-generate"} />} */}
              <TextInputCustom
                label="Confirm Password"
                type="text"
                color="white"
                onChange={(value) => {
                  setEditData({ ...editData, password: value });
                  checkForPasswordValidation(value)
                }}
              >
                {editData.password}
              </TextInputCustom>
              {message && <p className='password-message'>{message}</p>}
              </div>
            <div>
              <h4>Status</h4>
              <RadioCustom onChange={radioToggle} status="ACTIVE" checked={editData.status === "ACTIVE"} type="green">ACTIVE</RadioCustom>
              <RadioCustom onChange={radioToggle} status="INACTIVE" checked={editData.status === "INACTIVE"} type="green">INACTIVE</RadioCustom>
              <RadioCustom onChange={radioToggle} status="SUSPENDED" checked={editData.status === "SUSPENDED"} type="green">SUSPENDED</RadioCustom>
              <RadioCustom onChange={radioToggle} status="CLOSED" checked={editData.status === "CLOSED"} type="green">CLOSED</RadioCustom>
            </div>
          </div>
          <div className="container credit">
            <h3>Credit</h3>
            <div className="container__body">
              <div>
                <div className="credit__input">
                  <div>
                    <TextInputCustom
                      label='Credit Limit'
                      color='white'
                      onChange={changeEditData("creditLimit")}
                      // checkFunc={creditValidation}
                      type="number"
                    >
                      {editData.creditLimit}
                    </TextInputCustom>
                  </div>
                  <div className="credit__params">
                    {parentLevel === 'Agent' ?
                      <>
                        {/* <span>{`>=${addCommas(settings.data.downlineFormInfo.downlineDetails.creditLimits.availableBalance)}`}</span> */}
                        <span>{`>=0`}</span>
                        <span>{"<=" + addCommas(Math.min(settings.data.formInfo.creditLimits.data.maxMemberCredit,
                          (settings.data.formInfo.creditLimits.data.availableBalance +
                            settings.data.downlineFormInfo.downlineDetails.creditLimits.creditLimit)))}</span>
                      </> :
                      <>
                        <span>{`>=${addCommas(settings.data.downlineFormInfo.creditLimits.data.creditLimit)}`}</span>
                        <span>{"<=" + addCommas(Math.min(settings.data.formInfo.creditLimits.data.maxAgentCredit,
                          (settings.data.formInfo.creditLimits.data.availableBalance +
                            settings.data.downlineFormInfo.downlineDetails.creditLimits.creditLimit)))}</span>
                      </>}
                  </div>
                </div>
                {userLevel !== "MA" && userLevel !== "Agent" ? <div className="credit__input">
                  <div>
                    <TextInputCustom
                      label='Agent Max Credit'
                      color='white'
                      onChange={changeEditData("maxAgentCredit")}
                      // checkFunc={creditValidation}
                      type="number"
                    >
                      {editData.maxAgentCredit}
                    </TextInputCustom>
                  </div>
                  <div className="credit__params">
                    <span>{`>=${addCommas(settings.data.downlineFormInfo.creditLimits.data.maxAgentCredit)}`}</span>
                    <span>{`<=${addCommas(Math.min(settings.data.formInfo.creditLimits.data.maxAgentCredit,
                      settings.data.downlineFormInfo.downlineDetails.creditLimits.creditLimit))}`}</span>
                  </div>
                </div> : <></>}
                {userLevel !== "Agent" ? <div className="credit__input">
                  <div>
                    <TextInputCustom
                      label='Member Max Credit'
                      color='white'
                      onChange={changeEditData("maxMemberCredit")}
                      type="number"
                    >
                      {editData.maxMemberCredit}
                    </TextInputCustom>
                  </div>
                  <div className="credit__params">
                    {userLevel == 'MA' ?
                      <span>{`>=${addCommas(settings.data.downlineFormInfo.creditLimits.data.maxAgentCredit)}`}</span> :
                      <span>{`>=${addCommas(settings.data.downlineFormInfo.creditLimits.data.maxMemberCredit)}`}</span>
                    }
                    <span>{"<=" + addCommas(Math.min(settings.data.formInfo.creditLimits.data.maxMemberCredit,
                      settings.data.downlineFormInfo.downlineDetails.creditLimits.creditLimit))}</span>
                  </div>
                </div> : <></>}
              </div>
              <TextInputCustom
                label='Payment Threshold'
                color='white'
                onChange={changeEditData("paymentThreshold")}
                // checkFunc={creditValidation}
                type="number"
              >
                {editData.paymentThreshold}
              </TextInputCustom>
            </div>


          </div>
          <div className="container bet-settings">
            <h3>Bet Settings</h3>
            <div className="check">
              <input
                className="custom-checkbox"
                id="bet-settings__check"
                onChange={checkboxToggle("betSettingsSameForAllActive")}
                type="checkbox"
                checked={editData.betSettingsSameForAllActive}
              />
              <label htmlFor="bet-settings__check">Same for all sports</label>
            </div>

            <Table>
              <thead>
                <tr>
                  <th scope="col" ></th>
                  <th scope="col" >Min Bet</th>
                  <th scope="col">Max Bet</th>
                  <th scope="col" >Max Market</th>
                </tr>
              </thead>
              <tbody>
                {editBetSettings.map((activeItem, index) => {
                  const firstItem = editBetSettings[0];
                  const requiredItem = editData.betSettingsSameForAllActive ? firstItem : activeItem
                  return <tr key={activeItem.group}>
                    <td >{titlesForBetSettings[index]}</td>
                    {["minBet", "maxBet", "maxWinPerMarket"].map(row => {
                      return <td key={row + index}>
                        <TextInputCustom
                          type="number"
                          disabled={editData.betSettingsSameForAllActive && firstItem !== activeItem}
                          onChange={(value) => editBetSettingsToggle(value, index, row)}
                          color="white"
                        >{requiredItem[row].toString()}
                        </TextInputCustom></td>
                    })}
                  </tr>
                })}

              </tbody>
            </Table>
          </div>
          <div className="container my-pt">
            <h3>My PT (PAATI)</h3>
            <div className="check">
              <input
                className="custom-checkbox"
                id="my-pt__check"
                onChange={checkboxToggle("myPTSameForAllActive")}
                type="checkbox"
                checked={editData.myPTSameForAllActive}
              />
              <label htmlFor="my-pt__check">Same for all sports</label>
            </div>
            <Table>
              <thead>
                <tr>
                  <th scope="col" >Sport</th>
                  <th scope="col" >My share (%)</th>
                </tr>
              </thead>
              <tbody>
                {editPosTakings.map((item, index) => {
                  const firstItem = editPosTakings[0];
                  const { parentPosition } = editData.myPTSameForAllActive ? firstItem : item
                  return <tr key={item.posTakingGroup}>
                    <td >{item.posTakingGroup}</td>
                    <td>
                      <TextInputCustom
                        type="number"
                        disabled={editData.myPTSameForAllActive && firstItem !== item}
                        color="white"
                        onChange={(value) => editPosTakingsToggle(value, index)}
                      >{parentPosition.toString()}
                      </TextInputCustom>
                    </td>
                  </tr>
                })}

              </tbody>
            </Table>
          </div>
          <div className="container notes">
            <h3>Notes</h3>
            <textarea onChange={(e) => setEditData({ ...editData, notes: e.target.value })} value={editData.notes}></textarea>
          </div>
        </div>


        <div className="modal-child-info__controls" >
          <Button
            className="close-button"
            variant="link"
            onClick={() => closeFunc()}
          >
            Cancel
                    </Button>
          <ButtonCustom width='65px' height='42px' color='green' onClick={submitToggle}>Save</ButtonCustom>
        </div>
      <AlertCustom
        disableFunc={() => setAlertOptions({ ...alertOptions, message: null })}
        type={alertOptions.type}
      >{!!alertOptions.message ? <p>{alertOptions.message}</p> : ''}
      </AlertCustom>
      </ModalCustom>
    </> :

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    <ModalCustom visible className="modal-descendant-info" closeFunc={() => closeFunc()}>
      <div className="container information">
        <h3>Information</h3>
        <Table>
          <thead>
            <tr>
              <th scope="col" >Username</th>
              <th scope="col" >Login Name</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td >{personalInfo.name}</td>
              <td >{personalInfo.loginName}</td>
              <td >{personalInfo.status}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container credit">
        <h3>Credit</h3>
        <Table>
          <thead>
            <tr>
              <th scope="col" >Credit Limit</th>
              {personalInfo.level === "Member" || personalInfo.level === "Agent" ? <></> : <th scope="col" >Agent Max Credit</th>}
              {personalInfo.level === "Member" ? <></> : <th scope="col">Member Max Credit</th>}
              <th scope="col">Payment Threshold</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td >{addCommas(creditLimits.creditLimit)}</td>
              {personalInfo.level === "Member" || personalInfo.level === "Agent" ? <></> : <td >{addCommas(creditLimits.maxAgentCredit)}</td>}
              {personalInfo.level === "Member" ? <></> : <td >{addCommas(creditLimits.maxMemberCredit)}</td>}
              <td >{creditLimits.paymentThreshold ? creditLimits.paymentThreshold : "-"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="container bet-settings">
        <h3>Bet Settings</h3>
        <Table>
          <thead>
            <tr>
              <th scope="col" ></th>
              <th scope="col" >Min Bet</th>
              <th scope="col">Max Bet</th>
              <th scope="col" >Max Market</th>
            </tr>
          </thead>
          <tbody>
            {betSettings.map((item, index) => <tr key={item.group}>
              <td >{titlesForBetSettings[index]}</td>
              <td >{item.minBet}</td>
              <td >{item.maxBet}</td>
              <td >{item.maxWinPerMarket}</td>
            </tr>)}

          </tbody>
        </Table>
      </div>
      <div className="container position-taking">
        <h3>Position Taking</h3>
        <Table>
          <thead>
            <tr>
              <th scope="col" >Sport</th>
              <th scope="col" >{parentLevel} Position (%)</th>
              {personalInfo.level === "Member" ? <></> :
                <th scope="col">{`${personalInfo.level} Max Position (%)`}</th>}
            </tr>
          </thead>
          <tbody>
            {posTakings.map(item => <tr key={item.posTakingGroup}>
              <td >{item.posTakingGroup}</td>
              <td >{item.parentPosition}</td>
              {personalInfo.level === "Member" ? <></> : <td >{item.maxDownLinePosition}</td>}
            </tr>)}
          </tbody>
        </Table>
      </div>
      <Button
        className="close-button"
        variant="link"
        onClick={() => closeFunc()}
      >
        Close
                </Button>
    </ModalCustom>


}
