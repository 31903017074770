import { Image } from "react-bootstrap"
import { useHelpStorage } from "../../../../Providers/HelpProvider"
import Img1 from "../../../../static/images/help/menu_user_listing.png"
import Img2 from "../../../../static/images/help/button_create_new_user.png"
import Img3 from "../../../../static/images/help/popup_create_new_user.png"
import Img4 from "../../../../static/images/help/icon_lock.png"
import HelpMenuLink from "../../../../components/HelpMenuLink"

export default () => {
  const { userLevel } = useHelpStorage()
  const user = userLevel === "sma" ? "Customer" : userLevel === "ma" ? "MA" : "Agent"
  return (
    <div>
      <h1>Creating a New Admin Account</h1>
      <div className="body">
        <p className="shortdesc">
          This section describes how to create a new user for{" "}
          {userLevel === "sma" ? "the Company (a Customer)" : `an ${user}`}.
        </p>
        <p className="last-p">
          To create a new user, follow this procedure:
          <br />
          Navigate to the <strong>Admin Users</strong> &gt; <strong>User Listing</strong> menu item.
        </p>
        <Image src={Img1} />
        <p className="last-p">
          Click the <strong>Create New User</strong> button.
        </p>
        <Image src={Img2} />
        <p className="last-p">
          Enter a username, a password and confirm the password. Also, set the user status to <strong>Active</strong> or{" "}
          <strong>Inactive</strong>.
        </p>
        <Image src={Img3} />
        <p className="last-p">
          <strong>Note:</strong> Inactive users cannot log in to the site.
          <br />A username must be at least four characters consisting of lower case letters, numbers and dots (
          <kbd>.</kbd>). Other special characters are not allowed.
          <br />
          Passwords must be between 6 and 15 characters and must contain at least one number and one letter. Special
          characters are not allowed.
          <br />
          Entering a first or last name is optional.
          <br />
          Click the <strong>Create</strong> button to create the new user.
          <br />
          <strong>Important:</strong> When the user is created, the system does not set up their access rights
          automatically. You have to set up the access rights manually for each user that you have created.
          <br />
          After the user is created, click the <img style={{ margin: 0 }} src={Img4} alt="" /> icon to set up its access
          rights.
          <br />
          <strong>Parent topic:</strong> <HelpMenuLink>Admin Users</HelpMenuLink>
          <br />
          <strong>Related tasks</strong>
          <br />
          <HelpMenuLink>Setting Up Access Rights</HelpMenuLink>
        </p>
      </div>
    </div>
  )
}
