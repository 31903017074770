import './index.scss'
import moment from "moment"
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

import { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useAuth } from '../../Providers/AuthProvider'
import { useStorage } from '../../Providers/StorageProvider';
import DateTimeRangePicker from '../../components/DateTimeRangePicker'
import ButtonCustom from '../../components/ButtonCustom'
import { Table } from 'react-bootstrap'
import StringToRecolorNumber from '../../components/StringToRecolorNumber'
import ViewAgentBets from './ViewAgentBets'
import CurrentTime, { getTime } from "../../components/CurrentTime"
import { downloadCsv } from '../../utils/createCsv'

export default ({ location }) => {
  const history = useHistory();
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState()
  const [globalSum, setGlobalSum] = useState()
  const { userName, setIsLoading } = useStorage();

  const now = new Date();
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  const [alert, setAlert] = useState({
    isDisplayed: false,
    message: "There is no data for this date range. Please select another date range."
  })
  const { location: { pathname, search } } = history;


  useEffect(async () => {
    try {
      if (!pathname.includes("bet-breakdown")) {
        setIsLoading(true)
        setAlert({ ...alert, isDisplayed: false });
        const target = new URL(window.location.href).searchParams.get("target");
        const response = await requestsToApi.get('agency/' +
          userName + '/report/user-pnl?target=' + (target ? target : '') +
          '&from=' + dateTimeRange.start.valueOf() +
          '&to=' + dateTimeRange.end.valueOf());
        if (response.data.result.event && response.data.result.event.length) {
          setAlert({ ...alert, isDisplayed: false });
          let globalSum = {
            comm: 0,
            grossComm: 0,
            netWin: 0,
            upline: 0,
            win: 0
          }
          const countSums = response.data.result.event.map((event) => {
            const newAgencies = event.agency.reduce((previousValue, agency, index, arr) => {
              const newValue = {
                comm: previousValue.comm + agency.comm,
                grossComm: previousValue.grossComm + agency.grossComm,
                netWin: previousValue.netWin + agency.netWin,
                upline: previousValue.upline + agency.upline,
                win: previousValue.win + agency.win
              }
              if (arr.length !== (index + 1)) return { ...newValue }
              else {

                globalSum = {
                  comm: (Number(globalSum.comm) + Number(newValue.comm)).toFixed(2),
                  grossComm: (Number(globalSum.grossComm) + Number(newValue.grossComm)).toFixed(2),
                  netWin: (Number(globalSum.netWin) + Number(newValue.netWin)).toFixed(2),
                  upline: (Number(globalSum.upline) + Number(newValue.upline)).toFixed(2),
                  win: (Number(globalSum.win) + Number(newValue.win)).toFixed(2)
                }
                return [...arr, newValue]
              }
            }, {
              comm: 0,
              grossComm: 0,
              netWin: 0,
              upline: 0,
              win: 0
            })
            return { ...event, agency: newAgencies }
          })
          setGlobalSum(globalSum)
          setResponseData({ ...response.data.result, event: countSums })
        }
        else setAlert({ ...alert, isDisplayed: true });
      }
      setIsLoading(false)

    } catch (e) {
      setIsLoading(false)
      console.log("error", e)
    }
  }, [dateTimeRange, pathname, search])

  const followTheLink = (href) => () => {
    history.push(location.pathname + href)
    setResponseData(null)
  }

  const getCSVData = () => {

    let data = []
    responseData.event.map((event) => {
      console.log('event', responseData)

      // event.agency.map((el) => {
        // console.log('sdfsdfsdfsdfdsfsdfsdf', el)
        data.push({
          Date: moment.unix(event.marketTime).format("DD.MM.YYYY HH:mm"),
          Sport: event.sportName,
          Sports: '',
          Competition: event.competitionName,
          Event: event.name,
          'CUS Login Name': event.agency[0].loginName,
          'CUS ID': event.agency[0].name,
          'Net Win': event.agency[0].netWin,
          'Comm': event.agency[0].grossComm,
          'Win': event.agency[0].win,
          'Comm': event.agency[0].comm,
          'P&L': event.agency[0].upline
        })
      // }
      // )
    })

    console.log(data)
    return { data: data }
  }

  return (
    <div className="pnl-by-agent">

      <div className="page-header">
        <div className="page-header__group">
          <h1>P&L Report by Agent</h1>
          {history.location.pathname.includes("bet-breakdown") ?
            <></> :
            <DateTimeRangePicker
              dateTimeRange={dateTimeRange}
              onChangeFunc={setDateTimeRange}
            />
          }
        </div>
        <ButtonCustom color="cyan" onClick={() => downloadCsv(getCSVData())}>Download CSV</ButtonCustom>
      </div>

      <div className="pnl-by-agent__main">
        <Switch>
          <Route exact path="/reports/pnl-by-agency/bet-breakdown/:currentUserId/:eventId/:myUserLogin" component={ViewAgentBets} />
          <Route exact path="/reports/pnl-by-agency" component={() => <>
            {alert.isDisplayed ? <div className="pnl-by-agent__alert">
              <span>{alert.message}</span>
            </div> :
              responseData ?
                <div className="downline-nav">
                  {responseData.upline.map((user, index, arr) => {
                    if (arr.length - 1 === index) return <i>{user.name}</i>

                    return <>
                      <i><a className="no-href" onClick={followTheLink(user.userCode === userName ? "" : `?target=${user.userCode}`)}>{user.name}</a></i>
                      <span><ChevronRightIcon size={17} /></span>
                    </>

                  })}
                </div> : <></>}

            {responseData && responseData.event && responseData.event.length ? <div className="main-page-content">
              <Table striped hover variant="dark">
                <thead>
                  <tr>
                    <th className="border_right-side"></th>
                    <th className="border_right-side" colSpan="2">MEMBER</th>
                    {responseData.agencyLevel !== "Agent" ? <th className="border_right-side">{responseData.downlineLevel === "SMA" ? "CUS" : responseData.downlineLevel.toUpperCase()}</th> : <></>}
                    <th className="border_right-side" colSpan="3">{responseData.agencyLevel === "SMA" ? "CUS" : responseData.agencyLevel.toUpperCase()}</th>
                    <th>{responseData.agencyLevel === "SMA" && userName.length === 2 ? "SMA" : "UPLINE"}</th>
                  </tr>
                  <tr>
                    <th className="border_right-side"></th>
                    <th>Net Win</th>
                    <th className="border_right-side">Comm</th>
                    {responseData.agencyLevel !== "Agent" ? <th className="border_right-side"></th> : <></>}
                    <th>Win</th>
                    <th>Comm</th>
                    <th className="border_right-side">P&L</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="border_right-side">Grand Total</th>
                    <td><StringToRecolorNumber>{globalSum.netWin}</StringToRecolorNumber></td>
                    <td className="border_right-side"><StringToRecolorNumber>{globalSum.grossComm === "0.00" ? 0 : -globalSum.grossComm}</StringToRecolorNumber></td>
                    {responseData.agencyLevel !== "Agent" ? <td className="border_right-side"><StringToRecolorNumber>
                      {responseData.agencyLevel === "SMA" && userName.length === 2 ? "NaN" : Number(globalSum.netWin) - Number(globalSum.grossComm)}
                    </StringToRecolorNumber></td> : <></>}
                    <td><StringToRecolorNumber>{globalSum.win}</StringToRecolorNumber></td>
                    <td><StringToRecolorNumber>{globalSum.comm}</StringToRecolorNumber></td>
                    <td className="border_right-side"><StringToRecolorNumber>{globalSum.comm}</StringToRecolorNumber></td>
                    <td><StringToRecolorNumber>{globalSum.upline}</StringToRecolorNumber></td>
                  </tr>
                  <tr className="delimiter-row">
                    <td className="delimiter-td" colSpan={responseData.agencyLevel !== "Agent" ? "8" : "7"}></td>
                  </tr>
                  {responseData.event.map((event) => {
                    return [<tr>
                      <td key={Math.random()} colSpan={responseData.agencyLevel !== "Agent" ? "8" : "7"} className="event-name">{event.competitionName + " - " + event.name}</td>
                    </tr>,
                    event.agency.map((agency) => {
                      return <tr key={Math.random()}>
                        {agency.loginName && agency.name ? <td className="agency-name border_right-side">
                          {responseData.downlineLevel !== "Member" ?
                            <a className="no-href" onClick={followTheLink('?target=' + agency.name)}>{agency.loginName + " (" + agency.name + ")"}</a> :
                            <span>{agency.loginName + " (" + agency.name + ")"}</span>}
                          <span style={{ margin: "0 3px 0 2px" }}>|</span>
                          <a className="no-href" onClick={followTheLink(`/bet-breakdown/${agency.name}/${event.eventId}/${responseData.upline[0].name}`)} >View Bets</a>
                        </td> :
                          <th className="border_right-side">Total</th>}
                        <td><StringToRecolorNumber>{agency.netWin}</StringToRecolorNumber></td>
                        <td className="border_right-side"><StringToRecolorNumber>{agency.grossComm === 0 ? 0 : -agency.grossComm}</StringToRecolorNumber></td>
                        {responseData.agencyLevel !== "Agent" ?
                          <td className="border_right-side"><StringToRecolorNumber>
                            {responseData.agencyLevel === "SMA" && userName.length === 2 ? "NaN" : Number(agency.netWin) - Number(agency.grossComm)}
                          </StringToRecolorNumber></td> :
                          <></>}
                        <td><StringToRecolorNumber>{agency.win}</StringToRecolorNumber></td>
                        <td><StringToRecolorNumber>{agency.comm}</StringToRecolorNumber></td>
                        <td className="border_right-side"><StringToRecolorNumber>{agency.comm}</StringToRecolorNumber></td>
                        <td><StringToRecolorNumber>{agency.upline}</StringToRecolorNumber></td>
                      </tr>
                    })
                    ]
                  })}
                </tbody>
              </Table>
            </div> : <></>}
          </>} />
        </Switch>
      </div>
    </div>
  )
}