export const numberWithCommas = num => {
  if (num === 0) return "0.00"
  return typeof num === "number"
    ? num.toLocaleString("en-IN", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    : num
}

export const positiveNumberWithCommas = num => {
  return Math.abs(num).toLocaleString("en-IN", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}
