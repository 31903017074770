import "./CasinoMeterPL.scss";
import React, { useEffect, useState } from 'react'

const CasinoMeterPL = ({roundMarkets}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="casino-meter-betting">
        <div className="casino-meter-betting__games">
          <div className="casino-meter-betting__games-item">
            <p className="casino-meter-betting__games-item-title">
              <span>Low </span><span> cards</span>
            </p>
            <div className="casino-meter-betting__games-item-cards">
              {roundMarkets && roundMarkets[0]?.marketRunner[0]?.cards?.map( card =>
                <img className="hi-low-last-result__player-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card} 
                />
              )}
            </div>
          </div>
          <div className="casino-meter-betting__item-wrapper">
            <div className="casino-meter-betting__games-item">
              <p className="casino-meter-betting__games-item-title">
                <span>High </span><span> cards </span>
              </p>
              <div className="casino-meter-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.marketRunner[1]?.cards?.map( card =>
                  <img className="hi-low-last-result__player-cards-card"
                       key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )}
              </div>
            </div>
            <div className="casino-meter-betting__games-item">
              <div className="casino-meter-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.indexCard?.map( card =>
                  <img className="hi-low-last-result__player-cards-card"
                       key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CasinoMeterPL;

