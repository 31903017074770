import { Image } from "react-bootstrap"
import { useHelpStorage } from "../../../../Providers/HelpProvider"
import Img1 from "../../../../static/images/help/menu_user_listing.png"
import Img2 from "../../../../static/images/help/button_lock_user_listing.png"
import Img3 from "../../../../static/images/help/popup_access_rights_agency.png"
import Img4 from "../../../../static/images/help/icon_lock.png"
import HelpMenuLink from "../../../../components/HelpMenuLink"

export default () => {
  const { userLevel } = useHelpStorage()
  const user = userLevel === "sma" ? "Customer" : userLevel === "ma" ? "MA" : "Agent"
  return (
    <div>
      <h1>Setting Up Access Rights</h1>
      <div className="body">
        <p className="shortdesc">This section describes how to set up or change the access rights for a user.</p>
        <p>To set up or change the access rights, follow this procedure:</p>
        <p className="last-p">
          Navigate to the <strong>Admin Users</strong> &gt; <strong>User Listing</strong> menu item.
        </p>
        <Image src={Img1} />
        <p className="last-p">
          Click the <img style={{ margin: 0 }} src={Img4} alt="" /> icon to open the access rights pane.
        </p>
        <Image src={Img2} />
        <p className="last-p">Select the radio buttons to allow view-only, full access or no access.</p>
        <Image src={Img3} />
        <p className="last-p">
          Click <strong>Save</strong> to confirm the access rights.
          <br />
          <strong>Parent topic:</strong> <HelpMenuLink>Admin Users</HelpMenuLink>
        </p>
      </div>
    </div>
  )
}
