import "./index.scss"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import { Table } from "react-bootstrap"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { numberWithCommas } from "../../utils/dataTransform"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime from "../CurrentTime"

export default ({ currentUserId, setDownlineName }) => {
  const { userName, userLevel } = useStorage()
  const [responseData, setResponseData] = useState({})
  const history = useHistory()
  const { requestsToApi } = useAuth()
  const isAgency = history.location.pathname.includes("/reports/pnl-by-agency/")

  useEffect(async () => {
    try {
      const pathnameArr = history.location.pathname.split("/")
      const response = await requestsToApi.get(
        `/agency/${userName}/report${
          userLevel === "Member" ? "/member-order-list" : "/order-list"
        }?type=settled&page=1${
          isAgency
            ? `&dl=${pathnameArr.slice(-3)[0]}&eventId=${pathnameArr.slice(-2)[0]}`
            : `&marketId=${pathnameArr.slice(-1)[0]}`
        } `
      )
      setResponseData(response.data.result)
      if (isAgency) {
        setDownlineName(`${currentUserId} - ${response.data.result.pageRows[0].eventName}`)
      } else {
        setDownlineName(
          response.data.result.pageRows
            ? `${response.data.result.pageRows[0].eventName} - ${response.data.result.pageRows[0].marketName}`
            : "market"
        )
      }
    } catch (error) {
      console.log(error?.message)
    }
  }, [history.location.search])

  return (
    <div className="breackdown-statement">
      <div className="breackdown-statement__main">
        <Table striped hover variant="dark" className="breackdown-statement-table">
          <thead>
            <tr>
              {userLevel !== "Member" && (
                <th scope="col" className="text-align-left">
                  Member
                </th>
              )}
              <th scope="col" className="text-align-left">
                Placed
              </th>
              <th scope="col" className="text-align-left">
                Selection
              </th>
              <th scope="col" className="text-align-left">
                Bet ID
              </th>
              <th scope="col" className="text-align-left">
                In Play
              </th>
              <th scope="col" className="text-align-left">
                1- Click
              </th>
              <th scope="col" className="text-align-left">
                Type
              </th>
              <th scope="col">Odds</th>
              <th scope="col">Stake</th>
              <th scope="col">Status</th>
              <th scope="col">
                <p>Member</p>
                <p>Win/Loss</p>
              </th>
              <th scope="col">
                <p>Agent</p>
                <p>Win/Loss</p>
              </th>
              {(userLevel === "MA" || userLevel === "SMA") && (
                <th scope="col">
                  <p>MA</p>
                  <p>Win/Loss</p>
                </th>
              )}
              {userLevel === "SMA" && (
                <th scope="col">
                  <p>CUS</p>
                  <p>Win/Loss</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {responseData.pageRows ? (
              responseData.pageRows.map(item => (
                <tr key={item.firstMatchedTime}>
                  {userLevel !== "Member" && (
                    <td className="text-align-left">{`${item.loginName} (${item.userName})`}</td>
                  )}

                  <td className="text-align-left">
                    {
                      <CurrentTime format="DD/MM/YYYY HH:mm:ss">
                        {/* {history.location.pathname.includes("/account/statement/")
                          ? item.betPlacedDate
                          : item.marketSettledDate} */}
                        {item.betPlacedDate}
                      </CurrentTime>
                    }
                  </td>
                  <td className="text-align-left">
                    {item.marketBettingType === "ODDS" ? item.selectionName : item.line}
                  </td>
                  <td className="text-align-left">{item.apolloBetId}</td>
                  <td className="text-align-left">{item.inPlay ? "Y" : "N"}</td>
                  <td className="text-align-left">{item.oneClick ? "Y" : "N"}</td>
                  <td className="text-align-left">
                    {item.marketBettingType === "ODDS" ? (!!!item.side ? "Back" : "Lay") : !!!item.side ? "Yes" : "No"}
                  </td>
                  <td>
                    {item.price.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                  </td>
                  <td>{numberWithCommas(item.sizeTotal)}</td>
                  <td className={item.outcome === "LOST" ? "-negative" : "-positive"}>{item.outcome}</td>
                  <td>
                    <StringToRecolorNumber>{item.memberWin}</StringToRecolorNumber>
                  </td>
                  <td>
                    <StringToRecolorNumber>{item.agentWin}</StringToRecolorNumber>
                  </td>
                  {(userLevel === "MA" || userLevel === "SMA") && (
                    <td>
                      <StringToRecolorNumber>{item.maWin}</StringToRecolorNumber>
                    </td>
                  )}
                  {userLevel === "SMA" && (
                    <td>
                      <StringToRecolorNumber>{item.smaWin}</StringToRecolorNumber>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
