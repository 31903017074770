import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BetBreakdown from '../../../components/BetBreakdown';
import { useStorage } from '../../../Providers/StorageProvider';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

import './index.scss'

export default ({ match }) => {
  const { userLevel, userName } = useStorage();
  const { params: { currentUserId } } = match;
  const [downlineName, setDownlineName] = useState("market");
  const history = useHistory();

  const goBack = (hash) => {
    const splitedURL = history.location.pathname.split("/")
    history.push(splitedURL.slice(0, splitedURL.length - 4).join('/') + (hash ? hash : ""))
  }

  return (<div className="pnl-by-agent__view-bets">
    <div className="downline-nav">
      <a className="no-href" onClick={() => goBack()}>{userName}</a>
      <span><ChevronRightIcon size={17} /></span>
      <a className="no-href" onClick={() => goBack(currentUserId)}>{currentUserId}</a>
      <span><ChevronRightIcon size={17} /></span>
      <i>{downlineName}</i>
    </div>
    <BetBreakdown userLevel={userLevel} currentUserId={currentUserId} setDownlineName={setDownlineName} />
  </div>)
}