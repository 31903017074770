import { useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import RadioCustom from '../../components/RadioCustom';
import SwitchCustom from '../../components/SwitchCustom';
import { useAuth } from '../../Providers/AuthProvider';
import { useStorage } from '../../Providers/StorageProvider';
import './index.scss';
import ArrowIcon from 'mdi-react/MenuDownIcon'


export default () => {
  const { requestsToApi } = useAuth();
  const { userName: myName, setIsLoading } = useStorage();
  const [responseData, setResponseData] = useState(null);
  const [changedSwitch, setChangedSwitch] = useState(null);
  const [activeRadioFilter, setActiveRadioFilter] = useState({
    title: "Cricket",
    id: 4
  })


  const changeFilter = (title) => {
    const id = title === "Cricket" ? 4 :
      title === "Football" ? 1 :
        title === "Tennis" ? 2 :
          title === "Horse Racing" ? 7 :
            title === "Greyhound Racing" ? 4339 :
              title === "Casino" ? 1444000 :
                title === "Other" ? 0 : null;
    setActiveRadioFilter({ title, id })
  }

  useEffect(async () => {
    try {
      setIsLoading(true)
      setResponseData(null)
      let response = await requestsToApi.get(`agency/${myName}/agency-mgmt/menu-value-pt?sportTypeId=${activeRadioFilter.id}`)
      response.data.result.children = response.data.result.children.map(itemLvl1 => {
        if (itemLvl1.children.length) {
          return {
            ...itemLvl1, children: itemLvl1.children.map(itemLvl2 => {
              return itemLvl2.children.length ? { ...itemLvl2, isOpened: true } : itemLvl2
            }), isOpened: true
          }
        } else return itemLvl1
      })
      setIsLoading(false)

      setResponseData(response.data.result)

    } catch (e) {
      console.log("error", e?.message)
      setIsLoading(false)
    }
  }, [activeRadioFilter])

  const changeTargetAndLowerActivity = (itemId, value) => {

    const updatedData = {
      ...responseData, children: [...responseData.children].map(itemLvl1 => itemLvl1.id === itemId ?
        {
          ...itemLvl1,
          children: itemLvl1.children
            .map(itemLvl2 => ({
              ...itemLvl2,
              children: itemLvl2.children
                .map(itemLvl3 => ({ ...itemLvl3, isPtOn: value })),
              isPtOn: value
            })),
          isPtOn: value
        } : {
          ...itemLvl1,
          children: itemLvl1.children
            .map(itemLvl2 => itemLvl2.id === itemId ?
              {
                ...itemLvl2,
                children: itemLvl2.children
                  .map(itemLvl3 => ({ ...itemLvl3, isPtOn: value })),
                isPtOn: value
              } :
              {
                ...itemLvl2,
                children: itemLvl2.children
                  .map(itemLvl3 => itemLvl3.id === itemId ?
                    { ...itemLvl3, isPtOn: value } : itemLvl3)
              }
            )
        }
      )
    }
    setResponseData(updatedData)
  }

  const changeTarget = (itemId, param) => {

    const updatedData = {
      ...responseData, children: [...responseData.children].map(itemLvl1 => itemLvl1.id === itemId ?
        {
          ...itemLvl1,
          [param]: !itemLvl1[param]
        } : {
          ...itemLvl1,
          children: itemLvl1.children
            .map(itemLvl2 => itemLvl2.id === itemId ?
              {
                ...itemLvl2,
                [param]: !itemLvl2[param]
              } :
              {
                ...itemLvl2,
                children: itemLvl2.children
                  .map(itemLvl3 => itemLvl3.id === itemId ?
                    { ...itemLvl3, [param]: !itemLvl3[param] } : itemLvl3)
              }
            )
        }
      )
    }
    setResponseData(updatedData)
  }

  const switchToggle = async (target) => {
    changeTarget(target.id, "isPtOn")
    setChangedSwitch(target)
  }

  useEffect(async () => {
    try {
      if (changedSwitch) {
        const data = {
          isPtOn: !changedSwitch.isPtOn,
          menuValue: changedSwitch.id,
          menuValueType: changedSwitch.type,
          name: changedSwitch.name,
          path: changedSwitch.path
        }
        await requestsToApi.put(`agency/${myName}/agency-mgmt/menu-value-pt`, data)
        changeTargetAndLowerActivity(changedSwitch.id, !changedSwitch.isPtOn)
      }
    } catch (e) {
      console.log("error", e.message)
      changeTarget(changedSwitch.id, "isPtOn")
    }
  }, [changedSwitch])

  return <div className="event-management">
    <div className="page-header">
      <h1>Event Management</h1>
      <div className="filters">
        {["All", "Cricket", "Football", "Tennis", "Horse Racing", "Greyhound Racing", "Casino", "Other"]
          .map(title => <RadioCustom
            key={title}
            type="green"
            checked={activeRadioFilter.title === title}
            onChange={() => changeFilter(title)}
          >{title}
          </RadioCustom>)}

      </div>
    </div>
    <div className="event-management__menu">
      {responseData && responseData.children.length ? responseData.children.map((itemLvl1) => {
        return <Accordion key={Math.random()} className="menu-item" activeKey={itemLvl1.isOpened ? "0" : null}>
          <Card>
            <Card.Header>
              <div>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => changeTarget(itemLvl1.id, "isOpened")}>
                  {itemLvl1.children.length ? <ArrowIcon className={itemLvl1.isOpened ? "active" : ""} /> : <></>}
                  {itemLvl1.name}
                </Accordion.Toggle>
              </div>

              <div className="event-management__switch">
                <SwitchCustom defaultValue={itemLvl1.isPtOn} onChange={() => switchToggle(itemLvl1)} type="white" />
              </div>


            </Card.Header>
            {itemLvl1.children.map((itemLvl2) => <Accordion.Collapse key={Math.random()} eventKey="0">
              <Accordion className="group" activeKey={itemLvl2.isOpened ? "0" : null}>
                <Card>
                  <Card.Header>
                    <div>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => changeTarget(itemLvl2.id, "isOpened")}>
                        {itemLvl2.children.length ? <ArrowIcon className={itemLvl2.isOpened ? "active" : ""} /> : <></>}
                        {itemLvl2.name}
                      </Accordion.Toggle>
                    </div>

                    <div className="event-management__switch">
                      <SwitchCustom defaultValue={itemLvl2.isPtOn} onChange={() => switchToggle(itemLvl2)} type="white" />
                    </div>
                  </Card.Header>
                  {itemLvl2.children.length ? itemLvl2.children.map(itemLvl3 => <Accordion.Collapse key={Math.random()} eventKey="0">
                    <Card>
                      <Card.Header>
                        <div>
                          <span>{itemLvl3.name}</span>
                        </div>

                        <div className="event-management__switch">
                          <SwitchCustom defaultValue={itemLvl3.isPtOn} onChange={() => switchToggle(itemLvl3)} type="white" />
                        </div>
                      </Card.Header>
                    </Card>
                  </Accordion.Collapse>
                  ) : <></>}
                </Card>
              </Accordion>
            </Accordion.Collapse>
            )}
          </Card>
        </Accordion>
      }) : <></>}
    </div>


  </div>
}