import TextInputCustom from '../../components/TextInputCustom'
import DropdownRange from '../../components/DropdownRange'
import SearchUserInput from '../../components/SearchUserInput'
import DropdownCustom from '../../components/DropdownCustom'
import {
  dropdownMarketOptions,
  dropdownSportOptions,
} from './casinoReportData'
import ButtonCustom from '../../components/ButtonCustom'
import { useEffect, useState } from 'react'

export default ({ options, resetOptions, updateOptions, className, casinoToggle }) => {

  const [newOptions, setNewOptions] = useState(options);

  const changeValue = (value, filterTitle) => {
    // if (filterTitle === "sportDropdown") {
    //     setNewOptions({ ...newOptions, sportDropdown: value, marketTypeDropdown: 'All' })
    // } else {
    setNewOptions({ ...newOptions, [filterTitle]: value })
    // }
  }

  useEffect(() => {
    setNewOptions(options)
  }, [options])

  return <div className={"page-header__filters " + className}>
    {casinoToggle === "xg" ? <div>
      <DropdownCustom
        title="Game"
        options={dropdownSportOptions}
        activeOption={newOptions.sportDropdown}
        onChange={(value) => changeValue(value, "sportDropdown")}
      />
      <DropdownCustom
        title="Market Type"
        options={dropdownMarketOptions}
        activeOption={newOptions.marketTypeDropdown}
        onChange={(value) => changeValue(value, "marketTypeDropdown")}
      />

    </div> : <></>}
    <div>
      <TextInputCustom
        onChange={(value) => changeValue(value, "IDInput")}
        placeholder={(casinoToggle === "xg" ? "Game" : "Session") + " ID"}
      >
        {newOptions.IDInput}
      </TextInputCustom>
      <SearchUserInput
        color="black"
        placeholder="Agency/Member Name"
        dropdownClickFunc={(value) => setNewOptions({ ...newOptions, nameInput: value.loginName, userCode: value.userCode })}
        defaultInputValue={newOptions.nameInput}
      />
    </div>
    <div className="filters__control-buttons">
      <ButtonCustom onClick={() => updateOptions(newOptions)} color="cyan">Apply</ButtonCustom>
      <ButtonCustom onClick={() => { setNewOptions(options); resetOptions() }} color="red">Clear</ButtonCustom>
    </div>
  </div>
}