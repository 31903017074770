import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Nav, Navbar } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useAuth } from "../../../Providers/AuthProvider"
import DateTimeRangePicker from "../../../components/DateTimeRangePicker"
import ButtonCustom from "../../../components/ButtonCustom"
import FilterIcon from "mdi-react/FilterIcon"
import { defaultFilterValues, sportOptionsId, marketOptionsId } from "./betListData"

import moment from "moment"
import { useHistory } from "react-router-dom"
import Current from "./current"
import Past from "./past"
import { useStorage } from "../../../Providers/StorageProvider"
import Filters from "./filters"
import PaginationCustom from "../../../components/PaginationCustom"

export default ({ userId, userLevel }) => {
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading } = useStorage()
  const history = useHistory()
  const isMaster = userLevel !== "Member"
  const [typeOfRequest, setTypeOfRequest] = useState(history.location.hash === "#past" ? "settled" : "matched")
  const [responseData, setResponseData] = useState(null)
  const [isShowFilters, setIsShowFilters] = useState(false)
  const [isShowFiltersApl, setIsShowFiltersApl] = useState(false)
  const [filtersOptions, setFilterOptions] = useState(defaultFilterValues)
  const [sportCasinoToggle, setSportCasinoToggle] = useState("sports")
  const [activePage, setActivePage] = useState(1)
  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  const [alert, setAlert] = useState({
    isDisplayed: false,
    message: "There is no data for selected filters."
  })

  const getPageDataRequest = async () => {
    try {
      //&sportId=1&marketType=ALL&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      //&sportId=4&marketType=ALL&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      //&sportId=1&marketType=MATCH_ODDS&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      //&sportId=1444000&marketType=ALL&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      //&sportId=1444000&marketType=ALL&eventName=Texas%20Hold%27em&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      //&sportId=1444000&marketType=WIN_ONLY&whiteLabelSkinId=allSkins&providerId=-1&dl=HZ0501&loginAsLevel=MA&page=1
      setIsLoading(true)
      setResponseData(null)
      setAlert({ ...alert, isDisplayed: false })
      const url = isMaster
        ? "agency/" +
        userName +
        "/report/order-list?type=" +
        typeOfRequest +
        "&from=" +
        dateTimeRange.start.valueOf() +
        "&to=" +
        dateTimeRange.end.valueOf() +
        "&sportId=" +
        sportOptionsId[sportCasinoToggle].filter(item => item.title === filtersOptions.sportDropdown)[0].id +
        "&marketType=" +
        marketOptionsId[sportCasinoToggle].filter(item => item.title === filtersOptions.marketTypeDropdown)[0].id +
        (filtersOptions.sportDropdown === "Texas Hold'em" && sportCasinoToggle === "casino"
          ? "&eventName=Texas%20Hold%27em"
          : "") +
        "&whiteLabelSkinId=allSkins" +
        "&providerId=-1" +
        (filtersOptions.oddsFrom ? `&oddsFrom=${filtersOptions.oddsFrom}` : "") +
        (filtersOptions.oddsTo ? `&oddsTo=${filtersOptions.oddsTo}` : "") +
        (filtersOptions.stakeFrom ? `&stakesFrom=${filtersOptions.stakeFrom}` : "") +
        (filtersOptions.stakeTo ? `&stakesTo=${filtersOptions.stakeTo}` : "") +
        (!!filtersOptions.user.loginName ? `&searchUserLogin=${filtersOptions.user.loginName}` : "") +
        (!!filtersOptions.user.userCode ? `&searchUserCode=${filtersOptions.user.userCode}` : "") +

        (filtersOptions.eventInput
          ? sportCasinoToggle === "sports"
            ? `&eventName=${filtersOptions.eventInput}`
            : `&eventId=${filtersOptions.eventInput}`
          : "") +
        (userId ? `&dl=${userId}&loginAsLevel=SMA` : "") +
        "&page=" +
        activePage
        : "agency/" +
        userName +
        "/report/member-order-list?memberCode=" +
        userId +
        "&from=" +
        dateTimeRange.start.valueOf() +
        "&to=" +
        dateTimeRange.end.valueOf() +
        "&orderStatus=" +
        typeOfRequest +
        "&page=" +
        activePage
      const response = await requestsToApi.get(url)
      if (
        (isMaster && response.data.result.pageRows && response.data.result.pageRows.length) ||
        (!isMaster && response.data.result.length)
      )
        setAlert({ ...alert, isDisplayed: false })
      else setAlert({ ...alert, isDisplayed: true })
      setActivePage(activePage)
      setResponseData(response.data.result)
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPageDataRequest()
  }, [dateTimeRange, typeOfRequest, sportCasinoToggle, activePage, filtersOptions ])

  useEffect(() => {
    setFilterOptions(defaultFilterValues)
  }, [sportCasinoToggle])

  const radioToggle = e => {
    setResponseData(null)
    setSportCasinoToggle(e.target.attributes["status"].value)
    setFilterOptions(defaultFilterValues)
  }

  const showFilters = () => setIsShowFilters(!isShowFilters)

  const followTheLink = href => () => {
    history.push(history.location.pathname + href)
    setResponseData(null)
    setActivePage(1)
    if (history.location.hash === "#past") setTypeOfRequest("settled")
    else setTypeOfRequest("matched")
  }

  const tabAdjuster = () => {
    switch (history.location.hash) {
      case "#past":
        return <Past data={responseData} userLevel={userLevel} />
      default:
        return <Current setTypeOfRequest={setTypeOfRequest} data={responseData} userLevel={userLevel} typeOfRequest={typeOfRequest} />
    }
  }

  return (
    <div className="bet-list-report">
      <div className="page-header">
        <div className="page-header__main">
          <div>
            <div>
              <DateTimeRangePicker
                dateTimeRange={dateTimeRange}
                onChangeFunc={result => {
                  setResponseData(null)
                  setDateTimeRange(result)
                }}
              />
              {isMaster && (
                <>
                  <input
                    type="checkbox"
                    onChange={showFilters}
                    id="bet-list-report__filter"
                    style={{ display: "none" }}
                  />
                  <label className="filter__toggle" htmlFor="bet-list-report__filter">
                    <FilterIcon size={20}  className={
                            isShowFiltersApl ? "active" : ""
                          }/>
                  </label>
                </>
              )}
            </div>
            {isMaster && (
              <div className="bet-list-report__radios">
                <RadioCustom name="category" status="sports" type="green" onChange={radioToggle} checked>
                  Sports
                </RadioCustom>
                <RadioCustom name="category" status="casino" type="green" onChange={radioToggle}>
                  CASINO
                </RadioCustom>
              </div>
            )}
          </div>

          {isMaster && (
            <ButtonCustom className="refresh__button" color="cyan">
              Download CSV
            </ButtonCustom>
          )}
        </div>
        <Filters
          updateOptions={newOptions => {
            setFilterOptions(newOptions)
            setIsShowFiltersApl(true)
          }}
          options={filtersOptions}
          sportCasinoToggle={sportCasinoToggle}
          resetOptions={() => {
            setFilterOptions(defaultFilterValues)
            setIsShowFiltersApl(false)
          }}
          className={isShowFilters ? "" : "invisible"}
        />
      </div>
      {alert.isDisplayed && (
        <div className="bet-list-report__alert">
          <span>{alert.message}</span>
        </div>
      )}
      <div className="bet-list-report__main">
        <Navbar className="main-tabs" variant="dark">
          <Nav defaultActiveKey={history.location.hash === "" ? "#current" : history.location.hash} className="mr-auto">
            <Nav.Link href="#current" onClick={followTheLink("#current")}>
              Current
            </Nav.Link>
            <Nav.Link href="#past" onClick={followTheLink("#past")}>
              Past
            </Nav.Link>
          </Nav>
          {responseData && (
            <PaginationCustom
              activePage={activePage}
              changeActivePage={(el) => {
                setResponseData(null)
                setActivePage(el)
              }}
              totalPages={responseData.lastPage}
            />
          )}
        </Navbar>
        <div className="main-report-content">{tabAdjuster()}</div>
        {responseData && (
          <PaginationCustom
            activePage={activePage}
            changeActivePage={(el) => {
              setResponseData(null)
              setActivePage(el)
            }}
            totalPages={responseData.lastPage}
          />
        )}
      </div>
    </div>
  )
}
