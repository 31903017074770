import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/menu_member_listing_agent.png'
import Img1MA from '../../../../static/images/help/menu_agent_listing_ma.png'
import Img1SMA from '../../../../static/images/help/menu_ma_listing_sma.png'
import Img2Agent from '../../../../static/images/help/member_listing_agent.png'
import Img2MA from '../../../../static/images/help/agent_listing_ma.png'
import Img2SMA from '../../../../static/images/help/ma_listing_sma.png'
import Img3Agent from '../../../../static/images/help/popup_changing_status_suspended_agent.png'
import Img3MA from '../../../../static/images/help/popup_changing_status_suspended_ma.png'
import Img3SMA from '../../../../static/images/help/popup_changing_status_suspended_sma.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()

  return <div>
    <h1>Changing the Status of {userLevel === "sma" ?
      "an MA" : userLevel === "ma" ? "an Agent" : "a Member"} Account</h1>
    <div className="body">

      <p className="shortdesc">This section describes the various user statuses in the site and how to change it.</p>

      <p>
        There are four user statuses available for {childrenLevel}s:<br />
        <strong>Active</strong> {childrenLevel}s can log in to the site to&nbsp;
                {userLevel === "agent" ? "view and adjust their own settings or to place bets." :
          `manage their own settings or those of their${userLevel === "ma" ? " Agents or" : ""} 
                Members, and downline Members can place bets in the system.`} <br />
        <strong>Suspended</strong> {childrenLevel}s can log in to the site to&nbsp;
                {userLevel === "agent" ? "view and adjust their own settings but they cannot place bets." :
          `manage their own settings or those of their${userLevel === "ma" ? " Agents or" : ""} 
                Members but downline Members cannot place bets.`} <br />
        <strong>Inactive</strong> {childrenLevel}s cannot log in to the site{userLevel !== "agent" ?
          " and neither can their downline users" : ""}.
                Inactive users must contact their upline to have their account reenabled.<br />
        <strong>Closed</strong> {childrenLevel}s cannot log in to the site{userLevel !== "agent" ?
          " and neither can their downline users" : ""}.
                As this status cannot be changed, users with a closed account are locked out of the system permanently
                {userLevel !== "agent" ? " and so are their downline Members" : ""}.
            </p>
      <p>To change the status of {userLevel === "sma" ?
        "an MA" : userLevel === "ma" ? "an Agent" : "a Member"} account, follow this procedure:</p>

      <p className="last-p">Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu item and click the <strong>Login Name</strong>
            &nbsp;or the <strong>ID</strong> of the {childrenLevel} whose status you want to change.</p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">In the <strong>Information</strong> section, change the <strong>Status</strong>&nbsp;
            of the {childrenLevel} to <strong>Active</strong>, <strong>Suspended</strong> or <strong>Inactive</strong>.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">
        <strong>Important:</strong> Closed status cannot be changed.
                Users with a closed account are locked out of the system permanently which means that they or their downline will not be able log in to the system.
                For more information, see <HelpMenuLink>{`Closing an ${childrenLevel} Account`}</HelpMenuLink>.<br />
                Click the <strong>Save</strong> button to change the status of the {childrenLevel} account.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink><br />
      </p>
    </div>
  </div>
}