import './index.scss'
import ButtonCustom from '../../components/ButtonCustom'
import TextInputCustom from '../../components/TextInputCustom'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../Providers/AuthProvider'
import { useStorage } from '../../Providers/StorageProvider';


export default ({ location }) => {
  const [searchValue, setSearchValue] = useState('');
  const { userName, setIsLoading } = useStorage();
  const history = useHistory();
  const { requestsToApi } = useAuth();
  const [getReqTrigger, setGetReqTrigger] = useState(false);

  const downlineUsersReq = async (parent) => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(`agency/${userName}/agency-mgmt/downline?parent=${parent}`)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error?.message)
    }
  }


  return (
    <div className="position-taking">
      <div className="position-taking__header page-header">
        <div className="position-taking__title">
          <h1>Position Taking Listing</h1>
        </div>
        <div className="position-taking__search">
          <p>Search by Login Name</p>
          <TextInputCustom placeholder="Agency Member Name" color="white" onChange={setSearchValue} autocomplete="off">{searchValue}</TextInputCustom>
        </div>
        <div></div>
        <div className="position-taking__buttons">
          <ButtonCustom disabled>Edit PT</ButtonCustom>

        </div>
      </div>
      <div className="position-taking__main">

      </div>
    </div>
  )
}