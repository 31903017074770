import "./index.scss"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import ModalCustom from "../ModalCustom"
import TextInputCustom from "../TextInputCustom"
import { passValidation } from "../../utils/validation"
import RadioCustom from "../RadioCustom"
import { Button, Table } from "react-bootstrap"
import ButtonCustom from "../ButtonCustom"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Providers/AuthProvider"
import AlertCustom from "../AlertCustom"
import axios from "axios"

const modalRoot = document.querySelector("#modal-root");

export default ({ settings, closeFunc }) => {
  const history = useHistory()
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [generate, setGenerate] = useState(false);
  const { requestsToApi } = useAuth();
  const [isSumbitting,setSubmitting] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  });

  useEffect(()=>{
    // generatePass();
  },[])

  const changeInputValue = (key) => (value) => {
    // setInputValues({ ...inputValues, [key]: value })
    if(key === 'password'){
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
//    await validateInput("amount", amount)
    if (password && password === confirmPassword) {
      try {
        console.log({
            "action": settings.action,
            "user":  settings.user.user.name,
            "password": password,
          });
        // const response = await axios.post( `https://dadm.gemexch.bet/api/agency/${settings.master}/agency-mgmt/downline/actions`,
        setSubmitting(true);
        const response = await requestsToApi.post( `/agency/${settings.master}/agency-mgmt/downline/actions`,
          {
            "action": settings.action,
            "user":  settings.user.user.name,
            "password": password,
          }
        )
        setAlertOptions({ message: `Password was updated successfully.`, type: "green" })
        closeFunc(true);
      } catch (error) {
        setSubmitting(false);
        setAlertOptions({
          message: "Something went wrong",
            type: "red"
        })
        console.log("ERRRROOOORRRR", error)
        setSubmitting(false);
      }
      history.push("/agency-management/downline")
    }
  };

  const generatePass = () => {
    setGenerate(true);
    setTimeout(() => setGenerate(false), 500);
    const newPass = () => {
    //   const pass = Array(8).fill().map(()=>((Math.random()*36)|0).toString(36)).join("")
    //   const index = Math.floor(Math.random() * pass.length);
    //   if (!parseInt(pass)) return newPass();
    //   if (!!Number(pass[index])) return newPass();
    //   return pass.replace(pass[index], pass[index].toUpperCase());
    const randomNumber = Math.floor(Math.random() * (22 - 0 + 1)) + 0;
    let randomNumber2 = Math.floor(Math.random() * (7 - 1 + 1)) + 1;
    let pass = "";
    let number = "";
    for(let i = randomNumber;i<randomNumber+4;i++){
        pass = pass + String.fromCharCode(i + 97);
        number = number + randomNumber2;
        randomNumber2++;
    }
    return pass+number;
    }
    // setPassword(newPass())
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <ModalCustom visible className="modal-child-info" closeFunc={() => closeFunc()} width={"500px"}>
      <form method={'POST'} onSubmit={onSubmit}>
      <div className="deposit-pop-up">
        <span className="deposit-pop-up-title">Change Password</span>
        <div className="deposit-pop-up-info">
        {/* <div className="new-ma__information__pass-input-wrapper"> */}
            {/* <div className="deposit-pop-up-info-title">Password</div> */}
            {/* <div onClick={generatePass} className={generate ? "new-ma__information__pass-generate-2 active" : "new-ma__information__pass-generate-2"}/> */}
            <TextInputCustom
              label="Password"
              type="text"
              color="white"
              onChange={changeInputValue('password')}
              // checkFunc={() => passValidation(password)}
              // onBlur={checkLoginAvailable}
            >
              {password}
            </TextInputCustom>
            <div className="deposit-pop-up-info-title">Confirm Password</div>
            <TextInputCustom
              // label="Confirm Password"
              type="text"
              color="white"
              onChange={changeInputValue('confirmPassword')}
              // checkFunc={() => passValidation(password)}
              // onBlur={checkLoginAvailable}
            >
              {confirmPassword}
            </TextInputCustom>
            {password && confirmPassword && password !== confirmPassword && (<div>Password and confirm password didn't match</div>)}
          {/* </div> */}
          
        </div>
        {/* <div className="deposit-pop-up-info">
            <div onClick={() => copyText(password)} className="new-ma__information__copy-button">
                <span>Copy Password</span>
            </div>
        </div> */}
        <div className="deposit-pop-up-buttonsRow">
          <div className="deposit-pop-up-buttonsRow-back" onClick={() => closeFunc()}>Back</div>
          <button disabled={isSumbitting} className="deposit-pop-up-button">
            Submit
          </button>
        </div>
        <AlertCustom disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
          {alertOptions.message}
        </AlertCustom>
      </div>
      </form>
  </ModalCustom>
  )
}
