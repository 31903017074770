import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/report_by_agent_agent.png'
import Img1MA from '../../../../static/images/help/report_by_agent_ma.png'
import Img1SMA from '../../../../static/images/help/report_by_agent_sma.png'
import Img2MA from '../../../../static/images/help/report_by_agent_agent_ma.png'
import Img2SMA from '../../../../static/images/help/report_by_agent_ma_sma.png'
import Img3SMA from '../../../../static/images/help/report_by_agent_agent_sma.png'
import Img4Agent from '../../../../static/images/help/report_by_agent_bets1_agent.png'
import Img4MA from '../../../../static/images/help/report_by_agent_bets_ma.png'
import Img4SMA from '../../../../static/images/help/report_by_agent_bets_sma.png'
import Img5Agent from '../../../../static/images/help/report_by_agent_bets2_agent.png'
import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage()
  const descendants = `${userLevel === "sma" ? "MA, " : ""}
    ${userLevel !== "agent" ? "Agent or " : ""}Member`;

  return <div>
    <h1>P&L Report by Agent</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to get more information about the aggregated performance of a specific {descendants} on all available markets in a given time period.</p>

      <p>Specify a time frame using the <strong>From</strong> and <strong>To</strong> drop-down menus and click the <strong>Search</strong> button to display the P&L Report by Agent.</p>

      <br />

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br /><br />

      <p>
        The report above shows that the Member lost
                {userLevel === "agent" ?
          " 10 each. You kept 4 (2 from each Member) and passed up 16 (8 for each Member)." :
          userLevel === "ma" ?
            " and paid their upline 20. The Agent paid up 16 to you. You kept 4 and paid 12 up to the Customer." :
            " and paid their upline 20. The MA paid 12 to you. You kept 4 and paid up 8 to the Company."}
      </p>

      {userLevel === "agent" ? null :
        <><Image src={userLevel === "ma" ? Img2MA : Img2SMA} /><br /><br /></>}



      {userLevel === "sma" ? <>
        <p className="last-p">Clicking on the MA, it is shown that the Agent paid up 16 to them. The MA kept 4 and paid up 12 to you.</p>
        <br /><Image src={Img3SMA} />

      </> : null}

      {userLevel === "agent" ? null :
        <p>{userLevel === "ma" ? "Clicking on the Agent shows" : "When clicking on the Agent, it is revealed"}
                    &nbsp;that there were actually two Members that lost 10 each.
                    The Agent kept 4 (2 from each Member) and passed up 16 (8 for each Member) to their upline.</p>
      }

      <p className="last-p">You can quickly access the list of bets the Members placed by clicking <strong>View Bets</strong>.</p>

      <Image src={userLevel === "agent" ? Img4Agent :
        userLevel === "ma" ? Img4MA : Img4SMA} />

      {userLevel === "agent" ? <Image src={Img5Agent} /> : null}

      <p className="last-p">
        The table displays a breakdown of the profit and loss for each bet.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>Reports</HelpMenuLink><br />
      </p>
    </div>
  </div>
}