import "./index.scss"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import { useEffect, useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../../Providers/AuthProvider"
import { Table } from "react-bootstrap"
// import StringToRecolorNumber from '../../../components/StringToRecolorNumber'
import { useStorage } from "../../../Providers/StorageProvider"
import { numberWithCommas } from "../../../utils/dataTransform"
import CurrentTime from "../../../components/CurrentTime"
import ButtonCustom from "../../../components/ButtonCustom"
import moment from "moment"

export default ({ marketId, closeBreakdown, targetUser }) => {
  const { userName,setIsLoading } = useStorage()
  const { requestsToApi } = useAuth()
  const history = useHistory()
  const [responseData, setResponseData] = useState({})

  const response = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await requestsToApi.get(
        `/agency/${userName}/report/order-list?type=matched&page=1&dl=${targetUser.userId}&loginAsLevel=${targetUser.userLevel}&marketId=${marketId}`
      )
      setResponseData(response.data.result)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error?.message)
    }
  }, [history.location.search, requestsToApi, marketId, targetUser])

  useEffect(() => {
    response()
  }, [])

  return !!Object.keys(responseData)?.length ? (
    <div className="net-exposure">
      <div
        className="page-header"
        style={{ justifyContent: "space-between", paddingTop: "10px", marginBottom: "15px" }}
      >
        <h1>Net Exposure </h1>
        <ButtonCustom color="cyan" onClick={() => {}}>
          Download CSV
        </ButtonCustom>
      </div>
      <div className="breackdown-statement__main">
        <div className="nav">
          {/* {!!Object.keys(responseData)?.length? ( */}
          <div className="downline-nav">
            <a className="no-href" onClick={closeBreakdown}>
              {"Net Exposure"}
            </a>
            <span>
              <ChevronRightIcon size={17} />
            </span>
            <i>
              {responseData
                ? `${responseData.pageRows?.[0].eventName.replace(
                          "${HH:mm}",
                          moment.unix(responseData.pageRows?.[0].marketTime / 1000).utcOffset(330).format("HH:mm"))} - ${responseData.pageRows?.[0].marketName}`
                : "market"}
            </i>
          </div>
          {/* ) : (
            <div style={{ margin: "0 auto" }}>No bets for this market</div>
          )} */}
        </div>

        <div className="content">
          {responseData?.pageRows && (
            <Table striped hover variant="dark" className="breackdown-statement-table">
              <thead>
                <tr>
                  {["Member", "Placed", "Selection", "Bet ID", "In Play", "1- Click", "Type"].map(title => (
                    <th key={Math.random()} scope="col" className="text-align-left">
                      {title}
                    </th>
                  ))}
                  {["Odds", "Stake", "Potential Profit", "Liability", "Agent PT"].map(title => (
                    <th key={Math.random()} scope="col">
                      {title}
                    </th>
                  ))}
                  {(targetUser.userLevel === "MA" || targetUser.userLevel === "SMA") && <th scope="col">MA PT</th>}
                  {targetUser.userLevel === "SMA" && <th scope="col">CUS PT</th>}
                </tr>
              </thead>
              <tbody>
                {!!responseData?.pageRows?.length &&
                  responseData.pageRows.map(item => (
                    <tr key={item.firstMatchedTime}>
                      <td className="text-align-left">
                        {item.loginName} ({item.userName})
                      </td>
                      <td className="text-align-left">
                        <span>{<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.betPlacedDate}</CurrentTime>}</span>
                      </td>
                      <td className="text-align-left">
                        {item.marketBettingType === "ODDS" ? item.selectionName : item.line}
                      </td>
                      <td className="text-align-left">{item.apolloBetId}</td>
                      <td className="text-align-left">{item.inPlay ? "Y" : "N"}</td>
                      <td className="text-align-left">{item.oneClick ? "Y" : "N"}</td>
                      <td className="text-align-left">
                        {item.marketBettingType === "ODDS"
                          ? !!item.side
                            ? "Lay"
                            : "Back"
                          : !!item.side
                          ? "No"
                          : "Yes"}
                      </td>
                      <td>{item.averagePrice.toFixed(3)}</td>
                      <td>{numberWithCommas(item.sizeMatched)}</td>
                      <td>{numberWithCommas(item.profitLoss)}</td>
                      <td>{item.liability ? parseFloat(item.liability).toFixed(2) : "-"}</td>
                      {/* <td>{numberWithCommas(item.liability)}</td> */}
                      <td>{item.agentWin}</td>
                      {(targetUser.userLevel === "MA" || targetUser.userLevel === "SMA") && <td>{item.maWin}</td>}
                      {targetUser.userLevel === "SMA" && <td>{item.smaWin}</td>}
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  ) : (
    <span style={{ display: "flex", justifyContent: "center" }}>No bets for this market</span>
  )
}
