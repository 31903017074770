import { useState,useEffect } from 'react';
import { Pagination } from 'react-bootstrap'
import TextInputCustom from '../TextInputCustom'
import './index.scss'

export default ({ activePage = 1, changeActivePage, totalPages }) => {
  const [inputValue, setInputValue] = useState(String(activePage));


  useEffect(() => {
    setInputValue(String(activePage))
  }, [activePage])

  const chooseDisplayedPageNums = () => {
    const displayedPageNums = [];
    if (totalPages <= 5) {
      let counter = totalPages
      while (counter > 0) {
        displayedPageNums.unshift(counter)
        counter--
      }
      return displayedPageNums
    } else if (activePage < 4) return [1, 2, 3, 4, 5]
    else {
      displayedPageNums.push(activePage - 2, activePage - 1, activePage)

      if (activePage < totalPages) {
        displayedPageNums.push(activePage + 1)
        if (activePage + 2 <= totalPages) {
          displayedPageNums.push(activePage + 2)
          return displayedPageNums
        }
        displayedPageNums.unshift(activePage - 3)
        return displayedPageNums
      }
      displayedPageNums.unshift(activePage - 4, activePage - 3)
    }
    return displayedPageNums
  }


  return <>
    {totalPages > 1 ? <Pagination>
      <Pagination.First disabled={activePage === 1} onClick={() => 
        (changeActivePage(1), setInputValue(1))} />
      <Pagination.Prev disabled={activePage === 1} onClick={() => 
        (changeActivePage(activePage - 1), setInputValue(activePage - 1))} />
      <div style={{ fontSize: "10px" }}>
        {chooseDisplayedPageNums().map(num => <Pagination.Item
          onClick={() => changeActivePage(num)}
          active={num === activePage}
          key={Math.random()}>{num}
        </Pagination.Item>)}
      </div>

      <Pagination.Next disabled={activePage === totalPages} onClick={() =>
        (changeActivePage(activePage + 1), setInputValue(activePage + 1))} />
      <Pagination.Last disabled={activePage === totalPages} onClick={() =>
        (changeActivePage(totalPages), setInputValue(totalPages))} />
      <label>
        <TextInputCustom
          color="white"
          onChange={(value) => {
            if (/^\d+$/.test(value) || value === '') setInputValue(value)
          }}
          onEnter={() => {
            changeActivePage(Number(inputValue) < totalPages ? Number(inputValue) : totalPages)
            setInputValue(Number(inputValue) < totalPages ? inputValue : totalPages.toString())
          }}
          type="number"
        >{inputValue +''}
        </TextInputCustom>
        <span style={{ margin: "0 7px" }}>/</span>
        <span>{totalPages}</span>
      </label>
    </Pagination> : <></>}
  </>
}