import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/dataTransform"
import { useAuth } from "../../../Providers/AuthProvider"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"

export default ({ userId, userLevel,getWallets }) => {
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState(null)
  const [displayedData, setDisplayedData] = useState(null)
  const { userName: myUserName } = useStorage()
  const tableTemplate = [
    ["win", "Win", true],
    ["comm", "Comm", true],
    ["pnl", "P&L", true],
    ["turnover", "Turnover", false]
  ]

  useEffect(async () => {
    try {
      const responce = await requestsToApi.get(`agency/${myUserName}/report/user-summary?dl=${userId}`)
      setResponseData(responce.data.result)
      setDisplayedData(responce.data.result.user);
      getWallets(responce.data.result.bonusWallets)
    } catch (error) {
      console.log(error?.message)
    }
  }, [userId])

  const radioToggle = e => {
    setDisplayedData(responseData[e.target.attributes["status"].value])
  }

  const formatData = (num, recolor) => {
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div className="activity-report">
      <div className="activity-report__controls">
        {userLevel === "Member" ? (
          <></>
        ) : (
          <>
            <RadioCustom status="user" onChange={radioToggle} checked>
              Agent
            </RadioCustom>
            <RadioCustom status="members" onChange={radioToggle}>
              All Members
            </RadioCustom>
          </>
        )}
      </div>
      <div className="activity-body">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th style={{ width: "6rem" }} scope="col"></th>
              <th scope="col">Today</th>
              <th scope="col">3 days</th>
              <th scope="col">7 days</th>
              <th scope="col">30 days</th>
              <th scope="col">Lifetime</th>
            </tr>
          </thead>
          <tbody>
            {displayedData ? (
              tableTemplate.map(([key, title, recolor]) => (
                <tr key={title}>
                  <th>{title}</th>
                  {["1", "3", "7", "30", "all"].map(fieldName => (
                    <td key={Math.random()}>{formatData(displayedData[key][fieldName], recolor)}</td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
