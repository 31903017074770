import React, { useEffect, useState } from "react"
import "./RoundResults.scss"
import axios from "axios"
import TeenpattiTestPL from "./TeenpattiTestPL/TeenpattiTestPL"
import CardsTeenpattiPL from "./CardsTeenpattiPL/CardsTeenpattiPL"
import TheTrapPL from "./TheTrapPL/TheTrapPL"
import TrioPL from "./TrioPL/TrioPL"
import MulfisTeenpattiPL from "./MulfisTeenpattiPL/MulfisTeenpattiPL"
import Race2020PL from "./Race2020PL/Race2020PL"
import CasinoWarPL from "./CasinoWarPL/CasinoWarPL"
import HighLowPL from "./HighLowPL/HighLowPL"
import SevenUpDownPL from "./SevenUpDownPL/SevenUpDownPL"
import AmarAkbarAnthonyPL from "./AmarAkbarAnthonyPL/AmarAkbarAnthonyPL"
import BollywoodCasinoPL from "./BollywoodCasinoPL/BollywoodCasinoPL"
import LiveTeenpattiPL from "./LiveTeenpattiPL/LiveTeenpattiPL"
import DragonTigerPL from "./DragonTigerPL/DragonTigerPL"
import MatkaPL from "./MatkaPL/MatkaPL"
import CardsCasinoPL from "./CardsCasinoPL/CardsCasinoPL"
import SicboPL from "./SicboPL/SicboPL"
import RoulettePL from "./RoulettePL/RoulettePL"
import Poker20PL from "./Poker20PL/Poker20PL"
import QueenPL from "./QueenPL/QueenPL"
import AndarBaharPL from "./AndarBaharPL/AndarBaharPL"
import PokerPL from "./PokerPL/PokerPL"
import PokerSixPlayerPL from "./PokerSixPlayerPL/PokerSixPlayerPL"
import BaccaratPL from "./BaccaratPL/BaccaratPL"
import CasinoMeterPL from "./CasinoMeterPL/CasinoMeterPL"
import { useAuth } from "../../Providers/AuthProvider"

const RoundResults = ({roundId}) => {
  const [roundMarkets, setRoundMarkets] = useState();
  const { requestsToApi } = useAuth();

  console.log("roundId", roundId)

  useEffect(async () => {
      try {
        // todo need API
        // const response = await requestsToApi.get(`/exchange/odds/roundmarkets/${roundId}`)

        const response = await axios.get(`https://fawk.app/api/exchange/odds/roundmarkets/${roundId}`, {
          headers: { 'X-access-token': sessionStorage.getItem("accessToken") },
        })
        if (response.status === 200) {
          setRoundMarkets(response.data.result)
        }
      } catch (error) {
        console.log(error?.message)
      }
  },  [roundId]);

    return (
      <div className="round-results">
        <div className="round-results_header">
          <div className= "round-results_header-info">
            <span className="round-results_header-info-text">Round Results {roundId}</span>
          </div>
        </div>
        <div className="round-results_body">
          {roundMarkets && (roundMarkets[0].gameId === '98790' || roundMarkets[0].gameId === '98794' ) && <DragonTigerPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67660' && <CardsTeenpattiPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67680' && <TheTrapPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67610' && <TrioPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67600' && <MulfisTeenpattiPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  roundMarkets[0].gameId === '67575' && <CasinoMeterPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  roundMarkets[0].gameId === '67630' && <TeenpattiTestPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  roundMarkets[0].gameId === '90100' && <Race2020PL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '56968' || roundMarkets[0].gameId === '56969' ) && <HighLowPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '98789' || roundMarkets[0].gameId === '98793' ) && <SevenUpDownPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '98791' || roundMarkets[0].gameId === '98795' ) && <AmarAkbarAnthonyPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '67570' || roundMarkets[0].gameId === '67571' ) && <BollywoodCasinoPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '56767' || roundMarkets[0].gameId === '56768' || roundMarkets[0].gameId === '56769' || roundMarkets[0].gameId === '56766') && <LiveTeenpattiPL roundMarkets={roundMarkets}/>}
          {roundMarkets &&  (roundMarkets[0].gameId === '92037' || roundMarkets[0].gameId === '92036' ) && <MatkaPL roundMarkets={roundMarkets}/>}
          {roundMarkets && (roundMarkets[0].gameId === '56967' || roundMarkets[0].gameId === '56966' ) && <CardsCasinoPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67580' && <CasinoWarPL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '98566' && <SicboPL roundMarkets={roundMarkets}/>}
          {roundMarkets && (roundMarkets[0].gameId === '98788' || roundMarkets[0].gameId === '98792')  && <RoulettePL  roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67567' && <Poker20PL roundMarkets={roundMarkets}/>}
          {roundMarkets && roundMarkets[0].gameId === '67620' && <QueenPL roundMarkets={roundMarkets} />}
          {roundMarkets && (roundMarkets[0].gameId === '87564' || roundMarkets[0].gameId === '87565')  && <AndarBaharPL roundMarkets={roundMarkets}/>}
          {roundMarkets && (roundMarkets[0].gameId === '67564' || roundMarkets[0].gameId === '67563') && <PokerPL roundMarkets={roundMarkets}/>}
          {roundMarkets && (roundMarkets[0].gameId === '67565' || roundMarkets[0].gameId === '67566') && <PokerSixPlayerPL roundMarkets={roundMarkets}/>}
          {roundMarkets && (roundMarkets[0].gameId === '92038') && <BaccaratPL  roundMarkets={roundMarkets} />}
        </div>
      </div>
    )
}
export default RoundResults
