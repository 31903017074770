import { useState } from "react"
import "./index.scss"
import ModalRules from "../../components/ModalRules"

const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const changeVisibility = () => {
    setIsModalVisible(!isModalVisible)
  }

  return (
    <div className="footer-container">
      <div className="footer">
        <p onClick={changeVisibility} className="hover-opacity">
          Rules & Regulations
        </p>
        <p>KYC</p>
        <p>© 2016-2021</p>
        <ModalRules visible={isModalVisible} closeFunc={changeVisibility} />
      </div>
    </div>
  )
}
export default Footer
