import { useState } from "react"
import { useHistory } from "react-router-dom"
import BetBreakdown from "../../../components/BetBreakdown"
import { useStorage } from "../../../Providers/StorageProvider"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"

import "./index.scss"

export default () => {
  const { userLevel } = useStorage()
  const [downlineName, setDownlineName] = useState("market")
  const history = useHistory()

  const goBack = () => {
    const splitedURL = history.location.pathname.split("/")
    history.push(splitedURL.slice(0, splitedURL.length - 2).join("/"))
  }

  return (
    <div className="pnl-by-market__view-bets">
      <div className="downline-nav">
        <a className="no-href" onClick={goBack}>
          {history.location.pathname.includes("/account/statement")
            ? "Account Statement"
            : history.location.pathname.includes("/reports/pnl-by-market/") ||
              history.location.pathname.includes("/reports/casino-report/")
            ? "Markets"
            : "P&L Statement"}
        </a>

        <span>
          <ChevronRightIcon size={17} />
        </span>
        <i>{downlineName}</i>
      </div>
      <BetBreakdown userLevel={userLevel} setDownlineName={setDownlineName} />
    </div>
  )
}
