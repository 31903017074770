import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/dataTransform"
import { useAuth } from "../../../Providers/AuthProvider"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"
import moment from "moment"

export default ({ userId, userLevel,getWallets }) => {
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState(null)
  const [displayedData, setDisplayedData] = useState(null)
  const { userName: myUserName } = useStorage()
  const tableTemplate = [
    ["memberCode", "memberCode", false],
    ["loginName", "loginName", false],
    ["currency", "currency", false],
    ["exposure", "exposure", true],
    ["eventTypeId", "eventTypeId", false],
    ["eventTypeName", "eventTypeName", false],
    ["competitionName", "competitionName", false],
    ["eventId", "eventId", false],
    ["eventName", "eventName", false],
    ["marketId", "marketId", false],
    ["marketName", "marketName", false],
    ["marketTime", "marketTime", false],
    ["marketStatus", "marketStatus", false],
  ]

  useEffect(async () => {
    try {
      const responce = await requestsToApi.get(`agency/${myUserName}/report/exposure-info?memberCode=${userId}`)
      setDisplayedData(responce.data.result);
    } catch (error) {
      console.log(error?.message)
    }
  }, [userId])

//   const radioToggle = e => {
//     setDisplayedData(responseData[e.target.attributes["status"].value])
//   }

  const formatData = (num, recolor,title) => {
    if(title === "marketTime") return moment(num).format("DD.MM.YYYY HH:mm:ss")
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div className="activity-report">
      <div className="activity-body">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th scope="col" className="text-center">Member Code</th>
              <th scope="col" className="text-center">Login Name</th>
              <th scope="col" className="text-center">Currency</th>
              <th scope="col" className="text-center">Exposure</th>
              <th scope="col" className="text-center">Event Type Id</th>
              <th scope="col" className="text-center">Event Type Name</th>
              <th scope="col" className="text-center">Competition Name</th>
              <th scope="col" className="text-center">Event Id</th>
              <th scope="col" className="text-center">Event Name</th>
              <th scope="col" className="text-center">Market Id</th>
              <th scope="col" className="text-center">Market Name</th>
              <th scope="col" className="text-center">Market Time</th>
              <th scope="col" className="text-center">Market Staus</th>
            </tr>
          </thead>
          <tbody>
            {displayedData ? (
              displayedData.map(fieldName => (
                <tr key={Math.random()}>
                  {tableTemplate.map(([key, title, recolor])=> (
                    <td className="text-center" key={Math.random()}>{formatData(fieldName[title], recolor,title)}</td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
