import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '../../Providers/AuthProvider'
import Router from './Router'
import { MqttProvider } from '../../Providers/mqttConnection/mqttConnection'

const App = () => {
  return (
    <BrowserRouter >
      <MqttProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </MqttProvider>
    </BrowserRouter>
  );
}

export default App;
