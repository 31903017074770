import React, { useState } from 'react';
import './index.scss'


const InputCustom = React.forwardRef(({
  children,
  id,
  placeholder,
  color = 'black',
  label,
  checkFunc,
  onChange,
  type = 'new-password',
  className,
  onClick,
  autoFill,
  name = '',
  pattern,
  autocomplete = 'off',
  disabled,
  onBlur = () => { },
  onFocus = () => { },
  onEnter = () => { },
  error,
  classNameWarning = ''
}, ref) => {
  const inputId = id || Math.random();
  const [warning, setWarning] = useState(error === undefined ? false : error);
  const [success, setSuccess] = useState(false);
  // const [timedType, setTimedType] = useState();
  const checkInputValue = () => {
    onBlur()
    // setTimedType(type)
    if (checkFunc) {
      if (!checkFunc()) setWarning(true);
      else setSuccess(true)
    }

  }

  const changeValue = (value) => {
    setWarning(false);
    setSuccess(false)
    onChange(value);
  }
  return (
    <div className={`custom-text-input_${color} ` + className}>
      <input
        id={inputId}
        pattern={pattern}
        type={type}
        name={name}
        placeholder={placeholder}
        className={`${error || warning ? 'warning' : success ? 'success' : ''} ${classNameWarning}`}
        onChange={(e) => changeValue(e.target.value)}
        onBlur={checkInputValue}
        onFocus={onFocus}
        value={children && typeof (children) === 'string' ? children : ''}
        onClick={onClick}
        disabled={disabled}
        autoComplete={autocomplete}
        autoFill={false}
        onKeyPress={(event) => {
          if (event.key === 'Enter') onEnter()
        }}
        ref={ref}
      />
      {label ? <label htmlFor={inputId} for="frmCCCVC">{label}</label> : <></>}
    </div>

  )

})

export default InputCustom