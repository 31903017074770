import { useState } from 'react';
import './index.scss'

export default ({ onChange = () => { }, className, defaultValue, type = "red" }) => {
  const [switchState, setSwitchState] = useState(true)
  const inputId = Math.random();


  const toggleSwitch = () => {
    setSwitchState(!switchState)
    onChange()
  }
  return (
    <form className={className} action="#">
      <div className="switch">
        <input
          id={inputId}
          type="checkbox"
          onChange={toggleSwitch}
          className="switch-input"
          checked={defaultValue === undefined ? switchState : defaultValue}
        />
        <label htmlFor={inputId} className={`switch__label label_${type}`}>Switch</label>
      </div>
    </form>
  )
}