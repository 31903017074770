import React, { useEffect } from 'react'
import { useHistory, Route } from 'react-router-dom'
import { useAuth } from '../Providers/AuthProvider'

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { isAuthenticated } = useAuth()
  const history = useHistory()

  useEffect(() => {
    !isAuthenticated && history.push('/login')
  }, [isAuthenticated])

  return <Route {...rest} render={routeProps => (<RouteComponent {...routeProps} />)} />
}