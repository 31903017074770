import React, { createContext, useContext, useEffect, useState } from 'react';
import mqtt from 'precompiled-mqtt';
import { mqttHostUrl } from '../AuthProvider';

const MqttContext = createContext();

const MqttProvider = ({ children, initialTopics }) => {
    const [messages, setMessages] = useState({});
    const [subscribedTopics, setSubscribedTopics] = useState(initialTopics || []);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    var client;

    useEffect(() => {
        checkedLogin();
    }, []);

    const mqttCoonectionFunction = () => {
        const dataToConnectMqtt = {
            username: sessionStorage.getItem('myLoginName'),
            password: sessionStorage.getItem('accessToken'),
        };

        const hostUrl = mqttHostUrl;

        client = mqtt.connect(hostUrl, dataToConnectMqtt);

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            if (initialTopics?.length === 0) {
                initialTopics.forEach(topic => {
                    client.subscribe(topic, (err) => {
                        if (err) {
                            console.error(`Failed to subscribe to topic ${topic}:`, err);
                        } else {
                            console.log(`Subscribed to topic ${topic}`);
                        }
                    });
                });
            }
        });

        client.on('message', (topic, message) => {
            if (message) {
                const messageData = JSON.parse(message.toString());
                if (!messageData || messageData === null || messageData === undefined || messageData === '' || messageData?.length === 0) {
                    setMessages(prevState => {
                        const newState = { ...prevState };
                        delete newState[messageData?.apolloBetId];
                        return newState;
                    });
                } else {
                    setMessages(prevState => {
                        const lengthOfPrevState = Object.keys(prevState).length;
                        if (lengthOfPrevState > 2000) {
                            const newState = { ...prevState };
                            delete newState[Object.keys(prevState)[lengthOfPrevState - 1]];
                            return {
                                [messageData?.apolloBetId]: messageData,
                                ...newState
                            };
                        } else {
                            return {
                                [messageData?.apolloBetId]: messageData,
                                ...prevState
                            };
                        }
                    });
                }
            }
        });

        client.on('error', (error) => {
            console.error("MQTT error:", error);
        });
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (sessionStorage.getItem('accessToken')) {
                mqttCoonectionFunction();
            } else {
                setTimeout(() => {
                    mqttCoonectionFunction();
                }, 1000);
            }
        }

    }, [initialTopics, isAuthenticated]);

    const subscribeToTopics = (topicsToAdd) => {
        if (isAuthenticated && client) {
            topicsToAdd.forEach(topic => {
                if (!subscribedTopics.includes(topic)) {
                    client.subscribe(topic, (err) => {
                        if (err) {
                            console.error(`Failed to subscribe to topic ${topic}:`, err);
                        } else {
                            console.log(`Subscribed to topic ${topic}`);
                            setSubscribedTopics(prevTopics => [...prevTopics, topic]);
                        }
                    });
                }
            });
        }
    };

    const unsubscribeFromTopics = (topicsToRemove) => {
        console.log('topicsToRemove', topicsToRemove);
        if (client) {
            topicsToRemove.forEach(topic => {
                if (subscribedTopics.includes(topic)) {
                    client.unsubscribe(topic, (err) => {
                        if (err) {
                            console.error(`Failed to unsubscribe from topic ${topic}:`, err);
                        } else {
                            console.log(`Unsubscribed from topic ${topic}`);
                            setMessages([]);
                        }
                    });
                }
            });
        }
    };

    const checkedLogin = () => {
        if (sessionStorage.getItem('accessToken')) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }

    const setIsAuthenticatedAfterLogin = (hasLoggedIn) => {
        setIsAuthenticated(hasLoggedIn);
    }

    const contextValue = {
        messages,
        subscribeToTopics,
        unsubscribeFromTopics,
        setIsAuthenticatedAfterLogin,
        isAuthenticated,
    };

    return (
        <MqttContext.Provider value={contextValue}>
            {children}
        </MqttContext.Provider>
    );
};

const useMqtt = () => useContext(MqttContext);

export { MqttProvider, useMqtt };