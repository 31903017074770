import './index.scss'

export default ({
  children,
  name = 'formHorizontalRadios',
  type = 'blue', // blue, green
  onChange,
  onClick,
  checked,
  status
}) => {
  const inputId = `${name} ${children}`;
  return (
    <div className={`radio-item ${type}`}>
      <input
        className={`custom-radio ${type}`}
        type='radio'
        id={inputId}
        name={name}
        onChange={onChange}
        onClick={onClick}
        defaultChecked={checked}
        status={status}
      />
      <label htmlFor={inputId}>{children}</label>
    </div>
  )
}