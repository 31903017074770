import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1 from '../../../../static/images/help/position_taking_switch.png'
import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()

  return <div>
    <h1>Position Taking</h1>
    <div className="body">

      <p className="shortdesc">This section describes what Position Taking is and how it works.</p>

      <p>
        Position Taking defines how the stake of a bet is divided between the agency users and can be best described through the following example.
            </p>

      <h2>Example</h2>

      <p>
        A Member places a 1000-credit bet at 3.00 odds, backing team A. This means that if the Member loses, they lose 1000;
        if they win, they win 2000. Agency users take positions against the Member which reflects their liability for the bet.
            </p>
      <p>
        Scenario A: Member loses<br />
                If the Member loses, agency users keep the Member's loss based on their Position Taking:
            </p>

      <table>
        <thead><tr>
          {["User", "Position Taking (%)", "Amount kept by Agency User", "Amount passed on to upline"]
            .map(title => <th>{title}</th>)}
        </tr></thead>
        <tbody>
          <tr><td>Member</td><td>--</td><td>--</td><td>1000</td></tr>
          <tr><td>Agent</td><td>10</td><td>100</td><td>900</td></tr>
          {userLevel === "sma" ? <>
            <tr><td>MA</td><td>15</td><td>150</td><td>750</td></tr>
            <tr><td>CUS</td><td>20</td><td>200</td><td>550</td></tr>
            <tr><td>Company</td><td>25</td><td>250</td><td>300 -- to Betfair</td></tr>
          </> : userLevel === "ma" ? <>
            <tr><td>MA</td><td>15</td><td>150</td><td>750</td></tr>
            <tr><td>Upline users</td><td>75</td><td>750</td><td>--</td></tr>
          </> : <tr><td>Upline users</td><td>90</td><td>900</td><td>--</td></tr>}
        </tbody>
      </table>
      <p>
        The Agent keeps 10% (100) and passes up 900 to its upline.<br />
        {userLevel === "agent" ? "The upline agency users keep 90% (900)." :
          "The MA keeps 15% (150) and passes up 750 to its upline."}<br />
        {userLevel === "ma" ? "The upline agency users keep 75% (750)." :
          userLevel === "sma" ? "CUS keeps 20% (200) and passes up 550 to the Company." : ""}
                Scenario B: Member wins
            </p>

      <p>If the Member wins, they get 2000 which is added up as follows:</p>

      <table>
        <thead><tr>
          {["User", "Position Taking (%)", "Amount received from the upline", "User's own contribution"]
            .map(title => <th>{title}</th>)}
        </tr></thead>

        <tbody>
          {userLevel === "sma" ? <>
            <tr><td>Company</td><td>25</td><td>600 -- from Betfair</td><td>500 </td></tr>
            <tr><td>MA</td><td>15</td><td>1500</td><td>300</td></tr>
          </> : userLevel === "ma" ? <>
            <tr><td>CUS</td><td>20</td><td>1100</td><td>400</td></tr>
            <tr><td>MA</td><td>15</td><td>1500</td><td>300</td></tr>
          </> : <tr><td>Upline users</td><td>90</td><td>--</td><td>1800</td></tr>}
          <tr><td>Agent</td><td>10</td><td>1800</td><td>200</td></tr>
          <tr><td>Member</td><td>--</td><td>2000</td><td>--</td></tr>
        </tbody>
      </table>

      <p className="last-p">
        {userLevel === "sma" ? <>Betfair pays 600 to the Company.
                    The Company (PT: 25%) adds 500 as its own contribution, and passes down 1100 to the Customer.<br />
                    The Customer (PT: 20%) passes down 1500 to the MA (1100 from the Company + 400 as their own contribution).<br /></> :
          <>{userLevel === "ma" ? "The upline (PT: 75%) pays 1500 that is passed down to the MA." :
            "The upline (PT: 90%) pays 1800 that is passed down to the Agent."}<br />
                        The MA (PT: 15%) passes down 1800 to the Agent (1500 from the upline + 300 as their own contribution).</>}<br />
                The Agent (PT: 10%) passes down 2000 to its Member (1800 from the upline + 200 as their own contribution).
            </p>

      <h2>Turning Position Taking on and off</h2>

      <p>
        Turning Position Taking off means you will not take part in any subsequent bets placed by the Members.
        Bets will be placed without any involvement and any risks from your part.
            </p>
      <p>
        <strong>Note:</strong> You are liable to take position on any unmatched bets that your members have placed&nbsp;
                before switching off Position Taking if these bets are eventually matched by Betfair.
            </p>
      <p>
        To turn Position Taking on or off, click the switch in the top right corner.
            </p>

      <br />
      <Image src={Img1} />

      <p className="last-p">
        <strong>Note:</strong> By default, Position Taking is turned on. If you turn it off, it will be turned off even when you log out and log back in.<br />
        <HelpMenuLink strong>Position Taking Management</HelpMenuLink><br />
                This section describes how {userLevel === "agent" ? "to manage Position Taking settingsfor one or more Members." :
          `you manage and view Position Taking for one or multiple ${childrenLevel}s.`
        }<br />
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink>
      </p>
    </div>
  </div>
}