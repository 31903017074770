import axios from "axios";
import moment from "moment";

export const downloadCsv = async (data) => {
  try {
    let response = {};

    if (data) {
      response = data;
    } else {
      response = await axios.get(
        `https://admin.lotusbook247.games/api/agency/MOGAMBOUSD/agency-mgmt/downline/csv?parent=MOGAMBOUSD&searchUser=&token=${sessionStorage.getItem(
          "accessToken"
        )}`
      );
    }

    const csvContent = ConvertToCSV(response.data);

    const blob = new Blob([csvContent], { type: "text/csv" });

    let tempLink = document.createElement("a");
    tempLink.style.visibility = "hidden";
    tempLink.setAttribute("href", window.URL.createObjectURL(blob));
    tempLink.setAttribute("download", "memberListing.csv");
    tempLink.click();
  } catch (e) {
    console.log(e);
  }
}

// function ConvertToCSV(dataArray) {
//   const csvRows = [];

//   for (const item of dataArray) {
//     const formattedItem = { ...item };

//     formattedItem.Created = new Date(item.Created).toLocaleString();
//     formattedItem['Last Login'] = new Date(item['Last Login']).toLocaleString();

//     const csvRow = Object.values(formattedItem).map(value => `"${value}"`);
//     csvRows.push(csvRow);
//   }

//   const headerRow = Object.keys(dataArray[0]);
//   csvRows.unshift(headerRow);

//   return csvRows.map(row => row.join(",")).join("\n");
// }
function ConvertToCSV(dataArray) {
  const csvRows = [];

  for (const item of dataArray) {
    const formattedItem = { ...item };

    formattedItem.Created = moment(item.Created).format("DD.MM.YY HH:mm:ss");
    formattedItem['Last Login'] = moment(item['Last Login']).format("DD.MM.YY HH:mm:ss");

    const csvRow = Object.values(formattedItem).map(value => `"${value}"`);
    csvRows.push(csvRow);
  }

  const headerRow = Object.keys(dataArray[0]);
  csvRows.unshift(headerRow);

  return csvRows.map(row => row.join(",")).join("\n");
}
