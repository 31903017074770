import { Dropdown } from "react-bootstrap"
import "./index.scss"

export default ({ title = "Option:", options, activeOption, onChange }) => {
  return (
    <Dropdown className="custom-dropdown">
      <Dropdown.Toggle data-title={`${title} ${activeOption}`}>
        {title} {activeOption}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options && options.length ? (
          options.map(item => {
            return (
              <Dropdown.Item
                key={Math.random()}
                onClick={() => {
                  onChange(item)
                }}
                active={item === activeOption}
              >
                <span>{item}</span>
              </Dropdown.Item>
            )
          })
        ) : (
          <Dropdown.Item active>
            <span>No ontions</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
