import { useState, useEffect, useCallback, Fragment } from "react"
import PlayCircleIcon from "mdi-react/PlayCircleIcon"
import RadioCustom from "../../../components/RadioCustom"
import DownlineIcon from "../../../static/images/help/dropDown.svg"
import Icon from "../../../static/images/help/icon_downline.svg"
import BetBreakdown from "../../../static/images/help/icon_bet_breakdown.png"
import Reload from "../../../static/images/help/reload.svg"
import { useAuth } from "../../../Providers/AuthProvider"
import { useStorage } from "../../../Providers/StorageProvider"
import { positiveOrNegative, filterElements, sortElments } from "../../../utils/positiveOrNegative"
import Vector from "../../../static/images/help/Vector.svg"
import ButtonCustom from "../../../components/ButtonCustom"
import Ladder from "../WorkStation/ladder"
import "./index.scss"
import moment from "moment"

export default ({ closeDownline, marketId, setShowTable, open }) => {
  const { requestsToApi } = useAuth()
  const { userName } = useStorage()
  const [groupRadio, setGroupRadio] = useState("My PT")
  const [nesting, setNesting] = useState([])
  const [response, setResponse] = useState(null)
  const [parentUserCode, setParentUserCode] = useState("")
  const [time, setTime] = useState(8)
  const [total, setTotal] = useState("profitAndLoss")
  const [showLadder, setShowLadder] = useState({})
  const [ladderMarket, setLadderMarket] = useState(open)

  const getNetExposureDataReq = useCallback(async () => {
    try {
      let data = await requestsToApi.get(
        `agency/${userName}/risk-mgmt/downline-net-exposure?marketId=${marketId}${parentUserCode ? `&parentUserCode=${parentUserCode}` : ""
        }`
      )
      data.data.result.downline = sortElments(data.data.result.downline)
      setResponse(data.data.result)
      setTime(8)
      setNesting(data.data.result.downline.map(el => el.marketName))
    } catch (e) {
      setTime(8)
      console.log(e?.message)
    }
  }, [marketId, requestsToApi, userName, parentUserCode])

  const addCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr)  < 100) {
      let res = 0
      x.length > 1 ?
        res = x1 + x2.substring(0,3) :
        res = x1 + '.00'
      return res
    }
    return x1
  }

  useEffect(() => {
    let timer = null
    if (!time) {
      getNetExposureDataReq()
    } else {
      timer = setTimeout(() => {
        setTime(prev => prev - 1)
      }, 1500)
    }
    return () => clearTimeout(timer)
  }, [time, getNetExposureDataReq])

  useEffect(() => {
    getNetExposureDataReq()
  }, [getNetExposureDataReq])

  const test = (data) => {
    console.log('data',data)
    let test_data_name_index = 0
    data.forEach((el, index) => el.userName === parentUserCode ? test_data_name_index = index : '')
    console.log(test_data_name_index,data[test_data_name_index])
    return data.map((el, index) =>
      index >= 1 && index <= test_data_name_index? <span key={index} onClick={() => setParentUserCode(data[index - 1].userName)}>{el.loginName} </span> : '')
  }

  return (
    <div>
      <div className="refresh">
        <h1>Net Exposure </h1>
        <div>
          <span>{time}</span>
          <ButtonCustom
            color="cyan"
            className="refresh__button"
            onClick={() => {
              getNetExposureDataReq()
            }}
          >
            <img src={Reload} style={{ marginRight: "5px" }} alt="" />
            Refresh
          </ButtonCustom>
        </div>
      </div>
      <div className="table">
        <table>
          <thead>
            <tr>
              {[
                ["My PT", "Total Book"].map(title => (
                  <RadioCustom
                    key={title}
                    checked={groupRadio === title}
                    name="group"
                    onChange={() => {
                      setGroupRadio(title)
                      if (title === "Total Book") {
                        setTotal("totalProfitAndLoss")
                      } else {
                        setTotal("profitAndLoss")
                      }
                    }}
                  >
                    {title}
                  </RadioCustom>
                )),
                "",
                "Stake",
                "1",
                "X",
                "2"
              ].map((title, index) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            <td className="title" colSpan="6">
              {response?.event.eventName.replace(
                "${HH:mm}",
                moment.unix(response?.event.marketTime / 1000).utcOffset(330).format("HH:mm"))}
              <span>
                <PlayCircleIcon size={20} /> In-Play
              </span>
            </td>
            <tr>
              <td className="marketName">{response?.market.marketName}</td>
              <td className="tabe-icon">
                {response?.market.ladder[groupRadio === "My PT" ? "myPt" : "totalBook"] && (
                  <img
                    src={Vector}
                    alt=""
                    style={{ background: "#485096" }}
                    onClick={() => setLadderMarket(prev => !prev)}
                  />
                )}
                <img
                  style={{ background: "#373D73" }}
                  src={BetBreakdown}
                  className="transform-rotate"
                  alt=""
                  onClick={() => setShowTable(response?.market)}
                />
                <img style={{ background: "#2F3462" }} src={DownlineIcon} alt="" onClick={() => closeDownline()} />
              </td>
              <td>{addCommas(parseFloat(response?.market?.[total === "profitAndLoss" ? "stakes" : "totalStakes"]).toFixed(2))}</td>
              {filterElements(response?.market.selections).map(el => (
                <td className={positiveOrNegative(el?.[total])}>
                  {el?.[total] ? addCommas(parseFloat(el?.[total]).toFixed(2)) : "-"}
                </td>
              ))}
            </tr>
            <tr>
              {response?.market.ladder[groupRadio === "My PT" ? "myPt" : "totalBook"] && ladderMarket && (
                <Ladder response={response.market.ladder[groupRadio === "My PT" ? "myPt" : "totalBook"]} />
              )}
            </tr>
            <td className="nesting" colSpan="6">
              <span
                onClick={() => {
                  closeDownline()
                }}
              >
                Net Exposure
              </span>
              <span>{`${response?.event.eventName.replace(
                "${HH:mm}",
                moment.unix(response?.event.marketTime / 1000).utcOffset(330).format("HH:mm"))} - ${response?.downline[0].marketName}`}</span>
              {response?.downline.length > 2 ? (
                // response?.downline.map((el, index) =>
                //   index > 1 && response.downline.filter((elem => elem.userName === el.userName)) ? <span key={index} onClick={() => setParentUserCode(response.downline[index - 1].userName)}>{el.loginName} </span> : '')
                  test(response?.downline)
              ) : <Fragment />}
            </td>
            {response?.downline.map((el, index) => (
              <Fragment key={index}>
                <tr
                  style={{ borderLeft: index * 20 + "px solid #180519" }}
                  className={
                    response?.downline[index]?.userName.length === response?.downline[index + 1]?.userName.length ||
                      response?.downline[index - 1]?.userName.length === response?.downline[index]?.userName.length
                      ? "join list"
                      : "list"
                  }
                >
                  <td>{el.loginName}</td>
                  <td className="tabe-icon">
                    {el.ladder?.myPt && (
                      <img
                        src={Vector}
                        alt=""
                        onClick={() => setShowLadder(prev => ({ ...prev, [index]: prev[index] ? !prev[index] : true }))}
                      />
                    )}
                    <img src={BetBreakdown} alt="" className="transform-rotate" onClick={() => setShowTable(el)} />
                    {response?.downline.length - 1 === index ||
                      response?.downline[index]?.userName.length === response?.downline[index + 1]?.userName.length
                      ? el.userName.length < 11 && (
                        <img src={Icon}
                          alt=""
                          onClick={() => setParentUserCode(el.userName)}
                        />
                      )
                      : el.userName.length < 11 && (
                        <img
                          src={DownlineIcon}
                          alt=""
                          onClick={() =>
                            setParentUserCode(index !== 0 ? response?.downline[index - 1]?.userName : "")
                          }
                        />
                      )}
                  </td>
                  <td>{addCommas(parseFloat(el?.[total === "profitAndLoss" ? "stakes" : "totalStakes"]).toFixed(2))}</td>
                  {filterElements(el?.selections).map(selection => (
                    <td className={positiveOrNegative(selection?.[total])}>
                      {selection?.[total] ? addCommas(parseFloat(selection?.[total]).toFixed(2)) : "-"}
                    </td>
                  ))}
                </tr>
                {showLadder?.[index] && (
                  <tr style={{ borderLeft: index * 20 + "px solid #180519" }} colSpan="2">
                    <Ladder response={el.ladder[groupRadio === "My PT" ? "myPt" : "totalBook"]} />
                  </tr>
                )}
                {!(
                  response?.downline[index]?.userName.length === response?.downline[index + 1]?.userName.length ||
                  response?.downline[index - 1]?.userName.length === response?.downline[index]?.userName.length
                ) && (
                    <tr>
                      <td colSpan="6" style={{ backgroundColor: "#180519" }} />
                    </tr>
                  )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
