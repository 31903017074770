import React from 'react'
import { ReactBurgerMenu } from 'react-burger-menu'
import './index.scss'

export default React.forwardRef(({
  children,
  color = 'purple',
  disabled,
  onClick,
  className = "",
  width,
  height = "32px",
  id
}, ref) => {
  return (
    <button
      style={{ width, height }}
      className={`custom-buttom ${color} ${className}`}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      id={id}
    >
      <span>
        {children}
      </span>
    </button>
  )
})