import { useEffect, useState } from "react"
import "./index.scss"

export default ({
  children,
  type = "red", //red, green
  timeout = 3000,
  disableFunc
}) => {
  const [animate, setAnimate] = useState("")
  useEffect(() => {
    if (timeout && disableFunc && children) {
      setTimeout(() => {
        setAnimate(null)
      }, timeout)
      setTimeout(() => {
        setAnimate("active")
      }, 100)
    }
    return () => clearTimeout()
  }, [children])

  useEffect(() => {
    if (!animate) {
      setTimeout(() => {
        disableFunc()
      }, 1000)
    }
    return () => clearTimeout()
  }, [animate])

  return children ? (
    <div className={`custom-alert alert_${type}`}>
      <div className={`custom-alert__container alert_${type} ${animate ? animate : ""}`}>
        <p>{children}</p>
      </div>
    </div>
  ) : (
    <></>
  )
}
