import React, { useEffect, useState } from "react"
import { Nav, Navbar, Table } from 'react-bootstrap'
import "./index.scss"
import { useStorage } from "../../../Providers/StorageProvider"
import { useAuth } from "../../../Providers/AuthProvider"
// import httpRequest from "../../../services/http-request"
import StringToRecolorNumber from '../../../components/StringToRecolorNumber'
// import LoadingSpinner from '../../common/loading-spinner'

export default ({ userData }) => {
  const [commonData, setCommonData] = useState(null)
  const [active, setActive] = useState(null)
  const {isLoading, setIsLoading } = useStorage()
  const { requestsToApi } = useAuth()
  useEffect(() => {
    if (userData?.name) {
      getData();
    }
  }, [userData])

  const getData = async () =>{
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(
        `/agency/user-ip-analysis?memberCode=${userData?.name}`
      )
      setCommonData(response.data.result)
      setActive( Object.keys(response.data.result)[1])
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  return (
    <div className="common-users">
      <div className="common-users__page">
        <h1>Common Users</h1>
      </div>
      {/* {isLoading && <LoadingSpinner />} */}
      {commonData &&
        <ul>
          {Object.keys(commonData).filter(i => i !== 'usr_id').map(item =>
          <li key={item}
              onClick={() => {setActive(item)}}
              className={active === item ? 'active' : ''}
          >
            {item.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}
          </li>
          )}
      </ul>}
      {commonData &&
        <p className='common-users__page-total'>
        {active?.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')}: {' '}
        <span>
           {commonData && commonData[active]?.member_codes?.filter(item => item.includes(`${userData?.name.slice(0,2)}`)).length || '-'}
        </span>
      </p>}
      <div className={`common-users__page-content ${isLoading ? "minimize" : ""}`}>
        { commonData && commonData[active] && commonData[active]?.login_names?.length ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th scope="col">Member code</th>
                <th scope="col">Login Names</th>
              </tr>
            </thead>
            <tbody>
              {commonData[active]?.login_names.map((row, index) => (
                    commonData[active]?.member_codes[index].includes(`${userData?.name.slice(0,2)}`) && <tr key={row + index}>
                      <td scope="col">
                        {commonData[active]?.member_codes[index]}
                      </td>
                      <td scope="col">
                        {row}
                      </td>
                    </tr>
              ))}
            </tbody>
          </Table>
        ) : !isLoading && <>There have been no {active ? active?.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ') : "Common Users"}</>
        }
      </div>
    </div>
  )
}
