import React, { useContext, useState, useEffect, memo } from 'react'
import { Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap'
import ArrowIcon from 'mdi-react/MenuDownIcon'
import { getSidebarData } from './SidebarData'
import './index.scss'
import ArrowSideBarToggleIcom from 'mdi-react/ChevronDoubleLeftIcon'
import { useHistory } from 'react-router-dom'
import { useStorage } from '../../Providers/StorageProvider'

function ButtonAccordionToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      // type="button"
      className={`toggle-button ${isCurrentEventKey ? "active" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
      <ArrowIcon />
    </div>
  );
}

const SideBar = () => {
  const [selectedPart, setSelectedPart] = useState([0, 0])
  const selectSubMenu = (menuIndex, subMenuIndex) => {
    setSelectedPart([menuIndex, subMenuIndex])
  }
  const [showSideBar, setShowSideBar] = useState(true)
  const history = useHistory();
  const { userLevel, childrenLevel } = useStorage();
  const sidebarData = getSidebarData(userLevel, childrenLevel)


  useEffect(() => {
    sidebarData.forEach((item, index) => item.subMenu.forEach((subItem, subIndex) => {
      if (history.location.pathname.includes(subItem.path)) setSelectedPart([index + 1, subIndex]);
    }));
  }, [history.location.pathname]);


  return (
    <div className={`sidebar-menu ${showSideBar ? "active" : ""} `}>
      <Accordion activeKey={selectedPart[0]}>
        {sidebarData.map((menu, index) => {
          const menuIndex = ++index
          return (
            <div key={Math.random()} className="accordion-content">
              <ButtonAccordionToggle callback={(key) => setSelectedPart([key, null])} eventKey={menuIndex}>{menu.title}</ButtonAccordionToggle>
              <Accordion.Collapse eventKey={menuIndex}>
                <div className="show-collapse">
                  {menu.subMenu.map((subMenu, subMenuIndex) => {
                    const [setMenuIndex, setSubMenuIndex] = selectedPart
                    return (
                      <a
                        key={Math.random()}
                        className={`${setMenuIndex === menuIndex && setSubMenuIndex === subMenuIndex ? "active" : ""}`}
                        onClick={() => {
                          if (history.location.pathname === subMenu.path) {
                            history.go(0)
                          } else {
                            history.push(subMenu.path)
                          }
                          selectSubMenu(menuIndex, subMenuIndex)
                        }}
                      >
                        {subMenu.title}
                      </a>
                    )
                  })}
                </div>
              </Accordion.Collapse>
            </div>
          )
        })}
      </Accordion>
      <div
        className={`toggle-show ${!showSideBar ? "active-all-time" : ""}`}
        onClick={() => setShowSideBar(!showSideBar)}
      >
        <ArrowSideBarToggleIcom />
      </div>
      <div className="toggle-show__background"></div>
    </div>

  );
}
export default memo(SideBar)
