import { useEffect, useState, useCallback, Fragment } from "react"
import RadioCustom from "../../../components/RadioCustom"
import DropdownCustom from "../../../components/DropdownCustom"
import { positiveOrNegative, filterElements, sortElments } from "../../../utils/positiveOrNegative"
import { useAuth } from "../../../Providers/AuthProvider"
import { useStorage } from "../../../Providers/StorageProvider"
import Icon from "../../../static/images/help/dropDown.svg"
import Icon2 from "../../../static/images/help/icon_downline.svg"
import Vector from "../../../static/images/help/Vector.svg"
import Ladder from "./ladder"
import Table3 from "./table3"
import "./index.scss"
import moment from "moment"

const WorkStation = ({ marketId, eventId, closeBreakdown }) => {
  const { requestsToApi } = useAuth()
  const { userName, setIsLoading } = useStorage()
  const [MarketId, setMarketId] = useState(marketId)
  const [sportRadio, setSportRadio] = useState("Bet Ticker")
  const [sportRadio2, setSportRadio2] = useState("My PT")
  const [value, setChangeValue] = useState("")
  const [dropdownSportOptions, setDropdownSportOptions] = useState([])
  const [response, setResponse] = useState(null)
  const [allResponse, setAllResponseData] = useState(null)
  const [responseData, setResponseData] = useState(null)
  const [total, setTotal] = useState("profitAndLoss")
  const [parentUserCode, setParentUserCode] = useState("")
  const [showLadder, setShowLadder] = useState({})
  const [ladderMarket, setLadderMarket] = useState(false)
  const [show, setShow] = useState(true)
  let triggerDownline = true
  let triggerTicker = true
  let ladderMin = 0
  let ladderMax = 0
  let maxExp = 0
  let maxWin = 0
  let maxTotal = 0

  const getDownlineDataReg = useCallback(async () => {
    try {
      setIsLoading(true)
      if (triggerDownline) {
        triggerDownline = false
        let response = await requestsToApi.get(
          `agency/${userName}/risk-mgmt/downline-net-exposure?marketId=${MarketId}${parentUserCode ? `&parentUserCode=${parentUserCode}` : ""
          }`
        )
        response.data.result.downline = sortElments(response.data.result.downline)
        setResponse(response.data.result)
        setIsLoading(false)

      }
    } catch (e) {
      setIsLoading(false)

      console.log(e?.message)
    }
    finally {
      triggerDownline = true
    }
  }, [MarketId, userName, requestsToApi, value, parentUserCode])

  const getBetTicker = useCallback(async () => {
    try {
      if (triggerTicker) {
        triggerTicker = false
        let response = await requestsToApi.get(`agency/exchange/odds/sma-event/${marketId}/${eventId}`)
        setResponseData(response.data.result.find(el => el.id === MarketId))
        setAllResponseData(response.data.result)
        setDropdownSportOptions(response.data.result.map(el => ({ [el.id]: el.name })))
        if (!value) setChangeValue(response.data.result.find(el => el.id === MarketId).name)
      }
    } catch (e) {
      console.log(e)
    }
    finally {
      triggerTicker = true
    }
  }, [requestsToApi, value, MarketId])

  useEffect(() => {
    getBetTicker()
    getDownlineDataReg()
    let timer = setInterval(() => {
      if (triggerDownline) {
        getBetTicker()
        getDownlineDataReg()
      }
    }, 2200)
    return () => clearInterval(timer)
  }, [getDownlineDataReg, getBetTicker])

  const addCommas = (nStr, trigger = true) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (trigger)
      if (Math.abs(+nStr) < 100) {
        let res = 0
        x.length > 1 ?
          res = x1 + x2.substring(0, 3) :
          res = x1 + '.00'
        return parseFloat(res).toFixed(2)
      }
    return x1
  }

  if (response && !!response?.downline[0].ladder && response?.market.marketType === 'INNINGS_RUNS') {
    response?.downline.forEach(elem => maxWin += Math.max(...elem.ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(el => el.exposure)))
    
    response?.downline.forEach(elem => {
      console.log('elem', elem)
      maxExp = maxExp + Math.min(...elem.ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(el => el.exposure))
      console.log('maxExp', maxExp)
      console.log('sdfdsfff', Math.min(...elem.ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(el => el.exposure)))
    })
    response?.downline.forEach(elem => maxTotal += elem[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"])
  }


  return (
    <div className="net-exposure">
      <div
        className="page-header"
        style={{ justifyContent: "space-between", paddingTop: "10px", marginBottom: "15px" }}
      >
        <h3>{response?.event.eventName.replace(
          "${HH:mm}",
          moment.unix(response?.event.marketTime / 1000).utcOffset(330).format("HH:mm"))}</h3>
        <div className="workstation">
          <div className="sport-radios">
            {["Bet Ticker", "Bet List"].map(title => (
              <RadioCustom
                checked={sportRadio === title}
                name="sport"
                type="green"
                key={title}
                onChange={() => {
                  setSportRadio(title)
                }}
              >
                {title}
              </RadioCustom>
            ))}
            {["My PT", "Total Book"].map(title => (
              <RadioCustom
                checked={sportRadio2 === title}
                type="green"
                key={title}
                onChange={() => {
                  setSportRadio2(title)
                  if (title === "Total Book") {
                    setTotal("totalProfitAndLoss")
                  } else {
                    setTotal("profitAndLoss")
                  }
                }}
              >
                {title}
              </RadioCustom>
            ))}
            <DropdownCustom
              title={!value ? "Select a market" : ''}
              options={dropdownSportOptions.map(el => Object.values(el)).flat(1)}
              activeOption={value}
              onChange={value => {
                setChangeValue(value)
                setMarketId(Object.keys(dropdownSportOptions.find(el => Object.values(el).includes(value)))[0])
                setParentUserCode("")
                setResponseData(null)
                setAllResponseData(null)
                setResponse(null)
                setShowLadder({})
              }}
            />
            <button
              onClick={() => {
                getDownlineDataReg()
                getBetTicker()
                setResponseData(null)
                setAllResponseData(null)
                setResponse(null)
              }}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className="workstation-container">
        <div className="back-net-exposure" onClick={closeBreakdown}>
          Net Exposure
        </div>
        {responseData ?
          <div className="table-workstation">
            <div>
              {allResponse && response?.market.marketType !== 'INNINGS_RUNS' ?
                <p>{allResponse[0].matched ? `Total matched: ${addCommas(allResponse[0].matched)}` : ''}</p> : ''}

              <table className={`table-1 ${response?.market.marketType}`}>
                <tbody>
                  {responseData && response ? (
                    response?.market.marketType === 'INNINGS_RUNS' ?
                      responseData?.runners.map((el, index) => {
                        let profitAndLoss_float = 0
                        let totalProfitAndLoss_float = 0
                        profitAndLoss_float = addCommas(parseFloat(response.market.selections[1]?.profitAndLoss || 0).toFixed(2)) || 0;
                        totalProfitAndLoss_float = addCommas(parseFloat(response.market.selections[0]?.totalProfitAndLoss || 0).toFixed(2)) || 0;
                        return <tr key={Math.random()}>
                          <td>
                            <div>{el.name}</div>
                            <div className={sportRadio2 === "My PT" ? positiveOrNegative(profitAndLoss_float) :
                              positiveOrNegative(totalProfitAndLoss_float)}>
                              {sportRadio2 === "My PT" ?
                                profitAndLoss_float
                                : totalProfitAndLoss_float}
                            </div>
                          </td>
                          {responseData.status !== "OPEN" ? (
                            <td className="Info">
                              {responseData.statusLabel}
                            </td>
                          ) : (
                            <>
                              <td key={Math.random()} className={`triplet ${responseData.isBettable ? 'isBettable' : ''}`}>
                                <div>{el.lay[index]?.line && addCommas(parseFloat(el.lay[index].line).toFixed(2))}</div>
                                <div className="price">{el.lay[index]?.price && addCommas(Math.ceil(el.lay[index].price), false)}</div>
                              </td>
                              <td key={Math.random()} className={`white ${responseData.isBettable ? 'isBettable' : ''}`}>
                                <div>{el.back[index]?.line && addCommas(parseFloat(el.back[index].line).toFixed(2) || 0)}</div>
                                <div className="price">{el.back[index]?.price && addCommas(Math.ceil(el.back[index].price), false)}</div>
                              </td>
                            </>
                          )}

                          {responseData.statusLabel === 'Ball Running' ?
                            <td className="InfoBall">
                              Ball Running
                            </td> : ''}
                        </tr>
                      })
                      :
                      responseData?.runners.map((el, index) => {
                        let profitAndLoss_float, totalProfitAndLoss_float = 0
                        let selection = response.market.selections.filter(selection => selection.selectionName === el.name)[0]

                        profitAndLoss_float = selection ? addCommas(selection.profitAndLoss) : '-'
                        totalProfitAndLoss_float = selection ? addCommas(selection.totalProfitAndLoss) : '-'
                        return <tr key={Math.random()}>
                          <td>
                            <div>{el.name}</div>
                            <div className={sportRadio2 === "My PT" ? positiveOrNegative(selection.profitAndLoss) :
                              positiveOrNegative(selection.totalProfitAndLoss)}>
                              {sportRadio2 === "My PT" ?
                                profitAndLoss_float
                                : totalProfitAndLoss_float}
                            </div>
                          </td>
                          {responseData.status !== "OPEN" ? (
                            <td className="Info">
                              {responseData.statusLabel === "INNINGS_RUNS" && responseData.isBettable
                                ? "Ball Running"
                                : responseData.status}
                            </td>
                          ) : (
                            <>
                              {Array.from(Array(3).keys())
                                .reverse()
                                .map(index => (
                                  <td key={Math.random()} className="white">
                                    <div>{el.back[index]?.price && addCommas(parseFloat(el.back[index].price).toFixed(2))}</div>
                                    <div className="price">{el.back[index]?.size && addCommas(Math.ceil(el.back[index].size))}</div>
                                  </td>
                                ))}
                              {Array.from(Array(3).keys()).map(index => (
                                <td key={Math.random()} className="triplet">
                                  <div>{el.lay[index]?.price && addCommas(parseFloat(el.lay[index].price).toFixed(2))}</div>
                                  <div className="price">{el.lay[index]?.size && addCommas(Math.ceil(el.lay[index].size))}</div>
                                </td>
                              ))}
                            </>
                          )}
                        </tr>
                      })
                  )
                    : (
                      <td style={{ border: 0 }}>Market is not available</td>
                    )}
                </tbody>
              </table>
            </div>
            <div>
              <table className="table-2">
                {response ? (
                  <>
                    <thead>
                      <tr>
                        {response?.market.marketType === 'TOURNAMENT_WINNER' ?
                          ["", "", "Stake", "Over/Yes", "Under/No",].map((title, index) => (
                            <td key={Math.random()}>{title}</td>
                          )) : response?.market.marketType === 'INNINGS_RUNS' ?
                            ["", "", "Stake", "Max win", "Max Exposure",].map((title, index) => (
                              <td key={Math.random()}>{title}</td>
                            )) :
                            ["", "", "Stake", "1", "X", "2"].map((title, index) => (
                              <td key={Math.random()}>{title}</td>
                            ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{response?.market.loginName}</td>
                        <td>
                          <td className={response.market.ladder.myPt ? "" : "none"}>
                            {response.market.ladder.myPt && (
                              <img src={Vector} alt="" onClick={() => setLadderMarket(prev => !prev)} />
                            )}
                          </td>
                          <td>
                            <img
                              src={show ? Icon : Icon2}
                              width="15px"
                              alt=""
                              onClick={() => {
                                setShow(prev => !prev)
                                setParentUserCode("")
                              }}
                            />
                          </td>
                        </td>
                        {response?.market.marketType === 'INNINGS_RUNS' || response?.market.marketType === 'TOURNAMENT_WINNER' ? '' :
                          <td>
                            {addCommas(parseFloat(response?.market[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"])
                            )}
                          </td>}
                        {response?.market.marketType === 'TOURNAMENT_WINNER' ?
                          <>
                            <td className="">
                              {addCommas(parseFloat(response?.market[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"]).toFixed(2))}
                            </td>
                            <td className={positiveOrNegative(response?.market.selections[0][total])}>
                              {response?.market.selections[0][total] || response?.market.selections[0][total] === 0 ?
                                addCommas(parseFloat(response?.market.selections[0][total]).toFixed(2)) : <span>-</span>}
                            </td>
                            <td className={positiveOrNegative(response?.market.selections[1][total])}>
                              {response?.market.selections[1][total] || response?.market.selections[1][total] === 0 ?
                                addCommas(parseFloat(response?.market.selections[1][total]).toFixed(2)) : <span>-</span>}
                            </td>
                          </> :
                          response?.market.marketType === 'INNINGS_RUNS' ?
                            <>
                              {
                                ladderMin = Math.min(response?.downline[0].ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(el => el.exposure)),
                                ladderMax = Math.max(response?.downline[0].ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(el => el.exposure))
                              }
                              <td className="">
                              {addCommas(parseFloat(response?.market[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"]).toFixed(2))}
                              </td>
                              <td className={
                                positiveOrNegative(maxWin)}>
                                {maxWin ?
                                  addCommas(maxWin) : <span>-</span>}
                              </td>
                              <td className={positiveOrNegative(maxExp)}>
                                {maxExp ?
                                  addCommas(maxExp) : <span>-</span>}
                              </td>
                            </>
                            : filterElements(response?.market.selections).map(el => (
                              <td key={Math.random()} className={positiveOrNegative(el?.[total])}>
                                {el?.[total] || el?.[total] === 0 ? addCommas(parseFloat(el?.[total]).toFixed(2)) : <span>-</span>}
                              </td>
                            ))}

                      </tr>
                      {ladderMarket && (
                        <Ladder response={response.market.ladder[sportRadio2 === "My PT" ? "myPt" : "totalBook"]} />
                      )}
                      <tr>
                        <td colSpan="6" className="indent" />
                      </tr>
                      {show &&
                        response?.downline.map((el, index) => (
                          <Fragment key={Math.random()}>
                            <tr
                              className={
                                response?.downline[index]?.userName.length ===
                                  response?.downline[index + 1]?.userName.length ||
                                  response?.downline[index - 1]?.userName.length ===
                                  response?.downline[index]?.userName.length
                                  ? "join"
                                  : ""
                              }
                            >
                              <td style={{ paddingLeft: 13 * (index + 1) + "px" }}>{el?.loginName}</td>
                              <td>
                                <td>
                                  {el.ladder?.myPt && (
                                    <img
                                      src={Vector}
                                      alt=""
                                      onClick={() =>
                                        setShowLadder(prev => ({ ...prev, [index]: prev[index] ? !prev[index] : true }))
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {response?.downline.length - 1 === index ||
                                    response?.downline[index]?.userName.length ===
                                    response?.downline[index + 1]?.userName.length
                                    ? el.userName.length < 11 && (
                                      <img
                                        src={Icon2}
                                        alt=""
                                        width="15px"
                                        onClick={() => setParentUserCode(el.userName)}
                                      />
                                    )
                                    : el.userName.length < 11 && (
                                      <img
                                        src={Icon}
                                        alt=""
                                        onClick={() =>
                                          setParentUserCode(index !== 0 && response?.downline[index - 1]?.userName)
                                        }
                                      />
                                    )}
                                </td>
                              </td>
                              {response?.market.marketType === 'INNINGS_RUNS' || response?.market.marketType === 'TOURNAMENT_WINNER' ? '' :
                                <td>
                                  {addCommas(parseFloat(el?.[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"]))}
                                </td>
                              }

                              {response?.market.marketType === 'TOURNAMENT_WINNER' ?
                                <>
                                  <td className="">
                                    {addCommas(parseFloat(el[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"]))}
                                  </td>
                                  <td className={positiveOrNegative(el.selections[0][total])}>
                                    {el.selections[0][total] || el.selections[0][total] === 0 ?
                                      addCommas(parseFloat(el.selections[0][total])) : <span>0.00</span>}
                                  </td>
                                  <td className={positiveOrNegative(el.selections[1][total])}>
                                    {el.selections[1][total] || el.selections[1][total] === 0 ?
                                      addCommas(parseFloat(el.selections[1][total])) : <span>0.00</span>}
                                  </td>
                                </>
                                : response?.market.marketType === 'INNINGS_RUNS' ?
                                  <>
                                    {
                                      ladderMin = Math.min(...el.ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(elem => elem.exposure)) || 0,
                                      ladderMax = Math.max(...el.ladder[sportRadio2 === "Total Book" ? 'totalBook' : 'myPt'].map(elem => elem.exposure)) || 0, ''
                                    }
                                    <td className="">
                                      {addCommas(parseFloat(el[sportRadio2 === "Total Book" ? "totalStakes" : "stakes"]))}
                                    </td>
                                    <td className={positiveOrNegative(ladderMax)}>
                                      {ladderMax ?
                                        addCommas(ladderMax < 0 ? 0 : ladderMax) : addCommas(0)}
                                    </td>
                                    <td className={positiveOrNegative(ladderMin)}>
                                      {ladderMin ?
                                        addCommas(ladderMin > 0 ? 0 : ladderMin) : addCommas(0)}
                                    </td>
                                  </> :
                                  filterElements(el?.selections).map(el => (
                                    <td key={Math.random()} className={positiveOrNegative(el?.[total])}>
                                      {el?.[total] || el?.[total] === 0 ? addCommas(parseFloat(el?.[total])) : <span>-</span>}
                                    </td>
                                  ))}
                            </tr>
                            {showLadder?.[index] && (
                              <Ladder response={el.ladder[sportRadio2 === "My PT" ? "myPt" : "totalBook"]} />
                            )}
                            {!(
                              response?.downline[index]?.userName.length ===
                              response?.downline[index + 1]?.userName.length ||
                              response?.downline[index - 1]?.userName.length ===
                              response?.downline[index]?.userName.length
                            ) && (
                                <tr>
                                  <td colSpan="6" className="indent" />
                                </tr>
                              )}
                          </Fragment>
                        ))}
                    </tbody>
                  </>
                ) : (
                  <td></td>
                )}
              </table>
            </div>
            <div className="table-3">
              <Table3 marketId={MarketId} betList={sportRadio === "Bet List"} />
            </div>
          </div>

          : <p>Market is not available</p>}
      </div>
    </div>
  )
}
export default WorkStation
