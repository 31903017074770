import { Table } from "react-bootstrap"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useHistory } from "react-router-dom"
import { useStorage } from "../../Providers/StorageProvider"
import CurrentTime from "../../components/CurrentTime"
import moment from "moment"

export default ({ data }) => {
  const history = useHistory()
  const { userLevel } = useStorage()

  return (
    <>
      <p className="total-pnl">
        {"Total P&L: "}
        {data && data.totalPnl ? <StringToRecolorNumber>{data.totalPnl}</StringToRecolorNumber> : "-"}
      </p>
      <div className="pnl_size">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th />
              {/* <th style={{ textAlign: "right" }}>P&L</th> */}
              <th>P&L</th>
              {/* {userLevel === "Agent" && (
                <>
                  <th style={{ textAlign: "right" }}>Credit Limit</th>
                  <th style={{ textAlign: "right" }}>Balance</th>
                </>
              )} */}
            </tr>
          </thead>
          <tbody>
            {data && data.statement && data.statement.length ? (
              data.statement.map(item => (
                <tr key={item.date + Math.random()}>
                  <td>{<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.date}</CurrentTime>}</td>
                  <td>{!!item.actionDisplayName ? item.actionDisplayName : ''}</td>
                  <td>
                    {!!item.description ?
                    <a
                      className="no-href"
                      onClick={() =>
                        history.push(
                          history.location.pathname + "/bet-breakdown/" + item.description.market + "#market"
                        )
                      }
                    >
                      {item.description.eventName.replace(
                        "${HH:mm}",
                        moment.unix(item.description.marketTime / 1000).utcOffset(330).format("HH:mm")) +
                        " - " +
                        item.description.marketName + 
                        ` ${!!item.description.selectionName ? item.description.selectionName: ''}`}
                    </a> : <></>}
                  </td>
                  {/* <td style={{ textAlign: "right" }}> */}
                  <td>
                    <StringToRecolorNumber>{item.amount}</StringToRecolorNumber>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </>
  )
}
