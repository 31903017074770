import React, { useState, memo } from 'react'
import { Image } from 'react-bootstrap'
import './index.scss'
// import Logo from '../../static/images/logo.png'
import CogIcon from 'mdi-react/CogIcon'
import LogoutIcon from 'mdi-react/LogoutVariantIcon'
import InfoIcon from 'mdi-react/InformationVariantIcon'
import DropdownForHeader from '../../components/DropdownForHeader'
import ChevronDown from 'mdi-react/ChevronDownIcon'
// import ChevronIcon from 'mdi-react/ChevronDoubleRightIcon'
// import { slide as Menu } from 'react-burger-menu'
import ModalChangePass from '../../components/ModalChangePass'
import { useAuth } from '../../Providers/AuthProvider'
import Clock from 'react-live-clock';
import { useStorage } from '../../Providers/StorageProvider'
import CurrentTime from '../../components/CurrentTime'
import { useHistory } from 'react-router-dom'

const Header = () => {
  const { activeTimeSetting, setActiveTimeSetting, timeSettings, userLevel, isLoading } = useStorage();
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { logout } = useAuth()
  const myLogin = sessionStorage.getItem('myLoginName')
  const myLastLogged = sessionStorage.getItem('myLastLoginTime')

  const changeVisibility = () => {
    setIsModalVisible(!isModalVisible);
  }


  const controls = () => (
    <>
      <div onClick={() => window.open(`/help/${userLevel.toLowerCase()}`, "help", "screenX=485, screenY=240, width=950, height=600")} className="controls__button hover-opacity">
        {/*<InfoIcon />*/}
        <span className="controls__button-help" >Help</span>
      </div>
      <div className="controls__dropdown">
        <DropdownForHeader title={<><span className="controls__button-settings">Settings</span></>} options={[{ title: 'Change Password', toggle: changeVisibility }]} />
      </div>
      <a
        key={Math.random()}
        href="/login"
        onClick={logout}
        className="controls__button hover-opacity"

      >
        {/*<LogoutIcon />*/}
        <span className="controls__button-logout" >Logout</span>
      </a>
    </>
  )

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-content__left">
          <div className="header-content__logo">
            <Image onClick={() => history.push("/")} src={"/assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "").replace("admin.", "") + "/logo.png"} />
          </div>
          <div className="header-content__date-time">
            <span className="date"><Clock
              timezone={activeTimeSetting.timezone}
              format="MMM D, YYYY"
              ticking={true}
            />
            </span>
            <span className="time"><Clock
              format="HH:mm:ss"
              timezone={activeTimeSetting.timezone}
              ticking={true}
            /></span>
            <div className={`wrapper ${isLoading ? '' : 'hide'}`}><div className={`spinner `}></div></div>
            <DropdownForHeader
              className="time-control"
              title={<><span>( {activeTimeSetting.gmt}</span><ChevronDown size={18} />)</>}
              options={timeSettings.map(item => ({ title: `${item.title} - (GMT ${item.gmt})`, toggle: () => setActiveTimeSetting(item) }))}
            />
          </div>
        </div>
        <div className="header-content__right">
          <div className="header-content__login-info">
            <p>
              Logged in as {myLogin}
            </p>
            <p style={{ fontSize: "11px" }}>
              Last logged in: {<CurrentTime format="D/MM/YYYY HH:mm">{myLastLogged}</CurrentTime>}
            </p>
          </div>
          <div className="header-content__controls">
            {controls()}
          </div>
          <div className="header-content__menu">
            {/* <Menu right width={200} customCrossIcon={<ChevronIcon />}> */}
            {controls()}
            {/* </Menu> */}
          </div>
        </div>
      </div>
      <ModalChangePass visible={isModalVisible} closeFunc={changeVisibility} />
    </div>
  )
}

export default memo(Header)
