import moment from "moment"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import DatePicker from "../../components/DatePicker"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import { useAuth } from "../../Providers/AuthProvider"
import { useStorage } from "../../Providers/StorageProvider"
import { numberWithCommas } from "../../utils/dataTransform"
import "./index.scss"

const Balance = () => {
  const { balance, userLevel, userName } = useStorage()
  const [tableData, setTableData] = useState([])
  const [clientBalance, setClientBalance] = useState(0)
  const [responseData, setResponseData] = useState(null)
  const [rewardsSummary, setRewardSummary] = useState({})
  const { requestsToApi } = useAuth()

  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  const role = sessionStorage.getItem("role")

  const getClientBalance = async () => {
    try {
      const response = await requestsToApi.get(`/agency/${userName}/account/clientBalance?dl=${userName}`)
      console.log("Reponse", response?.data?.result?.clientBalance)
      setClientBalance(response?.data?.result?.clientBalance || "-")
    } catch (error) {
      console.log(error?.message)
    }
  }

  const getUserCount = async () => {
    try {
      const response = await requestsToApi.get(
        `/agency/${userName}/account/downline-analysis?dl=${userName}${
          dateTimeRange.start ? `&date=${new Date(dateTimeRange.start).setHours(0, 0, 0, 0)}` : ""
        }`
      )
      setResponseData(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }
  const getRewardsReport = async () => {
    try {
      const response2 = await requestsToApi.get(`/agency/${userName}/account/rewardsReport?dl=${userName}`)
      if (response2?.data?.result?.result) {
        setRewardSummary(response2.data.result.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }
  useEffect(() => {
    getClientBalance()

    setTableData(
      [
        ["Net Exposure", balance.actualNetExposure],
        ["Balance Down", balance.cashBalance],
        ["Balance Up", balance.totalBalance],
        ["Credit Limit", balance.smaCredit],
        ["Available Credit", balance.availableBalance],
        [
          `Total credit given to ${userLevel === "MA" ? "Agents" : userLevel === "Agent" ? "Members" : "MAs"}`,
          balance.totalDownlineCredit
        ],
        [userLevel !== "MA" && userLevel !== "Agent" ? "Total credit distributed by MAs" : "", balance.totalMACredit],
        [userLevel !== "Agent" ? "Total credit distributed by Agents" : "", balance.totalAgentCredit],
        [
          userName.length === 4 || userName.length === 6 ? "Client Balance" : "",
          userName.length === 4 || userName.length === 6 ? clientBalance : ""
        ]
      ].map(([title, value], index) =>
        index > 2 ? [title, numberWithCommas(value)] : [title, <StringToRecolorNumber>{value}</StringToRecolorNumber>]
      )
    )
  }, [balance, userLevel, clientBalance])

  useEffect(() => {
    // getUserCount();
    getRewardsReport()
  }, [])

  const userCountTemplate = [
    ["TOTAL_USERS", "Total Users", false],
    ["ACTIVE_USERS", "Active Users", false],
    ["CLOSED_USERS", "Closed Users", false],
    ["INACTIVE_USERS", "Inactive Users", false],
    ["SUSPENDED_USERS", "Suspended Users", false],
    ["PLACED_SAUDA_USERS", "Placed Bet Users", false],
    ["LOGGED_IN_USERS", "Logged In Users", false],
    ["NEW_USERS", "New Users", false]
  ]

  const paymentCountTemplate = [
    ["TOTAL_DEPOSIT_AMOUNT", "Total Deposit Amount", false],
    ["TOTAL_WITHDRAW_AMOUNT", "Total Withdraw Amount", false],
    ["TOTAL_DEPOSIT_CUSTOMERS", "Total Deposit Customers", false],
    ["TOTAL_WITHDRAW_CUSTOMERS", "Total Withdraw Customers", false],
    ["COMMON_DW_CUSTOMERS", "Common DW Customers", false],
    ["COMMON_DW_CUSTOMERS_DEPOSIT_AMOUNT", "Common DW Customers Deposit Amount", false],
    ["COMMON_DW_CUSTOMERS_WITHDRAW_AMOUNT", "Common DW Customers Withdraw Amount", false],
    ["ONLY_DEPOSIT_CUSTOMERS", "Only Deposit Customers", false],
    ["ONLY_DEPOSIT_CUSTOMERS_AMOUNT", "Only Deposit Customers Amount", false],
    ["ONLY_WITHDRAW_CUSTOMERS", "Only Withdraw Customers", false],
    ["ONLY_WITHDRAW_CUSTOMERS_AMOUNT", "Only Customers Amount", false]
  ]
  const rewardsSummaryReport = [
    ["NO_TXN", "Number of Transaction", false],
    ["AMOUNT", "Amount", false],
    ["MIN_TXN_DATE", "MIN_TXN_DATE", false],
    ["MAX_TXN_DATE", "MAX_TXN_DATE", false]
  ]
  const formatData = (num, recolor) => {
    return recolor ? <StringToRecolorNumber>{num || 0}</StringToRecolorNumber> : <span>{num || 0}</span>
  }

  return (
    role !== "READ_ONLY" && (
      <div className="balance-page">
        <div>
          <div className="page-header">
            <div>
              <h1>Balance Information</h1>
            </div>
          </div>
          <Table striped hover id="balance-table" variant="dark">
            <tbody>
              {tableData.map(([title, value]) =>
                title ? (
                  <tr key={Math.random()}>
                    <th>{title}</th>
                    <td>{value}</td>
                  </tr>
                ) : (
                  <></>
                )
              )}
            </tbody>
          </Table>
          <div>
            <div className="page-header">
              <div className="row ml-2 mb-2 mt-5">
                <DatePicker
                  dateTimeRange={dateTimeRange}
                  onChangeFunc={result => {
                    console.log(result)
                    setDateTimeRange(result)
                  }}
                />
                <div className="ml-5">
                  <ButtonCustom onClick={() => getUserCount()} color="cyan">
                    Apply
                  </ButtonCustom>
                </div>
              </div>
            </div>
            <div className="row">
              <Table striped hover variant="dark" id="balance-table">
                <tbody>
                  {userCountTemplate.map(([key, title, recolor]) => (
                    <tr key={title}>
                      <th className="">{title}</th>
                      <td className="">{formatData(responseData?.users[key], recolor)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Table striped hover variant="dark" id="balance-table">
                <tbody>
                  {paymentCountTemplate.map(([key, title, recolor]) => (
                    <tr key={title}>
                      <th className="">{title}</th>
                      <td className="">{formatData(responseData?.payments[key], recolor)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {userName.length === 6 && (
                <Table striped hover variant="dark" id="balance-table">
                  <tbody>
                    {rewardsSummaryReport.map(([key, title, recolor]) => (
                      <tr key={title}>
                        <th className="">{title}</th>
                        <td className="">{formatData(rewardsSummary[key], recolor)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default Balance
