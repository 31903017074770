import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1 from '../../../static/images/help/user_listing.png'
import Img2 from '../../../static/images/help/menu_user_listing.png'
import HelpMenuLink from "../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const user = userLevel === "sma" ? "Customer" : userLevel === "ma" ? "MA" : "Agent"
  return <div>
    <h1>Admin Users</h1>
    <div className="body">

      <p className="shortdesc">This section describes the role of users created by {
        userLevel === "sma" ? "the Company a Customer" : `an ${user}`
      } and how to get basic information about them.</p>

      <br />
      <Image src={Img1} />
      <br /><br />

      <p>{user}s are advised to create and maintain a list of users that are tasked to run the business and act on behalf of the {user}s.</p>

      <p className="last-p">
        You can view your existing users by navigating to the <strong>Admin Users</strong> &gt; <strong>User Listing</strong> menu item.
            </p>

      <Image src={Img2} />

      <p className="last-p">
        <HelpMenuLink strong>Creating a New Admin Account</HelpMenuLink><br />
                This section describes how to create a new user for an {user}.<br />
        <HelpMenuLink strong>Setting Up Access Rights</HelpMenuLink><br />
                This section describes how to set up or change the access rights for a user.
            </p>
    </div>
  </div>
}