import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1Agent from '../../../static/images/help/net_exposure_agent.png'
import Img1MA from '../../../static/images/help/net_exposure_ma.png'
import Img1SMA from '../../../static/images/help/net_exposure_sma.png'
import Img2 from '../../../static/images/help/bet_ticker.png'
import Img3 from '../../../static/images/help/outrights.png'

import HelpMenuLink from "../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage()
  return <div>
    <h1>Risk Management</h1>
    <div className="body">

      <p className="shortdesc">This section describes how you can find out&nbsp;
                about your net exposure in different markets and market types.</p>

      <p>There are two main features available under the <strong>Risk Management</strong> menu:
                <strong>Net Exposure</strong> and <strong>Outrights</strong>.
                Values in the Stake column show how much has been staked against you based on your Position Taking.
                Red or green values in the <strong>1</strong>, <strong>X</strong>,
                <strong> 2</strong> or <strong>P&L</strong> column show how much you can lose or win, respectively.
            </p>

      <p className="last-p">
        Select the <strong>Net Exposure</strong> option to find out about the potential worst case scenario&nbsp;
                of how much you can lose or win based on the currently active event markets.
            </p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <br /><br />
      <p className="last-p">Select the <strong>Bet Ticker</strong> option to list all the bets the Members have placed in a chronological order.</p>

      <Image src={Img2} />
      <br /><br />

      <p>To find out more about the Bet Ticker, read the <HelpMenuLink>Bet Ticker</HelpMenuLink> section.</p>

      <p className="last-p">Select the <strong>Outrights</strong> option to find out about your net exposure in outrights markets.</p>

      <Image src={Img3} />

      <p className="last-p">
        <HelpMenuLink strong>{`Net Exposure`}</HelpMenuLink><br />
                This section describes how you can find out about your and your downline's net exposure.<br />
        <HelpMenuLink strong>{`Bet Ticker`}</HelpMenuLink><br />
                This section describes what Bet Ticker is in the site.
            </p>
    </div>
  </div>
}