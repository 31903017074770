import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1Agent from '../../../static/images/help/agency_listing_agent.png'
import Img2Agent from '../../../static/images/help/agency_listing_ext_agent.png'
import Img1MA from '../../../static/images/help/agency_listing_ma.png'
import Img2MA from '../../../static/images/help/agency_listing_ext_ma.png'
import Img1SMA from '../../../static/images/help/agency_listing_sma.png'
import Img2SMA from '../../../static/images/help/agency_listing_ext_sma.png'
import HelpMenuLink from "../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  return <div>
    <h1>{childrenLevel} Management</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to perform basic user management tasks,
            such as creating downline users and setting or editing their account properties.</p>

      <p>You can access this feature by navigating to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu.</p>

      <br />
      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <p>The <strong>{childrenLevel} Listing</strong> window gives you a compact table of your&nbsp;
                {childrenLevel}s showing the most relevant user information, such as: <br />
                Login name and ID,<br />
                Downline button,<br />
                User status,<br />
                Downline summary button,<br />
                Net exposure,<br />
                Settled balance,<br />
                Credit limit and<br />
                Position Taking.</p>

      <p>
        Settled balance is shown in two separate columns. The green number in the <strong>Take</strong> column shows
                how much the {childrenLevel} owes you, while the red in the <strong>Give</strong> column shows how much you owe the specific {childrenLevel}.
            </p>

      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p>
        Click the arrow on the right side of the compact view to extend it and show further details about your MAs, including:<br />
                The date when the user account was created,<br />
                The date of the last successful login and<br />
                The last IP address used to log in with the user account.
            </p>

      <p>The most important tasks related to agency management are detailed below.</p>

      <p>
        <HelpMenuLink strong>{`Creating a New ${childrenLevel} Account`}</HelpMenuLink><br />
                This section describes how to create an account for an {childrenLevel}.<br />
        <HelpMenuLink strong>{`Unlocking an ${childrenLevel} Account`}</HelpMenuLink><br />
                This section describes how to unlock the account of a downline user in the site.<br />
        <HelpMenuLink strong>{`Changing the Status of an ${childrenLevel} Account`}</HelpMenuLink><br />
                This section describes the various user statuses in the site and how to change it.<br />
        <HelpMenuLink strong>{`Closing an ${childrenLevel} Account`}</HelpMenuLink><br />
                This section describes how to close an {childrenLevel} account.<br />
        <HelpMenuLink strong>{`Changing the Credit Limit for an ${childrenLevel} Account`}</HelpMenuLink><br />
                This section describes how to change the credit limit for an {childrenLevel}.<br />
        <HelpMenuLink strong>{"Position Taking"}</HelpMenuLink><br />
                This section describes what Position Taking is and how it works.<br />
        <HelpMenuLink strong>{"Viewing a Downline User's Summary"}</HelpMenuLink><br />
                This section describes how you can view the detailed summary of one of your downline users
            </p>
    </div>
  </div>
}