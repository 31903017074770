import { Image } from "react-bootstrap"
import { useHelpStorage } from "../../../../../Providers/HelpProvider"
import Img1Agent from "../../../../../static/images/help/menu_pt_listing_agent.png"
import Img1MA from "../../../../../static/images/help/menu_pt_listing_ma.png"
import Img1SMA from "../../../../../static/images/help/menu_pt_listing_sma.png"
import Img2Agent from "../../../../../static/images/help/pt_listing_agent.png"
import Img2MA from "../../../../../static/images/help/pt_listing_ma.png"
import Img2SMA from "../../../../../static/images/help/pt_listing_sma.png"
import Img3 from "../../../../../static/images/help/icon_downline.svg"
import Img4Agent from "../../../../../static/images/help/button_selectall_pt_listing_agent.png"
import Img4MA from "../../../../../static/images/help/button_selectall_pt_listing_ma.png"
import Img4SMA from "../../../../../static/images/help/button_selectall_pt_listing_sma.png"
import Img5Agent from "../../../../../static/images/help/button_selectsome_pt_listing_agent.png"
import Img5MA from "../../../../../static/images/help/button_selectsome_pt_listing_ma.png"
import Img5SMA from "../../../../../static/images/help/button_selectsome_pt_listing_sma.png"
import Img6 from "../../../../../static/images/help/button_edit_pt.png"
import Img7Agent from "../../../../../static/images/help/popup_pt_edit_agent.png"
import Img7MA from "../../../../../static/images/help/popup_pt_edit_ma.png"
import Img7SMA from "../../../../../static/images/help/popup_pt_edit_sma.png"
import Img8Agent from "../../../../../static/images/help/pt_listing_updated_agent.png"
import Img8MA from "../../../../../static/images/help/pt_listing_updated_ma.png"
import Img8SMA from "../../../../../static/images/help/pt_listing_updated_sma.png"
import HelpMenuLink from "../../../../../components/HelpMenuLink"

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  return (
    <div>
      <h1>Position Taking Management</h1>
      <div className="body">
        <p className="shortdesc">
          This section describes how you manage and view Position Taking for one or multiple {childrenLevel}s.
        </p>

        <p>
          Using this feature, you can select one or more {childrenLevel}s and change
          {userLevel === "agent"
            ? " position you want to take against them."
            : `Position Taking settings. In the Take column, you can set your own Position Taking whilst in the Give column you can set the maximum
                Position Taking that can be distributed by the ${childrenLevel}(s) that you have selected.
                You can also view how much position your ${childrenLevel}s or Agents take against their downline users, but you cannot edit these settings.`}
        </p>
        <p>To modify Position Taking, follow this procedure:</p>

        <p className="last-p">
          To display the list of your MAs, click the <strong>Agency Management</strong> &gt;&nbsp;
          <strong>Position Taking Listing </strong> menu item. The PT you have been given per sport is displayed above
          the table on the right.
        </p>

        <Image src={userLevel === "agent" ? Img1Agent : userLevel === "ma" ? Img1MA : Img1SMA} />
        <br />
        <Image src={userLevel === "agent" ? Img2Agent : userLevel === "ma" ? Img2MA : Img2SMA} />

        <p className="last-p">
          {childrenLevel !== "agent" ? (
            <>
              To view how much position your{childrenLevel === "sma" ? " MAs or" : ""} Agents have taken against their
              downline users, click the <img style={{ width: "20px", margin: 0 }} src={Img3} alt="" /> button next to
              the MA to drill down as required.
            </>
          ) : (
            ""
          )}
          <br />
          To select all the {childrenLevel}s displayed in the Position Taking Listing table, click the checkbox in the
          top left corner.
        </p>

        <Image src={userLevel === "agent" ? Img4Agent : userLevel === "ma" ? Img4MA : Img4SMA} />

        <p className="last-p">
          Alternatively, you can select users individually by clicking the checkboxes next to the their login names.
        </p>

        <Image src={userLevel === "agent" ? Img5Agent : userLevel === "ma" ? Img5MA : Img5SMA} />

        <p className="last-p">
          To change Position Taking for the selected users, click the <strong>Edit PT</strong> button.
        </p>

        <Image src={Img6} />

        <p className="last-p">
          Fill in the{" "}
          {userLevel === "agent" ? (
            "fields to specify how much position you want to take "
          ) : (
            <>
              <strong>Take</strong> and <strong>Give</strong> fields{" "}
            </>
          )}
          making sure that the sum of the values in these two fields is less than or equal to your maximum Position
          Taking amount per sport. To confirm, click the <strong>Save</strong> button.
        </p>

        <Image src={userLevel === "agent" ? Img7Agent : userLevel === "ma" ? Img7MA : Img7SMA} />

        <p>
          A notification on the top of the screen will let you know if the changes were applied successfully and the
          updated values are shown in green.
        </p>
        <br />
        <Image src={userLevel === "agent" ? Img8Agent : userLevel === "ma" ? Img8MA : Img8SMA} />

        <p className="last-p">
          If the new Position Taking settings cannot be applied, a red triangle is displayed next to the login name of
          the user. Move your mouse over the triangle to find out why the changes are not accepted.
          <br />
          <strong>Note:</strong> You have to make sure that{userLevel !== "agent" ? " all" : ""} the values that you
          enter in the
          {userLevel !== "agent" ? (
            <>
              <strong>Take</strong> and <strong>Give</strong>
            </>
          ) : (
            ""
          )}
          fields comply with your maximum Position Taking settings, otherwise you will not be able to save your changes.
          <br />
          <strong>Parent topic:</strong> <HelpMenuLink>Position Taking</HelpMenuLink>
          <br />
        </p>
      </div>
    </div>
  )
}
