import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/menu_member_listing_agent.png'
import Img1MA from '../../../../static/images/help/menu_agent_listing_ma.png'
import Img1SMA from '../../../../static/images/help/menu_ma_listing_sma.png'
import Img2Agent from '../../../../static/images/help/member_listing_agent.png'
import Img2MA from '../../../../static/images/help/agent_listing_ma.png'
import Img2SMA from '../../../../static/images/help/ma_listing_sma.png'
import Img3Agent from '../../../../static/images/help/popup_changing_password_agent.png'
import Img3MA from '../../../../static/images/help/popup_changing_password_ma.png'
import Img3SMA from '../../../../static/images/help/popup_changing_password_sma.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()

  return <div>
    <h1>Unlocking {userLevel === "sma" ?
      "a Customer, MA, Agent or Member an MA" :
      userLevel === "ma" ? "an Agent" : "a Member"} Account</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to unlock the account of a downline user in the site.</p>

      <p>
        Downline users may get locked out of the system if they have too many unsuccessful login attempts.
                To unlock the account of {userLevel === "sma" ?
          "a Customer, MA, Agent or Member, an MA" :
          userLevel === "ma" ? "an Agent" : "a Member"}, you must reset their password.<br />
        <strong>Note:</strong> You cannot unlock an account that was previously closed.
                Closing an account is a permanent action that cannot be undone.
            </p>
      <p>To unlock {userLevel === "sma" ?
        "a Customer, MA, Agent or Member MA" :
        userLevel === "ma" ? "an Agent" : "a Member"} account, follow this procedure:</p>

      <p className="last-p">Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu item and click the <strong>Login Name</strong>
            &nbsp;or the <strong>ID</strong> of the {childrenLevel} whose password you want to reset.</p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">In the <strong>Information</strong> section, enter and confirm a new password in the&nbsp;
            <strong>Password</strong> and <strong>Repeat Password</strong> fields.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">
        <strong>Important:</strong>  Passwords must be between 6 and 15 characters and must contain at least one number and one letter.
                Special characters are not allowed.<br />
                Click the <strong>Save</strong> button to reset the password of the {childrenLevel} account.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink><br />
      </p>
    </div>
  </div>
}