import React, { useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"

export const StorageContext = React.createContext()
export const useHelpStorage = () => useContext(StorageContext)
export default ({ children }) => {
  const history = useHistory()

  const urlLevel = history.location.pathname.split("/").slice(2)[0]?.toLowerCase()
  const userLevel = useMemo(
    () =>
      urlLevel === "sma" || urlLevel === "ma" || urlLevel === "agent" ? urlLevel : sessionStorage.getItem("myLevel"),
    []
  )

  const childrenLevel = useMemo(
    () => (userLevel === "sma" ? "MA" : userLevel === "ma" ? "Agent" : userLevel === "agent" ? "Member" : "SMA"),
    []
  )

  const userName = useMemo(() => sessionStorage.getItem("myUserName"), [])

  return (
    <StorageContext.Provider
      value={{
        userName,
        userLevel,
        childrenLevel,
        urlLevel
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}
