import { useEffect, useState } from 'react'
import { useAuth } from '../../Providers/AuthProvider';
import './index.scss'
import { useStorage } from '../../Providers/StorageProvider';


export default ({ children, className, speed = 1 }) => {
  const { requestsToApi } = useAuth();
  const [message, setMessage] = useState("");
  const [indicator, setIndicator] = useState(false)
  const [reqTrigger, resetreqTrigger] = useState({})
  const { userName } = useStorage();
  const marqueeMessageReq = async () => {
    try {
      const response = await requestsToApi.get(`agency/${userName}/account/notification/displayed-messages`)
      setIndicator(!indicator)
      setMessage(response.data.result.length ? response.data.result[0].text : "")
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      resetreqTrigger({})
    }, 20000);
    return () => clearTimeout(timer);
  }, [indicator]);

  useEffect(() => {
    marqueeMessageReq()
  }, [reqTrigger]);

  return (
    <div className={className //+ (userLevel === "SMA" && userName.length === 2 ? " invisible" : "")
    } style={{ padding: "0 20px", display: !message ? 'none' : 'flex' }}>
      <div className="marquee ">
        <span style={{ animationDuration: `${50 / speed}s` }}>
          {message}
        </span>
      </div>
    </div>
  )
}