import "./index.scss"
import RadioCustom from "../../../components/RadioCustom"
import { Table } from "react-bootstrap"
import { useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/dataTransform"
import { useAuth } from "../../../Providers/AuthProvider"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"
import moment from "moment"

export default ({ userId, userLevel,getWallets }) => {
  const { requestsToApi } = useAuth()
  const [responseData, setResponseData] = useState(null)
  const [displayedData, setDisplayedData] = useState(null)
  const { userName: myUserName } = useStorage()
  const tableTemplate = [
    ["COMM", "COMM", false],
    ["EXPOSURE", "EXPOSURE", false],
    ["MKTID", "MKTID", false],
    ["PL", "PL", true],
    ["SETTLEMENT_DATE", "SETTLEMENT_DATE", false]
  ]

  useEffect(async () => {
    try {
      const responce = await requestsToApi.get(`agency/${myUserName}/report/wagering-info?memberCode=${userId}`)
      setResponseData({bonus:responce.data.result.bonus,promoCode:responce.data.result.promoCode})
      setDisplayedData(responce.data.result.result);
    } catch (error) {
      console.log(error?.message)
    }
  }, [userId])

//   const radioToggle = e => {
//     setDisplayedData(responseData[e.target.attributes["status"].value])
//   }

  const formatData = (num, recolor,title) => {
    if(title === "SETTLEMENT_DATE") return moment(num).format("DD.MM.YYYY HH:mm:ss")
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  return (
    <div className="activity-report">
        <p className="total-pnl">
        Promo Code: {responseData?.promoCode || ""}
      </p>
        <p className="total-pnl">
        Bonus: {responseData?.bonus || 0}
      </p>
      <div className="activity-body">
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th scope="col">COMM</th>
              <th scope="col">EXPOSURE</th>
              <th scope="col">MKTID</th>
              <th scope="col">PL</th>
              <th scope="col">SETTLEMENT_DATE</th>
            </tr>
          </thead>
          <tbody>
            {displayedData ? (
              displayedData.map(fieldName => (
                <tr key={Math.random()}>
                  {tableTemplate.map(([key, title, recolor])=> (
                    <td key={Math.random()}>{formatData(fieldName[title], recolor,title)}</td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
