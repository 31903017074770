import "./index.scss"
import BetListReport from "../SecretRoom/BetListReport"
import { useStorage } from "../../Providers/StorageProvider"

export default () => {
  const { userLevel } = useStorage()
  return (
    <div className="bet-list-page">
      <h1>Bets List</h1>
      <BetListReport userLevel={userLevel} />
    </div>
  )
}
