import { useEffect, useState } from "react"
import ButtonCustom from "../../components/ButtonCustom"
import { useAuth } from "../../Providers/AuthProvider"
import "./index.scss"
import { useStorage } from "../../Providers/StorageProvider"

export default () => {
  const [notificatinsData, setNotificationsData] = useState([{ text: "" }, { text: "" }])
  const { requestsToApi } = useAuth()
  const { userName } = useStorage()

  const getNotificationsDataReq = async () => {
    try {
      const response = await requestsToApi.get(`agency/${userName}/account/notification/messages`)
      let firstNotification, secondNotification
      if (response.data.result.length) {
        firstNotification = response.data.result.filter(item => item.order === 0)[0]
        secondNotification = response.data.result.filter(item => item.order === 1)[0]
      }
      if (!firstNotification) firstNotification = { text: "", order: 0 }
      if (!secondNotification) secondNotification = { text: "", order: 1 }

      setNotificationsData([firstNotification, secondNotification])
    } catch (error) {
      console.log(error?.message)
    }
  }

  const updateNotification = async index => {
    try {
      const { id, order, text } = notificatinsData[index]
      if (text === "") {
        const response = await requestsToApi.del(`agency/${userName}/account/notification/message/${id}`)
      } else {
        const reqData = { id, order: order ? order : index, text }
        const response = await requestsToApi.post(`agency/${userName}/account/notification/message`, reqData)
        changeData(response.data.result.text, response.data.result.order)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getNotificationsDataReq()
  }, [])

  const changeData = (value, index, changed = false) => {
    const updatedData = [...notificatinsData].map((item, itemIndex) => {
      if (itemIndex === index) {
        item.text = value
        item.changed = changed
      }
      return item
    })
    setNotificationsData(updatedData)
  }

  return (
    <div className="notifications-page">
      <div className="page-header">
        <h1>Notifications</h1>
      </div>
      {notificatinsData.map((item, index) => (
        <div key={item.id}>
          <textarea value={notificatinsData[index].text} onChange={e => changeData(e.target.value, index, true)} />
          <div className="notifications-page__button">
            <ButtonCustom onClick={() => updateNotification(index)} disabled={!item.changed}>
              Update
            </ButtonCustom>
          </div>
        </div>
      ))}
    </div>
  )
}
