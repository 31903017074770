import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/menu_member_listing_agent.png'
import Img1MA from '../../../../static/images/help/menu_agent_listing_ma.png'
import Img1SMA from '../../../../static/images/help/menu_ma_listing_sma.png'
import Img2Agent from '../../../../static/images/help/member_listing_agent.png'
import Img2MA from '../../../../static/images/help/agent_listing_ma.png'
import Img2SMA from '../../../../static/images/help/ma_listing_sma.png'
import Img3Agent from '../../../../static/images/help/popup_changing_status_closed_agent.png'
import Img3MA from '../../../../static/images/help/popup_changing_status_closed_ma.png'
import Img3SMA from '../../../../static/images/help/popup_changing_status_closed_sma.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const childrenAppeal = userLevel === "sma" ?
    "an MA" : userLevel === "ma" ? "an Agent" : "a Member";
  return <div>
    <h1>Closing {childrenAppeal} Account</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to close {childrenAppeal} </p>

      <p className="last-p">
        <strong>Important:</strong> Closed status cannot be changed.
                Users with a closed account are locked out of the system permanently which&nbsp;
                means that they or their downline will not be able log in to the system.<br />
                To close {childrenAppeal} account, follow this procedure:<br />
                Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu item and click the <strong>Login Name</strong>
            &nbsp;or the <strong>ID</strong> of the {childrenLevel} account which you want to close.</p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">In the <strong>Information</strong> section, change the <strong>Status</strong>&nbsp;
            of the {childrenLevel} to <strong>Closed</strong>.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">
        Click the <strong>Save</strong> button to close the {childrenLevel} account.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink><br />
      </p>
    </div>
  </div>
}