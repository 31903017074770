import "./index.scss"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import ModalCustom from "../ModalCustom"
import TextInputCustom from "../TextInputCustom"
import { passValidation } from "../../utils/validation"
import RadioCustom from "../RadioCustom"
import { Button, Table } from "react-bootstrap"
import ButtonCustom from "../ButtonCustom"
import { useHistory } from "react-router-dom"
import AlertCustom from "../AlertCustom"

const modalRoot = document.querySelector("#modal-root");

export default ({ settings, closeFunc,greenAlertMessage,setGreenAlertMessage }) => {
  // console.log("settings", settings)
  const history = useHistory()
  const handleClose = () =>{
    closeFunc();
    history.push("/agency-management/downline");
  }
  return (
    <ModalCustom visible className="modal-child-info" closeFunc={() => handleClose()} width={"490px"}>
      <AlertCustom timeout={3000} type="green" disableFunc={() => setGreenAlertMessage(null)}>
        {greenAlertMessage}
      </AlertCustom>
      <div className="new-member-pop-up">
        <div className="new-member-pop-up-info">
          <div className="new-member-pop-up-info-item">
            <span className="title">Username: </span>
            <span className="value">{settings.data?.username}</span>
            <span onClick={() =>  navigator.clipboard.writeText(settings.data?.username)} className="copy-img"/>
          </div>
          <div className="new-member-pop-up-info-item">
            <span className="title">Password : </span>
            <span className="value">{settings.data?.password}</span>
            <span onClick={() =>  navigator.clipboard.writeText(settings.data?.password)} className="copy-img"/>
          </div>
        </div>
        <div onClick={() => history.push("/agency-management/downline")} className="new-member-pop-up-button">
          Done
        </div>
      </div>
  </ModalCustom>
  )
}
