import { useState } from "react"
import { useStorage } from "../../../Providers/StorageProvider"
import MarketBreakdown from "../MarketBreakdown/index"
import DownlinePage from "./downlinePage"
import "./index.scss"

const Downline = ({ marketId, closeDownline, open }) => {
  const { userLevel } = useStorage()
  const [showTable, setShowTable] = useState(null)

  return showTable ? (
    <MarketBreakdown
      targetUser={{ userId: showTable.userName, userLevel }}
      marketId={showTable.marketId}
      closeBreakdown={() => setShowTable(null)}
    />
  ) : (
    <div className="table-downline">
      <DownlinePage marketId={marketId} closeDownline={closeDownline} setShowTable={setShowTable} open={open} />
    </div>
  )
}
export default Downline
