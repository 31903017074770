import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/report_by_market_agent.png'
import Img1MA from '../../../../static/images/help/report_by_market_ma.png'
import Img1SMA from '../../../../static/images/help/report_by_market_sma.png'
import Img2Agent from '../../../../static/images/help/report_by_market_bets_agent.png'
import Img2MA from '../../../../static/images/help/report_by_market_bets_ma.png'
import Img2SMA from '../../../../static/images/help/report_by_market_bets_sma.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage()
  const descendants = `${userLevel === "sma" ? "MAs, " : ""}
    ${userLevel !== "agent" ? "Agents or " : ""}Members`;

  return <div>
    <h1>P&L Report by Market</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to get more information about your performance and that of your {descendants} for a market in a given time period.</p>

      <p>Specify a time frame using the <strong>From</strong> and <strong>To</strong> drop-down menus and click the Search button to display the P&L Report by Market.</p>

      <br />

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <p>
        The report above shows that the Member lost (Win: -20) on this bet so their P&L is -20.
                {userLevel === "agent" ?
          "You won 4 and passed up 16 to your upline." :
          <>This implies that the Agent{userLevel === "sma" ? ", the MA" : ""} and you won 4 each and that you passed up 8 to your upline.</>}
      </p>

      <p>To find out more about a market, click <strong>View Bets</strong>.</p>
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <br /><br />

      <p className="last-p">
        The table displays a breakdown of the profit and loss for each bet.<br />
        <strong>Parent topic:</strong> <HelpMenuLink>Reports</HelpMenuLink><br />
      </p>
    </div>
  </div>
}