import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1 from '../../../../static/images/help/bet_ticker.png'
import Img2 from '../../../../static/images/help/bet_ticker_filter_by_login.png'
import Img3 from '../../../../static/images/help/dropdown_sport_market_type_bet_ticker.png'
import Img4 from '../../../../static/images/help/dropdown_stake_bet_ticker.png'
import Img5 from '../../../../static/images/help/button_cancel_bet_ticker.png'
import Img6 from '../../../../static/images/help/bet_ticker_cancelled_bet.png'

import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage()
  return <div>
    <h1>Bet Ticker</h1>
    <div className="body">

      <p className="shortdesc">This section describes what Bet Ticker is in the site.</p>

      <p className="last-p">
        The Bet Ticker lists the last 50 open bets that have been updated in the site.
        On this screen, each line represents one bet. The table shows the Member's name,
        the event, market type and selection, the requested and average matched odds,
        the matched and unmatched part of the bet as well as the profit (back) or liability (lay).
            </p>

      <Image src={Img1} />

      <h2>Filtering by Event and/or Member Name</h2>

      <p className="last-p">
        You can filter this list to show the bets placed on a specific event and/or by a specific Member.
                To do so, type the event name and/or the Member's login name or ID in the <strong>Event</strong>
                &nbsp;and/or <strong>Member Name</strong> fields, and click <strong>Apply</strong> .
            </p>

      <Image src={Img2} />

      <h2>Filtering by Sport, Market Type and Stake</h2>

      <p className="last-p">
        You can also filter the Bet Ticker to show all the bets belonging to a specific sport or market type.
                To do so, select the required options from the drop down menus, and click <strong>Apply</strong>.
            </p>

      <Image src={Img3} />

      <p className="last-p">
        To filter a range of stakes to be displayed, click the stake drop-down menu,
                fill in the <strong>From</strong> and <strong>To</strong> fields and click <strong>Apply</strong> .
            </p>

      <Image src={Img4} />

      <p className="last-p">
        <strong>Note:</strong> The original stake is considered when filtering for the minimum stake.
                This filter will ignore the fact that a bet may be matched, unmatched or partially matched.
            </p>

      <h2>Resetting the Filter</h2>

      <p className="last-p">To reset the filter, click the <strong>Clear</strong> button.</p>

      <Image src={Img5} />

      <h2>Unmatched Bets</h2>

      <p>
        Whenever an unmatched or partially matched bet is updated, it will again be displayed at the top of the Bet Ticker.
            </p>

      <p className="last-p">
        When an unmatched bet is cancelled, it remains in the Bet Ticker as shown below:

            </p>

      <Image src={Img6} />

      <p className="last-p">
        <strong>Parent topic:</strong> <HelpMenuLink>Risk Management</HelpMenuLink>
      </p>
    </div>
  </div>
}