import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { PrivateRoute } from "../../utils/PrivateRoute"
import Login from "../../Pages/LoginPage/Login"
import MainSidebar from "../MainSidebar"
import Header from "../Header"
import "../../scss/pages/main-wrapper.scss"
import "../../scss/pages/help-wrapper.scss"
import BalanceTracker from "../BalanceTracker"
import Footer from "../Footer"
import MAListingPage from "../../Pages/MAListingPage"
import Marquee from "../Marquee"
import NewMA from "../../Pages/MAListingPage/NewMA"
import BalancePage from "../../Pages/BalancePage"
import TransferPage from "../../Pages/TransferPage"
import StorageProvider from "../../Providers/StorageProvider"
import Notifications from "../../Pages/Notifications"
import PositionTakingPage from "../../Pages/PositionTakingPage"
import SecretRoom from "../../Pages/SecretRoom"
import StatementPage from "../../Pages/StatementPage"
// import BreakdownStatement from "../../Pages/StatementPage/MemberBreakdown"
import BetListPage from "../../Pages/BetListPage"
import VoidInvalidPage from "../../Pages/VoidInvalidPage"
import TransferStatement from "../../Pages/TransferStatement"
import PnLByAgentPage from "../../Pages/PnLByAgentPage"
import PnLByMarketPage from "../../Pages/PnLByMarketPage"
import EventManagement from "../../Pages/EventManagement"
import BetTickerPage from "../../Pages/BetTickerPage"
import CasinoReport from "../../Pages/CasinoReport"
import NetExposurePage from "../../Pages/NetExposurePage"
import HelpSidebar from "../HelpSidebar"
import AgencyManagementHelp from "../../Pages/Help/AgencyManagementHelp"
import HelpProvider from "../../Providers/HelpProvider"
import CreatingNewAcc from "../../Pages/Help/AgencyManagementHelp/CreatingNewAcc"
import UnlockingAcc from "../../Pages/Help/AgencyManagementHelp/UnlockingAcc"
import ChangingStatusOfAcc from "../../Pages/Help/AgencyManagementHelp/ChangingStatusOfAcc"
import ClosingAcc from "../../Pages/Help/AgencyManagementHelp/ClosingAcc"
import ChangingCreditLimitForAcc from "../../Pages/Help/AgencyManagementHelp/ChangingCreditLimitForAcc"
import PositionTaking from "../../Pages/Help/AgencyManagementHelp/PositionTaking"
import PTManagement from "../../Pages/Help/AgencyManagementHelp/PositionTaking/PTManagement"
import ViewDownlineSum from "../../Pages/Help/AgencyManagementHelp/ViewDownlineSum"
import RiskManagement from "../../Pages/Help/RiskManagement"
import NetExposure from "../../Pages/Help/RiskManagement/NetExposure"
import BetTicker from "../../Pages/Help/RiskManagement/BetTicker"
import Transfer from "../../Pages/Help/Transfer"
import UndoTransfer from "../../Pages/Help/Transfer/UndoTransfer"
import Reports from "../../Pages/Help/Reports"
import PnLByMarket from "../../Pages/Help/Reports/PnLByMarket"
import PnLByAgent from "../../Pages/Help/Reports/PnLByAgent"
import BetList from "../../Pages/Help/Reports/BetList"
import AccountStatement from "../../Pages/Help/AccountStatement"
import AdminUsers from "../../Pages/Help/AdminUsers"
import CreatingNewAdmin from "../../Pages/Help/AdminUsers/CreatingNewAdmin"
import SettingUpRights from "../../Pages/Help/AdminUsers/SettingUpRights"
import { DepositEntryPage } from "../../Pages/DepositEntryPage";
import PaymentReportsPage from "../../Pages/PaymentReportsPage"
import ButtonCustom from "../../components/ButtonCustom"
import PlsummaryReport from "../../Pages/Plsummaryreport/PlsummaryReport"
import AcSummary from "../../Pages/SummaryPage"
// import Authenticator from "../Authenticator"

const MainWrapper = () => (

  <div className="main-wrapper">
    <Header />
    <div className="middle">
      <div className="middle__background"></div>
      <MainSidebar />
      <div className="page-content">
        <Marquee className="main-marquee" speed={2} />
        <BalanceTracker />
        {/*<Authenticator />*/}
        <div className="main-content">
          <Switch>
            <Route exact path="/agency-management/downline/create/MA" component={NewMA} />
            <Route path="/agency-management/downline" component={MAListingPage} />
            <Route exact path="/agency-management/transfer" component={TransferPage} />
            <Route exact path="/agency-management/downline-pt" component={PositionTakingPage} />
            <Route exact path="/agency-management/notifications" component={Notifications} />
            <Route path="/secret-room/:parent/:id" component={SecretRoom} />
            {/* <Route exact path="/secret-room/:parent/:id/bet-breakdown/:market" component={SecretRoom} /> */}
            <Route exact path="/risk-management/net-exposure" component={NetExposurePage} />
            <Route exact path="/risk-management/event-management" component={EventManagement} />
            <Route exact path="/risk-management/bet-ticker" component={BetTickerPage} />
            <Route path="/reports/pnl-by-agency" component={PnLByAgentPage} />
            <Route path="/reports/pnl-by-market" component={PnLByMarketPage} />
            <Route path="/reports/casino-report" component={CasinoReport} />
            <Route exact path="/reports/bet-list" component={BetListPage} />
            <Route exact path="/reports/voidInvalid-report" component={VoidInvalidPage} />
            <Route exact path="/reports/transfer-statement" component={TransferStatement} />
            <Route exact path="/reports/pnl-summary-report" component={PlsummaryReport} />
            <Route exact path="/account/balance" component={BalancePage} />
            <Route path="/account/statement" component={StatementPage} />
            <Route exact path="/account/ac-summary" component={AcSummary} />
            <Route path="/payment-management/deposit-entry" component={DepositEntryPage} />
            <Route path="/payment-management/reports" component={PaymentReportsPage} />
            <Redirect to="/account/balance" />
          </Switch>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

const HelpWrapper = () => (
  <div className="help-wrapper">
    <HelpSidebar />
    <div className="help__content">
      <Switch>
        <Route path="/help/:userLevel/agency_management" component={AgencyManagementHelp} />
        <Route path="/help/:userLevel/creating_agency_user" component={CreatingNewAcc} />
        <Route path="/help/:userLevel/unlocking_account" component={UnlockingAcc} />
        <Route path="/help/:userLevel/changing_agency_status" component={ChangingStatusOfAcc} />
        <Route path="/help/:userLevel/closing_agency_user" component={ClosingAcc} />
        <Route path="/help/:userLevel/changing_credit_limit" component={ChangingCreditLimitForAcc} />
        <Route path="/help/:userLevel/position_taking" component={PositionTaking} />
        <Route path="/help/:userLevel/pt_management" component={PTManagement} />
        <Route path="/help/:userLevel/viewing_downline_summary" component={ViewDownlineSum} />
        <Route path="/help/:userLevel/risk_management" component={RiskManagement} />
        <Route path="/help/:userLevel/net_exposure" component={NetExposure} />
        <Route path="/help/:userLevel/bet_ticker" component={BetTicker} />
        <Route path="/help/:userLevel/transfer" component={Transfer} />
        <Route path="/help/:userLevel/undo_transfer" component={UndoTransfer} />
        <Route path="/help/:userLevel/reports" component={Reports} />
        <Route path="/help/:userLevel/profit_and_loss_by_market" component={PnLByMarket} />
        <Route path="/help/:userLevel/profit_and_loss_by_agent" component={PnLByAgent} />
        <Route path="/help/:userLevel/bet_list" component={BetList} />
        <Route path="/help/:userLevel/account_statement" component={AccountStatement} />
        <Route path="/help/:userLevel/user_management" component={AdminUsers} />
        <Route path="/help/:userLevel/creating_user" component={CreatingNewAdmin} />
        <Route path="/help/:userLevel/setting_up_access_rights" component={SettingUpRights} />
        <Redirect to="/help/:userLevel/agency_management" />
      </Switch>
    </div>
  </div>
)

const Router = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute
        path="/help/:userLevel"
        component={() => (
          <HelpProvider>
            <HelpWrapper />
          </HelpProvider>
        )}
      />
      <StorageProvider>
        <PrivateRoute path="/" component={MainWrapper} />
      </StorageProvider>
    </Switch>
  )
}

export default Router
