

export const getSidebarData = (userLevel, childrenLevel) => {
  const role = sessionStorage.getItem('role');
  const getAgencyManagementFields = () => {


    const AgencyManagementFields = [{
      title: `${childrenLevel} Listing`,
      path: "/agency-management/downline"
    },
      // {
      //   title: "Position Taking Listing",
      //   path: "/agency-management/downline-pt/"
      // },
    ]
    if (sessionStorage.getItem('isFranchiseMaster') != 'true' && role !== "READ_ONLY") {
      AgencyManagementFields.push({
        title: "Transfer",
        path: "/agency-management/transfer"
      })
    }
    if (userLevel === "SMA" && role !== "READ_ONLY") AgencyManagementFields.push({
      title: "Notifications",
      path: "/agency-management/notifications"
    })

    return AgencyManagementFields
  }


  const getReportsFields = () => {

    if (role === "READ_ONLY") {
      return [
        {
          title: "P&L Report by Market",
          path: "/reports/pnl-by-market"
        },
        {
          title: "P&L Report by Agent",
          path: "/reports/pnl-by-agency"
        },
        {
          title: "Casino Report",
          path: "/reports/casino-report"
        },
        {
          title: "Bet List",
          path: "/reports/bet-list"
        },
        {
          title: "Void and Invalid Report",
          path: "/reports/voidInvalid-report"
        }
      ]
    } else {
      return [
        {
          title: "P&L Report by Market",
          path: "/reports/pnl-by-market"
        },
        {
          title: "P&L Report by Agent",
          path: "/reports/pnl-by-agency"
        },
        {
          title: "Casino Report",
          path: "/reports/casino-report"
        },
        {
          title: "Bet List",
          path: "/reports/bet-list"
        },
        {
          title: "Transfer Statement",
          path: "/reports/transfer-statement"
        },
        {
          title: "Void and Invalid Report",
          path: "/reports/voidInvalid-report"
        },
        {
          title: 'P & L Summary Report',
          path: '/reports/pnl-summary-report'
        }
      ]
    }
  }

  const getAccountFields = () => {

    if (role === "READ_ONLY") {
      return [];
    } else if (sessionStorage.getItem('myUserName')?.includes('MOGAMBO')) {
      return [
        {
          title: "Balance",
          path: "/account/balance"
        },
        {
          title: "Statement",
          path: "/account/statement#pnl-statement"
        },
        {
          title: 'Account Summary',
          path: '/account/ac-summary'
        }
      ]
    } else {
      return [
        {
          title: "Balance",
          path: "/account/balance"
        },
        {
          title: "Statement",
          path: "/account/statement#pnl-statement"
        },
      ]
    }
  }


  return [
    {
      title: "Agency Management",
      subMenu: getAgencyManagementFields()
    },
    {
      title: "Risk Management",
      subMenu: [
        {
          title: "Net Exposure",
          path: "/risk-management/net-exposure"
        },
        {
          title: "Bet Ticker",
          path: "/risk-management/bet-ticker"
        },
        {
          title: "Event Management",
          path: "/risk-management/event-management"
        }
      ]
    },
    {
      title: "Reports",
      subMenu: getReportsFields()
    },
    {
      title: "Account",
      subMenu: getAccountFields()
    },
    // {
    //   title: "Payment Management",
    //   subMenu: [
    //     {
    //       title: "Deposit entry",
    //       path: "/payment-management/deposit-entry"
    //     },
    //     {
    //       title: "Reports",
    //       path: "/payment-management/reports"
    //     }
    //   ]
    // }
  ]
};
