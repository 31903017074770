import { useEffect, useState, Fragment } from "react"
import {Table } from "react-bootstrap"
import { useAuth } from "../../../Providers/AuthProvider"
import "./index.scss"
import { useStorage } from "../../../Providers/StorageProvider"
import CurrentTime from "../../../components/CurrentTime"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import moment from "moment/moment"
import DateTimeRangePicker from "../../../components/DateTimeRangePicker"
import RadioCustom from "../../../components/RadioCustom"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import ButtonCustom from "../../../components/ButtonCustom"
import { useHistory } from "react-router-dom"
import MemberBreakdown from "../../StatementPage/MemberBreakdown"

export default ({ userId, userData }) => {
  const [ bettingData, setBettingData ] = useState(null);
  const [ openDetails, setOpenDetails ] = useState(null);
  const [ allBettingData, setAllBettingData ] = useState({});
  const [ sports, setSports ] = useState([]);
  const { requestsToApi } = useAuth()
  // const { setIsLoading } = useStorage();
  const { userName,setIsLoading } = useStorage();

  const params = useParams()
  const history = useHistory()
  const now = new Date()
  const end = moment(now).add(1, "days").subtract(1, "seconds")
  const start = moment(now).subtract(14, "days")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: start,
    end: end
  })
  const [bettingObject, setBettingObject] = useState(null);

  // console.log("bettingData", bettingData)
  // console.log("sports", sports)
  // console.log("bettingObject", bettingObject)
  // console.log("allBettingData", allBettingData)

  useEffect(() => {
    if (!bettingData) {
      return;
    }
    const temp = {}
    bettingData.data.forEach((d) => {
      const key = moment(d.settledTime).format("DD/MM/YYYY");
      const value = temp[key];
      if (value) {
        value.push(d);
      } else {
        temp[key] = [d]
      }
    });
    Object.keys(temp).sort((a, b) => b[0] - a[0])
    setBettingObject( temp )
  }, [bettingData]);

  const getData = async () => {
    try {
      setIsLoading(true)
      const response = await requestsToApi.get(
        // `agency/IS010101/report/member-pnl?memberCode=IS010101M2P&from=${dateTimeRange.start.valueOf()}&to=${dateTimeRange.end.valueOf()}&orderStatus=settled`
        `agency/${userName}/report/member-pnl?memberCode=${params?.id}&from=${dateTimeRange.start.valueOf()}&to=${dateTimeRange.end.valueOf()}&orderStatus=settled`
      )
      const data = response.data.result;
      const allSports = Object.keys(data).sort((a,b) =>  data[a].order - data[b].order).map(one => {return {
        name: data[one].name,
        id: one,
      }} )

      setAllBettingData(data)
      setSports(allSports)
      setBettingData(data[allSports[0].id])
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  };

  useEffect(async () => {
    getData()
  }, [])

  const radioToggle = key => {
    setBettingData(allBettingData[key.id])
    setOpenDetails(null)
  }

  return (
    <div className="bettingPL-page">
      <div className="page-header">
        <div className="page-header_search">
          <DateTimeRangePicker dateTimeRange={dateTimeRange} onChangeFunc={setDateTimeRange} />
          <ButtonCustom color="green" onClick={() => {getData(); setOpenDetails(null)} }>Search</ButtonCustom>
        </div>
        <div className="bettingPL-page__radios">
          {!!sports?.length && sports.map((one, index) =>
            <div className="bettingPL-page__radios-content">
              <RadioCustom key={one.id} name="category" status="All" type="green" checked={index === 0} onChange={() => radioToggle(one)} >
                {one.name}
              </RadioCustom>
              <div className="bettingPL-page__radios-content-pnl">
                <StringToRecolorNumber>{allBettingData[one.id].totalPnl}</StringToRecolorNumber>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bettingPL-page__content">
        {
          !openDetails ?
          <>
            { bettingData && bettingObject && Object.keys(bettingObject)?.length ? (
              <>
        <p className="total-pnl">
          {"Total P&L: "}
          <StringToRecolorNumber>{Object.keys(allBettingData).reduce((acc,item) => acc + allBettingData[item].totalPnl ,0 )}</StringToRecolorNumber>
        </p>
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th scope="col">Market</th>
                <th scope="col">Start Time</th>
                <th scope="col">Settle Time</th>
                <th scope="col">Comm</th>
                <th scope="col">Net Win</th>
              </tr>
            </thead>
            <tbody>
              { Object.keys(bettingObject)
                .sort((a,b) => moment(b,"DD/MM/YYYY").valueOf() -  moment(a,"DD/MM/YYYY").valueOf())
                .map((key) => (
                <Fragment key={key}>
                  <tr>
                    <td colSpan="5" style={{ backgroundColor: "#7E747F", paddingLeft: "40px", position: "sticky", top: "35px" }}>
                      {key}
                    </td>
                  </tr>
                  {bettingObject[key]?.map((row, index) => (
                    <tr key={index}>
                      <td scope="col">
                        <a className="no-href"
                                 onClick={() => setOpenDetails(row)}
                        >
                        {row?.eventName} - {row?.marketName}
                        </a>
                      </td>
                      <td className="td_time" scope="col">
                        {<CurrentTime format="HH:mm:ss">{row?.marketTime}</CurrentTime>}
                      </td>
                      <td className="td_time" scope="col">
                        {<CurrentTime format="HH:mm:ss">{row?.settledTime}</CurrentTime>}
                      </td>
                      <td scope="col">{row?.memberComm}</td>
                      <td scope="col">
                        <StringToRecolorNumber>{row?.pnl}</StringToRecolorNumber>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>
              </>
        ) : (
          <>There have been no data in the last 14 days</>
        )}
          </>
          :
          <MemberBreakdown
            userId={userId}
            dateTimeRange={dateTimeRange}
            goBack={() => setOpenDetails(null)}
            openedMarket={openDetails?.marketId}
            prevPageData={null}
            isBetting={true}
            isLiveCasino={openDetails.eventName === "Live Casino"}
            commission={openDetails?.memberComm}
          />
        }
      </div>
    </div>
  )
}
