import { Dropdown } from 'react-bootstrap'
import { numInputFormat } from '../../utils/validation';
import './index.scss'

export default ({ title = "Option", fromValue, onChangeFromValue, toValue, onChangeToValue }) => {
  const toggleValue = (!fromValue && !toValue) ? "All" : "Custom"
  const changeInputValueToggle = (value, parentFunc) => {
    parentFunc(numInputFormat(value))
  }

  return (
    <Dropdown className='dropdown-range'>
      <Dropdown.Toggle data-title={`Option: ${toggleValue}`}>
        {title}: {toggleValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div >
          <label htmlFor="range__from" >From</label>
          <input id="range__from" onChange={(e) => changeInputValueToggle(e.target.value, onChangeFromValue)} value={fromValue}></input>
        </div>
        <div>
          <label htmlFor="range__to">To</label>
          <input id="range__to" onChange={(e) => changeInputValueToggle(e.target.value, onChangeToValue)} value={toValue}></input>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}