import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../Providers/HelpProvider";
import Img1 from '../../../static/images/help/menu_reports.png'

import HelpMenuLink from "../../../components/HelpMenuLink";

export default () => {
  const { userLevel } = useHelpStorage();
  const descendantsOneByOne = `${userLevel === "sma" ? "MA, " : ""}
    ${userLevel !== "agent" ? "Agent or " : ""}Member`;
  const descendantsMany = `${userLevel === "sma" ? "MAs, " : ""}
    ${userLevel !== "agent" ? "Agents or " : ""}Members`;
  return <div>
    <h1>Reports</h1>
    <div className="body">

      <p className="shortdesc">This section describes the reports that are available in the site .</p>

      <p>Reports can help you find out more about your performance and that of your {descendantsMany} and about each market your downline users are engaged with.</p>

      <p className="last-p">You can view the reports by clicking the <strong>Reports</strong> menu in the menubar on the left.</p>

      <Image src={Img1} />

      <p className="last-p">
        Select the <HelpMenuLink strong>P&L Report by Market</HelpMenuLink>
                &nbsp;option to find out about your performance and that of your {descendantsMany} for a market in a given time period.<br />
                Select the <HelpMenuLink strong>P&L Report by Agent</HelpMenuLink><br />
                &nbsp;option to find out about the aggregated performance of a specific {descendantsOneByOne} on all available markets in a given time period.<br />
                Select the <HelpMenuLink strong>Bet List</HelpMenuLink> option to list all the bets the Members have placed in a given time period.<br />
                Select the <strong>Transfer Statement</strong> option to display all incoming or outgoing transfers that have taken place in the last 60 days.<br />
        <HelpMenuLink strong>P&L Report by Market</HelpMenuLink><br />
                This section describes how to get more information about your performance and that of your {descendantsMany} for a market in a given time period.<br />
        <HelpMenuLink strong>P&L Report by Agent</HelpMenuLink><br />
                This section describes how to get more information about the aggregated performance of a specific {descendantsOneByOne} on all available markets in a given time period.<br />
        <HelpMenuLink strong>Bet List</HelpMenuLink><br />
                This section describes how to list all the bets the Members have placed in a given period of time.<br />
      </p>
    </div>
  </div>
}