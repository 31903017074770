import "./index.scss"
import axios from "axios"
import PaginationCustom from "../../../components/PaginationCustom"
import { Table } from "react-bootstrap"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { numberWithCommas } from "../../../utils/dataTransform"
import { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useStorage } from "../../../Providers/StorageProvider"
import countSums from "./countSums"
import { sportOptionsId, marketOptionsId } from "../../BetTickerPage/betTickerData"
import CurrentTime from "../../../components/CurrentTime"

export default ({ dateTimeRange, filtersOptions, casinoToggle }) => {
  const [activePageNumber, setActivePageNumber] = useState(1)
  const [alert, setAlert] = useState({
    isDisplayed: false,
    message: "There is no data for selected filters on this date."
  })
  const history = useHistory()
  const { userName, userLevel,setIsLoading } = useStorage()
  const [responseData, setResponseData] = useState(null)
  let downlineCols = ["member", "agent", "ma", "sma"]
  if (userLevel === "Member") downlineCols = downlineCols.slice(0, 1)
  else if (userLevel === "Agent") downlineCols = downlineCols.slice(0, 2)
  else if (userLevel === "MA") downlineCols = downlineCols.slice(0, 3)

  useEffect(async () => {
    try {
      setAlert({ ...alert, isDisplayed: false })
      setIsLoading(true)
      const url = casinoToggle
        ? "agency/" +
        userName +
        "/report/market-pnl?&from=" +
        dateTimeRange.start.valueOf() +
        "&to=" +
        dateTimeRange.end.valueOf() +
        (casinoToggle === "xg"
          ? "&sportId=1444000&marketType=" +
          (filtersOptions.marketTypeDropdown === "All" ? "ALL" : "WIN_ONLY") +
          (filtersOptions.sportDropdown === "All" ? "" : "&eventName=Texas%20Hold%27em")
          : "&sportId=77777") +
        (filtersOptions.IDInput ? "&eventId" + filtersOptions.IDInput : "") +
        (filtersOptions.nameInput
          ? `&searchUserCode=${filtersOptions.userCode}&searchUserLogin=${filtersOptions.nameInput}`
          : "") +
        "&whiteLabelSkinId=allSkins" +
        "&providerId=-1" + //?
        "&selectedPage=" +
        activePageNumber //?
        : "agency/" +
        userName +
        "/report/market-pnl?&from=" +
        dateTimeRange.start.valueOf() +
        "&to=" +
        dateTimeRange.end.valueOf() +
        "&marketType=" +
        marketOptionsId.filter(item => item.title === filtersOptions.marketTypeDropdown)[0].id +
        "&sportId=" +
        sportOptionsId.filter(item => item.title === filtersOptions.sportDropdown)[0].id +
        "&whiteLabelSkinId=allSkins" +
        (filtersOptions.eventInput ? "&eventName=" + filtersOptions.eventInput : "") +
        (filtersOptions.userCode ? "&searchUserCode=" + filtersOptions.userCode : "") +
        "&providerId=-1&selectedPage=" +
        activePageNumber
      const response = await axios.get(process.env.REACT_APP_API_URL + url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("accessToken")
        }
      })
      if (response.data.result.rows && response.data.result.rows.length) setAlert({ ...alert, isDisplayed: false })
      else setAlert({ ...alert, isDisplayed: true })
      const countedDataRows = countSums(response.data.result.rows)
      setResponseData({ ...response.data.result, rows: countedDataRows })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log("error", e)
    }
  }, [dateTimeRange, activePageNumber, filtersOptions, casinoToggle])

  const formatData = (num, recolor = true) => {
    return recolor ? <StringToRecolorNumber>{num}</StringToRecolorNumber> : <span>{numberWithCommas(num)}</span>
  }

  const followTheLink = href => () => {
    history.push(history.location.pathname + href)
    setResponseData(null)
  }
  const pagination = (
    <PaginationCustom
      activePage={activePageNumber}
      changeActivePage={setActivePageNumber}
      totalPages={responseData?.totalPages}
    />
  )

  return (
    <div className="pnl-report">
      <div className="date-nav">
        {responseData ? (
          <>
            <p>{<CurrentTime format="DD/MM/YYYY">{responseData.selectedDate}</CurrentTime>}</p>
            {pagination}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="main-report-content">
        {responseData ? (
          <Table striped hover variant="dark">
            <thead>
              <tr>
                <th className="border_right-side"></th>
                <th className="border_right-side" colSpan="4">
                  MEMBER
                </th>
                <th className="border_right-side" colSpan="3">
                  AGENT
                </th>
                {userLevel === "SMA" || userLevel === "MA" ? (
                  <th className="border_right-side" colSpan="3">
                    MA
                  </th>
                ) : (
                  <></>
                )}
                {userLevel === "SMA" ? (
                  <th className="border_right-side" colSpan="3">
                    CUS
                  </th>
                ) : (
                  <></>
                )}
                <th>UPLINE</th>
              </tr>
              <tr>
                <th className="border_right-side"></th>
                <th>T/O</th>
                <th>Win</th>
                <th>Comm</th>
                <th className="border_right-side">P&L</th>
                <th>Win</th>
                <th>Comm</th>
                <th className="border_right-side">P&L</th>
                {userLevel === "SMA" || userLevel === "MA" ? (
                  <>
                    <th>Win</th>
                    <th>Comm</th>
                    <th className="border_right-side">P&L</th>
                  </>
                ) : (
                  <></>
                )}
                {userLevel === "SMA" ? (
                  <>
                    <th>Win</th>
                    <th>Comm</th>
                    <th className="border_right-side">P&L</th>
                  </>
                ) : (
                  <></>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {responseData?.grandTotal ? (
                <tr>
                  <th className="border_right-side">Grand Total</th>
                  <td>{formatData(responseData.grandTotal.member.turnover, false)}</td>
                  {downlineCols.map(item => {
                    return [
                      ["win", "comm"].map(param => (
                        <td key={Math.random()}>{formatData(responseData.grandTotal[item][param])}</td>
                      )),
                      <td key={Math.random()} className="border_right-side">
                        {formatData(responseData.grandTotal[item].pnl)}
                      </td>
                    ]
                  })}
                  <td>{formatData(responseData.grandTotal.uplineWin)}</td>
                </tr>
              ) : (
                <tr>
                  <th className="border_right-side">Grand Total</th>
                  <td />
                  <td />
                  <td className="-positive">0.00</td>
                  <td className="border_right-side" />
                  <td />
                  <td />
                  <td className="border_right-side" />
                  <td />
                  <td />
                  <td className="border_right-side" />
                  <td />
                  <td />
                  <td className="border_right-side" />
                  <td />
                </tr>
              )}
              {responseData?.rows && !!responseData?.rows.length ? (
                <>
                  <tr className="delimiter-row">
                    <td className="delimiter-td text-align-left" colSpan="15">
                      {<CurrentTime format="DD/MM/YYYY">{responseData.selectedDate}</CurrentTime>}
                    </td>
                  </tr>
                  {responseData.rows.map(event => {
                    return [
                      <tr key={Math.random()}>
                        <td
                          colSpan="15"
                          className={
                            "event-name text-align-left " +
                            (history.location.pathname.includes("casino-report") ? "" : "recolor")
                          }
                        >
                          {event.competitionName + " - " + event.name}
                        </td>
                      </tr>,
                      event.market.map(market => {
                        return (
                          <tr key={Math.random()}>
                            {market.name ? (
                              <td className="market-name border_right-side text-align-left">
                                <span>{market.name}</span>
                                <span>{" | "}</span>
                                <a className="no-href" onClick={followTheLink("/bet-breakdown/" + market.marketId)}>
                                  View Bets
                                </a>
                              </td>
                            ) : (
                              <th className="border_right-side">Total</th>
                            )}
                            <td>{formatData(market.member.turnover, false)}</td>
                            {downlineCols.map(item => (
                              <Fragment key={item}>
                                <td>{formatData(market[item].win)}</td>
                                <td>{formatData(market[item].comm)}</td>
                                <td className="border_right-side">{formatData(market[item].pnl)}</td>
                              </Fragment>
                            ))}
                            <td>{formatData(market.uplineWin)}</td>
                          </tr>
                        )
                      })
                    ]
                  })}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        ) : <p>There is no data for selected filters.</p>}
        {alert.isDisplayed ? (
          <div className="pnl-by-market__alert">
            <span>{alert.message}</span>
          </div>
        ) : (
          <></>
        )}
      </div>

      {responseData ? (
        pagination
      ) : (
        <></>
      )}
    </div>
  )
}
