import { Image } from "react-bootstrap";
import { useHelpStorage } from "../../../../Providers/HelpProvider";
import Img1Agent from '../../../../static/images/help/menu_member_listing_agent.png'
import Img1MA from '../../../../static/images/help/menu_agent_listing_ma.png'
import Img1SMA from '../../../../static/images/help/menu_ma_listing_sma.png'
import Img2Agent from '../../../../static/images/help/button_new_member_agent.png'
import Img2MA from '../../../../static/images/help/button_new_agent_ma.png'
import Img2SMA from '../../../../static/images/help/button_new_ma_sma.png'
import Img3Agent from '../../../../static/images/help/popup_create_new_member.png'
import Img3MA from '../../../../static/images/help/popup_create_new_agent.png'
import Img3SMA from '../../../../static/images/help/popup_create_new_ma.png'
import Img4 from '../../../../static/images/help/create_downline_credit.png'
import Img5Agent from '../../../../static/images/help/position_taking_agent.png'
import Img5MA from '../../../../static/images/help/position_taking_agent_max.png'
import Img5SMA from '../../../../static/images/help/position_taking_ma_max.png'
import Img6 from '../../../../static/images/help/notes.png'
import Img7Agent from '../../../../static/images/help/member_listing_agent.png'
import Img7MA from '../../../../static/images/help/agent_listing_ma.png'
import Img7SMA from '../../../../static/images/help/ma_listing_sma.png'
import HelpMenuLink from "../../../../components/HelpMenuLink";

export default () => {
  const { childrenLevel, userLevel } = useHelpStorage()

  return <div>
    <h1>Creating a New {childrenLevel} Account</h1>
    <div className="body">

      <p className="shortdesc">This section describes how to create an account for a {childrenLevel}.</p>

      <p>Creating a new {childrenLevel} is a quick and easy process in the site. You can edit&nbsp;
                additional user options, once you have created the new agency user.</p>
      <p>To create a new {childrenLevel}, follow this procedure:</p>

      <p className="last-p">Navigate to the <strong>Agency Management</strong> &gt;&nbsp;
            <strong>{childrenLevel} Listing</strong> menu item and click the <strong>New {childrenLevel}</strong> button.</p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />
      <br />
      <Image src={userLevel === "agent" ? Img2Agent :
        userLevel === "ma" ? Img2MA : Img2SMA} />

      <p className="last-p">The <strong>Username</strong> field in the <strong>Information</strong>
            &nbsp;section is already filled in automatically, but you can change this if you wish.&nbsp;
            Enter a login name and a password in the <strong>Information</strong> section. Also, set the&nbsp;
            user status to <strong>Active</strong> or <strong>Inactive</strong>.</p>

      <Image src={userLevel === "agent" ? Img3Agent :
        userLevel === "ma" ? Img3MA : Img3SMA} />

      <p className="last-p">
        <strong>Note:</strong> Inactive Agents cannot log in to the site.<br />
        <strong>Tip:</strong><br />
                Fill in all available username fields using letters or numbers only.<br />
                A login name must be at least four characters consisting of lower case letters, numbers and dots (<kbd>.</kbd>).
                Other special characters are not allowed.<br />
                Passwords must be between 6 and 15 characters and must contain at least one number and one letter.
                Special characters are not allowed.<br />
                In the <strong>Credit</strong> section, enter the credit limit that the new {childrenLevel} can
                &nbsp;distribute among their downline users in the <strong>Credit</strong> Limit field.
            </p>

      <Image src={Img4} />

      <p className="last-p">
        <strong>Note:</strong> When setting up the credit limit for an {childrenLevel}, the credit that you set in the the&nbsp;
                <strong>Credit Limit</strong> field will be transferred from your balance to theirs.
                Therefore, you must make sure that you have enough credit on your balance to transfer from.<br />
                Set up Position Taking by filling in the <strong>
          {userLevel === "sma" ? "CUS" :
            userLevel === "ma" ? "MA" : "Agent"} Position (%)</strong> field that applies to you
                {childrenLevel !== "Member" ?
          <span> as well as the <strong>{childrenLevel} Max Position (%)</strong> field that applies to the new {childrenLevel} you are creating</span> : ""}.
                For more information on Position Taking, see <HelpMenuLink>Position Taking</HelpMenuLink>.
            </p>

      <Image src={userLevel === "agent" ? Img5Agent :
        userLevel === "ma" ? Img5MA : Img5SMA} />

      <p className="last-p">You can add a note about the {childrenLevel} you are creating in the <strong>Notes</strong> section.</p>

      <Image src={Img6} />

      <p className="last-p">
        Click the <strong>Create</strong> button to create the new {childrenLevel}.<br />
        <strong>Note:</strong> You can edit additional user options by navigating to the
                <strong>Agency Management</strong> &gt; <strong>{childrenLevel} Listing</strong>
                &nbsp;menu item and clicking the <strong>Login Name</strong> or the <strong>ID</strong> of the {childrenLevel}.
            </p>

      <Image src={userLevel === "agent" ? Img1Agent :
        userLevel === "ma" ? Img1MA : Img1SMA} />

      <br />

      <Image src={userLevel === "agent" ? Img7Agent :
        userLevel === "ma" ? Img7MA : Img7SMA} />

      <p className="last-p">
        <strong>Parent topic:</strong> <HelpMenuLink>{`${childrenLevel} Management`}</HelpMenuLink><br />
        <strong>Related tasks</strong><br />
        <HelpMenuLink>{`Changing the Credit Limit for an ${childrenLevel} Account`}</HelpMenuLink><br />

      </p>
    </div>
  </div>
}