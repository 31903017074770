import "./index.scss"
import RadioCustom from "../../components/RadioCustom"
import { useEffect, useState } from "react"
import DateTimeRangePicker from "../../components/DateTimeRangePicker"
import ButtonCustom from "../../components/ButtonCustom"
import FilterIcon from "mdi-react/FilterIcon"
import { defaultFilterValues } from "./casinoReportData"
import ViewCasinoBets from "./ViewCasinoBets"
import moment from "moment"
import { useHistory } from "react-router-dom"
import Filters from "./filters"
import PnlReport from "../PnLByMarketPage/PnlReport"
import { useStorage } from "../../Providers/StorageProvider"

export default () => {
  const history = useHistory()
  const { userLevel } = useStorage()
  const isMaster = userLevel !== "Member"
  const [isShowFilters, setIsShowFilters] = useState(false)
  const [filtersOptions, setFilterOptions] = useState(defaultFilterValues)
  const [casinoToggle, setCasinoToggle] = useState("xg")
  const now = new Date()
  const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
  const [dateTimeRange, setDateTimeRange] = useState({
    start: startOfToday,
    end: endOfToday
  })
  // const [alert, setAlert] = useState({
  //     isDisplayed: false,
  //     message: "There is no data for selected filters."
  // })

  useEffect(() => {
    setFilterOptions(defaultFilterValues)
  }, [casinoToggle])

  const radioToggle = e => {
    setCasinoToggle(e.target.attributes["status"].value)
    setFilterOptions(defaultFilterValues)
  }

  const showFilters = () => setIsShowFilters(!isShowFilters)

  return (
    <div className="casino-report">
      <div className="page-header">
        <div className="page-header__main">
          <div>
            <div>
              <h1>Casino Report</h1>
              {!history.location.pathname.includes("/bet-breakdown") ? (
                <>
                  <DateTimeRangePicker
                    dateTimeRange={dateTimeRange}
                    onChangeFunc={result => setDateTimeRange(result)}
                  />
                  {isMaster ? (
                    <>
                      <input
                        type="checkbox"
                        onChange={showFilters}
                        id="casino-report__filter"
                        style={{ display: "none" }}
                      />
                      <label className="filter__toggle" htmlFor="casino-report__filter">
                        <FilterIcon
                          size={20}
                          className={
                            isShowFilters ? "active" : ""
                          }
                        />
                      </label>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {isMaster && !history.location.pathname.includes("/bet-breakdown") ? (
              <div className="casino-report__radios">
                <RadioCustom name="category" status="xg" type="green" onChange={radioToggle} checked>
                  XG
                </RadioCustom>
                <RadioCustom name="category" status="live casino" type="green" onChange={radioToggle}>
                  Live Casino
                </RadioCustom>
              </div>
            ) : (
              <></>
            )}
          </div>

          {isMaster ? (
            <ButtonCustom className="refresh__button" color="cyan">
              Download CSV
            </ButtonCustom>
          ) : (
            <></>
          )}
        </div>
        <Filters
          updateOptions={newOptions => {
            setFilterOptions(newOptions)    
          }}
          options={filtersOptions}
          casinoToggle={casinoToggle}
          resetOptions={() => {
            setFilterOptions(defaultFilterValues)
          }}
          className={isShowFilters ? "" : "invisible"}
          isPnl
        />
      </div>
      {/* {alert.isDisplayed ? <div className="casino-report__alert">
            <span>{alert.message}</span>
        </div> : <></>} */}

      <div className="casino-report__main">
        {history.location.pathname.includes("bet-breakdown") ? (
          <ViewCasinoBets />
        ) : (
          <PnlReport dateTimeRange={dateTimeRange} filtersOptions={filtersOptions} casinoToggle={casinoToggle} />
        )}
      </div>
    </div>
  )
}
