import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { getPathOfLink } from "./utils";
import { useHelpStorage } from "../../Providers/HelpProvider";


export default ({ children, title = children, value = title, strong }) => {
  const { childrenLevel, userLevel } = useHelpStorage()
  const history = useHistory();

  const followTheLink = (name) => {
    history.push(`/help/${userLevel}${getPathOfLink(childrenLevel, name)}`)
  }

  return <a className="no-href" style={strong ? { fontWeight: "700" } : null} onClick={() => followTheLink(value)}>{title}</a>
}