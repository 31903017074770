export default (rows) => rows.map((row) => {
  const rowWithSum = row.market.reduce((previousValue, market, index, arr) => {
    const newValue = {
      agent: {
        win: previousValue.agent.win + market.agent.win,
        comm: previousValue.agent.comm + market.agent.comm,
        pnl: previousValue.agent.pnl + market.agent.pnl
      },
      ma: {
        win: previousValue.ma.win + market.ma.win,
        comm: previousValue.ma.comm + market.ma.comm,
        pnl: previousValue.ma.pnl + market.ma.pnl
      },
      member: {
        win: previousValue.member.win + market.member.win,
        comm: previousValue.member.comm + market.member.comm,
        pnl: previousValue.member.pnl + market.member.pnl,
        turnover: previousValue.member.turnover + market.member.turnover
      },
      sma: {
        win: previousValue.sma.win + market.sma.win,
        comm: previousValue.sma.comm + market.sma.comm,
        pnl: previousValue.sma.pnl + market.sma.pnl
      },
      uplineWin: previousValue.uplineWin + market.uplineWin
    }
    if (arr.length !== (index + 1)) return { ...newValue }
    else return [...arr, newValue]
  }, {
    agent: { win: 0, comm: 0, pnl: 0 },
    ma: { win: 0, comm: 0, pnl: 0 },
    member: { win: 0, comm: 0, pnl: 0, turnover: 0 },
    sma: { win: 0, comm: 0, pnl: 0 },
    uplineWin: 0
  })

  return { ...row, market: rowWithSum }
})