import './index.scss'

export default ({ activePage = 1, changeActivePage, totalPages = 20 }) => {

  return (
    <div className="pagination-main">
      <div className="pagination-main__button first-page"/>
      <div className="pagination-main__button prev-page"/>
      <div className="pagination-main__pages">
        <span>{activePage}</span>
        <span>/{totalPages}</span>
      </div>
      <div className="pagination-main__button next-page"/>
      <div className="pagination-main__button last-page"/>
    </div>
  )
}
