import "./QueenPL.scss";
import React, { useEffect, useState } from 'react'
import winnerCup from "../../../static/images/Trophy.svg"

const QueenPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const getSumOfCards = (cards, bonusScore) => {
    let sum = 0;
    for (const card of cards) {
      sum += parseInt(card.substring(1));
    }
    if (bonusScore) {
      sum = sum + bonusScore;
      return sum;
    } else {
      return sum;
    }
  }

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="queen-betting">
        <div className="queen-betting__games">
          <div className="queen-betting__games-item-row">
            <div className="queen-betting__games-item">
              <p className="queen-betting__games-item-title">
                Total 0
                <span className={`${(roundMarkets[0]?.marketRunner[0]?.cards.length !== 0) ? "points-value" : ""}`}>
                  - {getSumOfCards(roundMarkets[0]?.marketRunner[0]?.cards, 0)}
                </span>
              </p>
              <div className="queen-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.marketRunner[0].cards.map(card => <img className="queen-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
              </div>
              {(roundMarkets[0]?.marketRunner[0].status === 'WINNER') && <img className="queen-betting__games-item-cup rightCup" src={winnerCup} alt="cup" />}
            </div>
            <div className="queen-betting__games-item">
              <p className="queen-betting__games-item-title">
                Total 1
                <span className={`${(roundMarkets[0]?.marketRunner[1]?.cards.length !== 0) ? "points-value" : ""}`}>
                  - {getSumOfCards(roundMarkets[0]?.marketRunner[1]?.cards, 1)}
                </span>
              </p>
              <div className="queen-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.marketRunner[1].cards.map(card => <img className="queen-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
              </div>
              {(roundMarkets[0]?.marketRunner[1].status === 'WINNER') && <img className="queen-betting__games-item-cup" src={winnerCup} alt="cup" />}
            </div>
          </div>
          <div className="queen-betting__games-item-row">
            <div className="queen-betting__games-item">
              <p className="queen-betting__games-item-title">
                Total 2
                <span className={`${(roundMarkets[0]?.marketRunner[2]?.cards.length !== 0) ? "points-value" : ""}`}>
                  - {getSumOfCards(roundMarkets[0]?.marketRunner[2]?.cards, 2)}
                </span>
              </p>
              <div className="queen-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.marketRunner[2].cards.map(card => <img className="queen-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
              </div>
              {(roundMarkets[0]?.marketRunner[2].status === 'WINNER') && <img className="queen-betting__games-item-cup rightCup" src={winnerCup} alt="cup" />}
            </div>
            <div className="queen-betting__games-item">
              <p className="queen-betting__games-item-title">
                Total 3
                <span className={`${(roundMarkets[0]?.marketRunner[3]?.cards.length !== 0) ? "points-value" : ""}`}>
                  - {getSumOfCards(roundMarkets[0]?.marketRunner[3]?.cards, 3)}
                </span>
              </p>
              <div className="queen-betting__games-item-cards">
                {roundMarkets && roundMarkets[0]?.marketRunner[3].cards.map(card => <img className="queen-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
              </div>
              {(roundMarkets[0]?.marketRunner[3].status === 'WINNER') && <img className="queen-betting__games-item-cup" src={winnerCup} alt="cup" />}
            </div>
          </div>
        </div>
        <div className="queen-betting__info-column">
          <div className="queen-betting__info-row">
            <div className="queen-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="queen-betting__info-row-results">
              <span>{winnerData && winnerData['Match Odds']  || 'No Winner'}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default QueenPL;

