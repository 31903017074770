import { useEffect, useState, Fragment, useCallback } from "react"
import { positiveOrNegative, filterElements } from "../../utils/positiveOrNegative"
import { Table } from "react-bootstrap"
import ButtonCustom from "../../components/ButtonCustom"
import RadioCustom from "../../components/RadioCustom"
import { useAuth } from "../../Providers/AuthProvider"
import { useStorage } from "../../Providers/StorageProvider"
import { sportOptionsId } from "../BetTickerPage/betTickerData"
import PlayCircleIcon from "mdi-react/PlayCircleIcon"
import SpeedometerIcon from "../../static/images/help/speedometerIcon.svg"
import LadderIcon from "../../static/images/help/Vector.svg"
import ImgBetBreakdown from "../../static/images/help/icon_bet_breakdown.png"
import "./index.scss"
// import CurrentTime from "../../components/CurrentTime"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import Reload from "../../static/images/help/reload.svg"
import Icon_downline from "../../static/images/help/icon_downline.svg"
import moment from "moment"
import MarketBreakdown from "./MarketBreakdown"
import WorkStation from "./WorkStation/index"
import Downline from "./Downline/index"

export default ({ targetUser }) => {
  const { requestsToApi } = useAuth()
  const { userName, userLevel, setIsLoading } = useStorage()
  const [timerValue, setTimerValue] = useState(8)
  const [resData, setResData] = useState(null)
  const [groupRadio, setGroupRadio] = useState("My PT")
  const [sportRadio, setSportRadio] = useState("All")
  const [breakdownTarget, setBreakdownTarget] = useState(null)
  const [WorkstationTarget, setWorkstationTarget] = useState(null)
  const [downline, setDownline] = useState(null)
  const [ladder, setLadder] = useState(false)
  const BetBreakdownIcon = ({ marketId, className = "transform-rotate" }) => (
    <div title="Bet breakdown" style={{ backgroundColor: "#373d73" }} onClick={() => setBreakdownTarget(marketId)}>
      <img style={{ width: 16 }} src={ImgBetBreakdown} alt="" className={className} />
    </div>
  )

  const getNetExposureDataReq = useCallback(async () => {
    try {
      let response = await requestsToApi.get(
        "agency/" +
        userName +
        "/risk-mgmt/" + (targetUser ? 'net-exposure-member' : 'net-exposure') +
        (sportRadio !== "All" || targetUser ? "?" : "") +
        (targetUser ? `target=${targetUser.userId}` : "") +
        (sportRadio !== "All" ? `&sportTypeId=${sportOptionsId.filter(item => item.title === sportRadio)[0].id}` : "")
      )
      setIsLoading(false)
      setResData(response.data.result)
      setTimerValue(8)
    } catch (error) {
      setTimerValue(8)
      setIsLoading(false)
      console.log(error?.message)
    }
  }, [requestsToApi, userName, sportRadio])

  useEffect(() => {
    getNetExposureDataReq()
  }, [getNetExposureDataReq])

  useEffect(() => {
    let timer = null
    if (!timerValue) {
      getNetExposureDataReq()
    } else {
      timer = setTimeout(() => {
        setTimerValue(prev => prev - 1)
      }, 1500)
    }
    return () => clearTimeout(timer)
  }, [timerValue])


  const addCommas = nStr => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    if (Math.abs(+nStr) < 100) {
      let res = 0
      x.length > 1 ?
        res = x1 + x2.substring(0, 3) :
        res = x1 + '.00'
      return res
    }
    return x1
  }

  const outrightsTable = (selections) => {
    let row = []
    let i = 0
    let i2 = 0
    while (i < selections.length) {
      i2 = i + 1
      row.push(
        <tr key={Math.random()}>
          <td className="name blue-back" >
            {groupRadio === "My PT" && selections[i].selectionName}
          </td>
          <td align="center" className="stake">
            <span>
              {groupRadio === "My PT" ? addCommas(selections[i].stakes) : addCommas(selections[i].totalStakes)}
            </span>
          </td>
          <td align="center" className="under-no">
            <span className={(selections[i][groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"] >= 0 ? "-positive " : "-negative ")}>
              {addCommas(selections[i][groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"])}
            </span>
          </td>
          {i2 < selections.length ?
            <>
              <td className="name blue-back">
                {groupRadio === "My PT" && selections[i2].selectionName}
              </td>
              <td align="center" className="stake">
                <span>
                  {groupRadio === "My PT" ? addCommas(selections[i].stakes) : addCommas(selections[i].totalStakes)}
                </span>
              </td>
              <td align="center" className="under-no">
                <span className={(selections[i2][groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"] >= 0 ? "-positive " : "-negative ")}>

                  {addCommas(selections[i2][groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"])}
                </span>
              </td>
            </> :
            <> <td /><td /><td /></>}
        </tr>
      )
      i = i + 2
    }

    return row
  }

  return !breakdownTarget && !WorkstationTarget && !downline ? (
    <div className="net-exposure">
      <div className="page-header">
        <div className="page-header__left-items">
          <h1>Net Exposure </h1>
          <div>
            <div className="group-radios">
              {["My PT", "Total Book"].map(title => (
                <RadioCustom
                  key={title}
                  checked={groupRadio === title}
                  name="group"
                  onChange={() => setGroupRadio(title)}
                >
                  {title}
                </RadioCustom>
              ))}
            </div>
            <div className="sport-radios">
              <span className="sport-radios__label">Filter by Sport</span>
              {["All", "Cricket", "Football", "Tennis", "Horse Racing", "Greyhound Racing", "Other"].map(title => (
                <RadioCustom
                  checked={sportRadio === title}
                  name="sport"
                  type="green"
                  onChange={() => setSportRadio(title)}
                  key={title}
                >
                  {title}
                </RadioCustom>
              ))}
            </div>
          </div>
        </div>
        <div className="page-header__right-items">
          <div className="refresh">
            <div className="refresh__timer">
              <span>{timerValue}</span>
            </div>
            <ButtonCustom
              color="cyan"
              className="refresh__button"
              onClick={() => {
                getNetExposureDataReq()
              }}
            >
              <img src={Reload} style={{ marginRight: "5px" }} alt="" />
              Refresh
            </ButtonCustom>
          </div>
        </div>
      </div>
      <div className="net-exposure__main">
        {resData && (!!resData?.nonOutrights?.length) ? (
          <Table variant="dark">
            <thead>
              <tr>
                {["", "", "Stake", "1", "X", "2", "", "", "Stake", "Over/Yes", "Under/No"].map((title, index) => (
                  <th key={index} scope="col" className={index == 1 ? 'min-width-100px' : ''}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resData &&
                resData?.nonOutrights.map(item => (
                  <Fragment key={item.eventId}>
                    <tr className="event-title">
                      <td colSpan="11">
                        {item.eventName.replace(
                          "${HH:mm}",
                          moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))}
                        {item.isInPlay ? (
                          <span style={{ color: "#3cd247", marginLeft: "10px" }}>
                            <PlayCircleIcon size={20} />
                            In-Play
                          </span>
                        ) : moment(+item.eventTime)
                          .calendar()
                          .split(" ")[0] === "Tomorrow" ||
                          moment(+item.eventTime)
                            .calendar()
                            .split(" ")[0] === "Yesterday" ? (
                          <>
                            {moment(+item.eventTime)
                              .calendar()
                              .split(" ")[0] + " "}
                            {moment(+item.eventTime).format("HH:mm")}
                          </>
                        ) : moment(+item.eventTime)
                          .calendar()
                          .split(" ")[0] === "Today" ? (
                          <>
                            {" - "} {moment(+item.eventTime).format("HH:mm")}
                          </>
                        ) : (
                          <>
                            {" - "} {moment(+item.eventTime).format("HH:mm")}
                          </>
                        )}
                      </td>
                    </tr>
                    {item[
                      item?.otherMarkets?.length > item?.matchOddsMarkets?.length ? "otherMarkets" : "matchOddsMarkets"
                    ].map((_, index) => (
                      <tr key={index}>
                        {item.matchOddsMarkets[index] ? (
                          <>
                            <td className="market blue-back">
                              <span className="market__name">{item.matchOddsMarkets[index]?.marketName}</span>
                            </td>
                            <td className="td-buttons blue-back">
                              <div>
                                <div
                                  title="Workstation"
                                  onClick={() =>
                                    setWorkstationTarget({
                                      marketId: item.matchOddsMarkets[index].marketId,
                                      eventId: item.eventId
                                    })
                                  }
                                >
                                  <img src={SpeedometerIcon} alt="" />
                                </div>
                                <BetBreakdownIcon marketId={item.matchOddsMarkets[index].marketId} />
                                <div
                                  style={{ backgroundColor: "#2f3462" }}
                                  onClick={() => setDownline(item.matchOddsMarkets[index].marketId)}
                                >
                                  <img style={{ width: 16 }} src={Icon_downline} />
                                </div>
                              </div>
                            </td>
                            <td align="center" className="stake">
                              <span>
                                {groupRadio === "My PT"
                                  ? addCommas(item.matchOddsMarkets[index].stakes)
                                  : addCommas(item.matchOddsMarkets[index].totalStakes)
                                }
                              </span>
                            </td>
                            {filterElements(item.matchOddsMarkets[index].selections).map((el, i) => (
                              <td
                                align="center"
                                key={i}
                                className={`${positiveOrNegative(
                                  el?.[groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"]
                                )} ${!!!(i % 2) ? "over-yes" : "under-no"}`}
                              >
                                {el?.[groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"] ? (
                                  addCommas(
                                    el?.[groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"]
                                  )
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                            ))}
                          </>
                        ) : (
                          <td colSpan="6" />
                        )}
                        {item.otherMarkets[index] ? (
                          <>
                            <td className="market blue-back">
                              <div className="market__container-div">
                                <div>{item.otherMarkets[index]?.marketName}</div>
                                <div><span>{item.otherMarkets[index].marketType === "INNINGS_RUNS" ? '(Max Win/Exp.)' : ''}</span></div>
                              </div>
                            </td>
                            <td className="td-buttons blue-back">
                              <div>
                                <div
                                  title="Workstation"
                                  onClick={() =>
                                    setWorkstationTarget({
                                      marketId: item.otherMarkets[index].marketId,
                                      eventId: item.eventId
                                    })
                                  }
                                >
                                  <img src={SpeedometerIcon} alt="" />
                                </div>
                                {item.otherMarkets[index].marketType === "INNINGS_RUNS" && (
                                  <div title="Ladder">
                                    <img
                                      src={LadderIcon}
                                      onClick={() => {
                                        setDownline(item.otherMarkets[index].marketId)
                                        setLadder(true)
                                      }}
                                    />
                                  </div>
                                )}
                                <BetBreakdownIcon marketId={item.otherMarkets[index].marketId} />
                                <div
                                  style={{ backgroundColor: "#2f3462" }}
                                  onClick={() => setDownline(item.otherMarkets[index].marketId)}
                                >
                                  <img style={{ width: 16 }} src={Icon_downline} alt="" />
                                </div>
                              </div>
                            </td>
                            <td align="center" className="stake">
                              <span>
                                {
                                  groupRadio === "My PT"
                                    ? addCommas(item.otherMarkets[index].stakes)
                                    : addCommas(item.otherMarkets[index].totalStakes)
                                }
                              </span>
                            </td>
                            {[0, 1].map((el, i) => (
                              <td align="center" className={i % 2 === 0 ? "over-yes" : "under-no"} key={i}>
                                <span className={(item.otherMarkets[index].selections?.[el][
                                  groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"
                                ] >= 0 ? "-positive " : "-negative ")}>

                                  {
                                    addCommas(item.otherMarkets[index].selections?.[el][
                                      groupRadio === "My PT" ? "profitAndLoss" : "totalProfitAndLoss"
                                    ])
                                  }
                                </span>
                              </td>
                            ))}
                          </>
                        ) : (
                          <td colSpan="5" />
                        )}
                      </tr>
                    ))}
                    <tr>
                      <td />
                    </tr>
                  </Fragment>
                ))}
            </tbody>
          </Table>
        ) : <Fragment />}
        {resData && !!resData?.outrights?.length && (
          <Table variant="dark">
            <thead>
              <tr>
                {["", "Stake", "P&L", "", "Stake", "P&L"].map((title, index) => (
                  <th key={index} scope="col" className="center-text">
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resData.outrights.map(item => (
                <Fragment key={Math.random()}>
                  <tr className="event-title">
                    <td colSpan="6" className="td-buttons">
                      <div className="justify-content-start">
                        <BetBreakdownIcon marketId={item.marketId} className='' />
                        {item.competitionName.replace(
                          "${HH:mm}",
                          moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))} -{item.eventName.replace(
                            "${HH:mm}",
                            moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))} -{item.marketName.replace(
                              "${HH:mm}",
                              moment.unix(item.marketTime / 1000).utcOffset(330).format("HH:mm"))}
                      </div>
                    </td>
                  </tr>
                  {
                    outrightsTable(item.selections).map(el => el)
                  }
                  <tr>
                    <td />
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {resData && !!!resData?.outrights?.length && !!!resData?.nonOutrights?.length ?
          <div>
            <span>There are currently no matched bets for this sport</span>
          </div>
          : ''}
      </div>
    </div>
  ) : (
    (breakdownTarget && (
      <MarketBreakdown
        targetUser={targetUser ? targetUser : { userId: userName, userLevel }}
        marketId={breakdownTarget}
        closeBreakdown={() => setBreakdownTarget(null)}
      />
    )) ||
    (WorkstationTarget && (
      <WorkStation
        marketId={WorkstationTarget.marketId}
        eventId={WorkstationTarget.eventId}
        closeBreakdown={() => setWorkstationTarget(null)}
      />
    )) ||
    (downline && (
      <Downline
        marketId={downline}
        closeDownline={() => {
          setDownline(null)
          setLadder(false)
        }}
        open={ladder}
      />
    ))
  )
}
