import React, { useEffect, useState } from 'react'
import './PlsummaryReport.css';
import DatePicker from '../../components/DatePicker';
import moment from 'moment';
import ButtonCustom from '../../components/ButtonCustom';
import { useAuth } from '../../Providers/AuthProvider';
import { Table } from 'react-bootstrap';

const PlsummaryReport = () => {
    const { requestsToApi } = useAuth()

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const now = new Date()
    const startOfToday = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
    const endOfToday = moment(startOfToday).add(1, "days").subtract(1, "seconds")
    const [dateTimeRange, setDateTimeRange] = useState({
        start: startOfToday,
        end: endOfToday
    });


    const getPnlData = async () => {
        setLoading(true);
        if (sessionStorage.getItem('myUserName')) {
            const date = new Date(dateTimeRange.start);
            const getTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999).getTime();
            const url = `/agency/MOGAMBOHKD/report/pl-summary?memberCode=${sessionStorage.getItem('myUserName')}&date=${getTime}`;
            const response = await requestsToApi.get(url);
            if (response?.status === 200) {
                setData(response.data.result);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        getPnlData();
    }, []);

    const reColor = (num) => {
        if (typeof num === 'string') {
            num = parseFloat(num);
        }
        if (num < 0) {
            return `text-red`
        } else {
            return `text-green`
        }
    }

    const getPercentage = (profit, Turnover) => {
        if (!Turnover) {
            return 'N/A'
        }
        const perc = (profit / Turnover) * 100;
        return perc.toFixed(2);
    }

    const formatecolorPercentage = (value) => {
        if (value < 0) {
            return <span className="text-danger">{(-Number(value))?.toLocaleString('en-In')}%</span>
        } else {
            return <span className="text-success">{(value)?.toLocaleString('en-In')}%</span>
        }
    }

    return (
        <div>
            <div className="header-plsummary d-flex align-items-center justify-content-between">
                <span className='d-flex align-items-center justify-content-between'>
                    <h1>P & L Summary Report</h1>
                    <span className=' ml-3'>
                        <DatePicker
                            dateTimeRange={dateTimeRange}
                            onChangeFunc={result => {
                                setDateTimeRange(result)
                            }}
                            showTime={false}
                        />
                    </span>
                </span>
                <ButtonCustom onClick={() => getPnlData()}>Apply</ButtonCustom>
            </div>
            <div id='pnlSummaryStatement'>
                <span className='subheading-pl mb-4'>
                    Profit And Loss Summary Report for {moment(dateTimeRange.start).format('DD/MM/YYYY')}
                </span>
                {!loading && <Table striped hover variant="dark">
                    <thead>
                        <th>#</th>
                        <th>Commission</th>
                        <th>No Of Transactions</th>
                        <th>Profit and Loss</th>
                        <th>TurnOver</th>
                        <th>Percentage</th>

                    </thead>
                    <tbody>
                        {
                            !loading && data?.map((item, index) => (
                                <tr className=' cursor-pointer' key={index}>
                                    <td>{item?.SPORT}</td>
                                    <td className={`${reColor(item.COMM)}`}>{item?.COMM || '0'}</td>
                                    <td className={`${reColor(item.NO_TXN)}`}>{item?.NO_TXN || '0'}</td>
                                    <td className={`${reColor(item.PL)}`}>{item?.PL || '0'}</td>
                                    <td className={`${reColor(item.TURNOVER)}`} >{item?.TURNOVER || '0'}</td>
                                    <td>{formatecolorPercentage(getPercentage(Number(item?.PL), Number(item?.TURNOVER)))}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </Table>}
                {
                    loading && (
                        <div className=' w-full d-flex align-items-center justify-content-center' style={{ height: "60vh", width: "100%" }}>
                            <div class="spinner-grow text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default PlsummaryReport;
