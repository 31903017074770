export const reports = [
    {
        created: new Date(),
        completed:  new Date(),
        bankManager: 21201201,
        username: "valeriia",
        supervisor: 132141324,
        id: 23413243213,
        amount: 5000,
        utrNo: "ADF15467896535",
        status: "PENDING"
    },
    {
        created: new Date(),
        completed:  new Date(),
        bankManager: 21201201,
        username: "valeriia",
        supervisor: 132141324,
        id: 23413243213,
        amount: 5000,
        utrNo: "ADF15467896535",
        status: "PENDING"
    },
    {
        created: new Date(),
        completed:  new Date(),
        bankManager: 21201201,
        username: "valeriia",
        supervisor: 132141324,
        id: 23413243213,
        amount: 5000,
        utrNo: "ADF15467896535",
        status: "PENDING"
    },
    {
        created: new Date(),
        completed:  new Date(),
        bankManager: 21201201,
        username: "valeriia",
        supervisor: 132141324,
        id: 23413243213,
        amount: 5000,
        utrNo: "ADF15467896535",
        status: "PENDING"
    },
    {
        created: new Date(),
        completed:  new Date(),
        bankManager: 21201201,
        username: "valeriia",
        supervisor: 132141324,
        id: 23413243213,
        amount: 5000,
        utrNo: "ADF15467896535",
        status: "PENDING"
    }
]
