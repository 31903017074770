import "./index.scss"
import { useEffect, useState } from "react"
import { marketOptionsId, sportOptionsId } from "../BetTickerPage/betTickerData"
import { useAuth } from "../../Providers/AuthProvider"
import { useStorage } from "../../Providers/StorageProvider"
import axios from "axios"
// import InfoPopUp from "../../components/InfoPopUp"

export const DepositEntryPage = () => {
  let [showOption, setShowOption] = useState(false)
  let [selectedAccount, setSelectedAccount] = useState("Account No 1")
  const { requestsToApi, requestsToApiPayment} = useAuth()
  const { userName, setIsLoading } = useStorage()

  const handleListDisplay = () => {
    setShowOption(prevState => !prevState)
  }
  const handleOptionClick = e => {
    setSelectedAccount(e.target.innerHTML)
    setShowOption(false)
  }

  const getDepositAcc = async () => {
    try {
      // const response = await requestsToApi.get("Banking/deposit/payment-gateways");
      const response = await requestsToApiPayment.get("Banking/deposit/payment-gateways");
      console.log(response)
    } catch (error) {
      console.log(error?.message)
    }
  }
  useEffect(() => {
    getDepositAcc().then(r => console.log(r))
  }, [])


  const account = ["Account No 1", "Account No 2", "Account No 3"]
  return (
    <div className="deposit-entry__main">
      <h1>Deposit Entry</h1>
      <div className="deposit-entry">
        <div className="deposit-entry__top">
          <span className="deposit-entry__top-select-label deposit-entry__label">Account No.</span>
          {/*<select className="deposit-entry__top-select" name="" id="">*/}
          {/*    <option >Account No 1</option>*/}
          {/*    <option >Account No 2</option>*/}
          {/*    <option >Account No 3</option>*/}
          {/*</select>*/}
          {/*<InfoPopUp></InfoPopUp>*/}
          <div className="deposit-entry__pop-up"> Successfully saved</div>
          <div className="deposit-entry__top-select-custom">
            <div
              className={showOption ? "deposit-entry__top-select-custom-selected active" : "deposit-entry__top-select-custom-selected"}
              onClick={handleListDisplay}>
              {selectedAccount}
            </div>
            {showOption && (
              <ul className="deposit-entry__top-select-custom-options">
                {account.map(acc => <li className="deposit-entry__top-select-custom-option" key={acc}
                                        onClick={handleOptionClick}>{acc}</li>)}
              </ul>
            )}
          </div>
        </div>
        <div className="deposit-entry__form-wrapper">
          <h2 className="deposit-entry__form-title">Deposit Entry</h2>
          <form className="deposit-entry__form" action="">
            <div className="deposit-entry__form-body">
              <div className="deposit-entry__form-input-wrapper">
                <label className="deposit-entry__top-input-label deposit-entry__label">Amount</label>
                <input type="text" className="deposit-entry__form-input" placeholder="Amount" />
              </div>
              <div className="deposit-entry__form-input-wrapper">
                <label className="deposit-entry__top-input-label deposit-entry__label">UTR or Ref No</label>
                <input type="text" className="deposit-entry__form-input" placeholder="UTR" />
              </div>
            </div>
            <div className="deposit-entry__form-footer">
              <button className="deposit-entry__form-button deposit-entry__form-reset">
                <span>RESET</span>
              </button>
              <button className="deposit-entry__form-button deposit-entry__form-save">
                <span>Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
