import './index.scss'
import { Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../utils/dataTransform'
import { useAuth } from '../../../Providers/AuthProvider'
import StringToRecolorNumber from '../../../components/StringToRecolorNumber'
import { useStorage } from '../../../Providers/StorageProvider'



export default ({ userId, userLevel }) => {
  const { requestsToApi } = useAuth();
  const [responseData, setResponseData] = useState(null);
  const { userName } = useStorage();

  const tableTemplate = [
    ["actualNetExposure", "Net Exposure", true],
    ["cashBalance", "Balance Down", true],
    ["totalBalance", "Balance Up", true],
    ["smaCredit", "Credit Limit", false],
    ["availableBalance", "Available Credit", false]

  ]
  if (userLevel === "Agent") {
    tableTemplate.push(
      ["totalMACredit", `Total credit distributed by MAs`, false],
      ["totalAgentCredit", `Total credit distributed by Agents`, false]
    )
  } else {
    tableTemplate.push(
      ["totalDownlineCredit", `Total credit given to ${userLevel === "MA" ? "Members" : "MAs"}`, false],
      ["totalMACredit", `Total credit distributed by MAs`, false],
      ["totalAgentCredit", `Total credit distributed by Agents`, false]
    )
  }

  useEffect(async () => {
    try {
      const response = await requestsToApi.get(`agency/${userName}/account/balance?dl=${userId}&showDownlineView=true`)
      setResponseData(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }, [])

  const formatData = (num, recolor) => {
    return recolor ?
      <StringToRecolorNumber>{num}</StringToRecolorNumber> :
      <span>{numberWithCommas(num)}</span>
  }

  return <div className="balance-report">

    <div className="balance-body">
      <Table striped hover variant="dark">
        <tbody>
          {responseData ? tableTemplate.map(([key, title, recolor]) => (
            <tr key={title}>
              <th className="" >{title}</th>
              <td className="" >{formatData(responseData[key], recolor)}</td>
            </tr>
          )) : <></>}
        </tbody>
      </Table>
    </div>
  </div>
}