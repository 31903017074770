import "./index.scss"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

const modalRoot = document.querySelector("#modal-root");

export default ({ visible, closeFunc, data }) => {
  let [report, setReport] = useState({});

  useEffect(() => {
    setReport(data);
  }, [data]);

  const changeReport = (e) => {
    e.stopPropagation();
    setReport({ [e.target.name]: e.target.value });
  };
  const clickReset = (e) => {
    e.preventDefault();
    closeFunc();
  };
  const clickSave = (e) => {
    e.preventDefault();
    closeFunc();
  };

  return !visible ? (<> </>) : createPortal (
    <div className="deposit-entry-pop-up">
      <div className="deposit-entry-pop-up__form-wrapper">
        <div className="deposit-entry-pop-up__title_row">
          <span className="deposit-entry-pop-up__title_row-title">Update Deposit Entry</span>
          <span className="deposit-entry-pop-up__title_row-id">#{data.id}</span>
        </div>
        <form className="deposit-entry-pop-up__form" action="">
          <div className="deposit-entry-pop-up__form-body">
            <div className="deposit-entry-pop-up__form-input-wrapper">
              <label className="deposit-entry-pop-up__top-input-label deposit-entry__label">Amount</label>
              <input type="text" className="deposit-entry-pop-up__form-input"
                     placeholder="Amount"
                     value={report?.amount}
                     name={'amount'}
                     onChange={changeReport}
              />
            </div>
            <div className="deposit-entry-pop-up__form-input-wrapper">
              <label className="deposit-entry-pop-up__top-input-label deposit-entry__label">UTR or Ref No</label>
              <input type="text" className="deposit-entry-pop-up__form-input"
                     placeholder="UTR"
                     value={report?.utrNo}
                     name={'utrNo'}
                     onChange={changeReport}/>
            </div>
          </div>
          <div className="deposit-entry-pop-up__form-footer">
            <button onClick={clickReset} className="deposit-entry-pop-up__form-button deposit-entry__form-reset">
              <span>RESET</span>
            </button>
            <button onClick={clickSave} className="deposit-entry-pop-up__form-button deposit-entry__form-save">
              <span>Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>,
    modalRoot
  )
}
