import { useStorage } from "../../Providers/StorageProvider";
import moment from "moment"
import { useEffect, useState } from "react";

export default ({ children, format, str = false }) => { //<CurrentTime format=>{}</CurrentTime>
  const { activeTimeSetting } = useStorage();
  const [gmt, setGmt] = useState(activeTimeSetting?.gmt.slice(1).split(":").map(item => Number(item)));

  useEffect(() => {
    setGmt(activeTimeSetting?.gmt.slice(1).split(":").map(item => Number(item)))
  }, [activeTimeSetting])

  const getCurrentTime = () => {
    let currentTime = Number(children);
    if (activeTimeSetting.gmt[0] === "+") currentTime = currentTime + (gmt[0] * 3600 + gmt[1] * 60) * 1000
    if (activeTimeSetting.gmt[0] === "-") currentTime = currentTime - (gmt[0] * 3600 + gmt[1] * 60) * 1000
    return currentTime
  }

  if (str) {
    return activeTimeSetting ? moment.utc(getCurrentTime()).format(format) : null
  } else {
    return <span>
      {activeTimeSetting ? moment.utc(getCurrentTime()).format(format) : null}
    </span>
  }
}

export const getTime = (format, currentTime, activeTimeSetting) => {
  const gmt = activeTimeSetting?.gmt.slice(1).split(":").map(item => Number(item));

  const getCurrentTime = () => {
    if (activeTimeSetting.gmt[0] === "+") currentTime = currentTime + (gmt[0] * 3600 + gmt[1] * 60) * 1000
    if (activeTimeSetting.gmt[0] === "-") currentTime = currentTime - (gmt[0] * 3600 + gmt[1] * 60) * 1000
    return currentTime
  }

  return activeTimeSetting ? moment.utc(getCurrentTime()).format(format) : null
}