export const positiveOrNegative = el => (+el >= 0 ? "-positive" : "-negative")
export const filterElements = element => {
  let array = [
    { profitAndLoss: "", totalProfitAndLoss: "" },
    { profitAndLoss: "", totalProfitAndLoss: "" },
    { profitAndLoss: "", totalProfitAndLoss: "" }
  ]
  element?.forEach(el => {
    if (el.selectionDisplayName === "1") {
      array[0] = el
    } else if (el.selectionDisplayName === "2") {
      array[2] = el
    } else {
      array[1] = el
    }
  })
  return array
}

export const sortElments = elements => {
  return Object.keys(elements)
    .map(name => elements[name])
    .sort((x, y) => x.userName.length - y.userName.length)
}
