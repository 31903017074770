import { Table } from "react-bootstrap"
import StringToRecolorNumber from "../../components/StringToRecolorNumber"
import CurrentTime from "../../components/CurrentTime"

export default ({ data, openMarket }) => {
  return (
    <div style={{ maxHeight: "38rem", overflow: "auto" }}>
      <Table striped hover variant="dark">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th></th>
            <th style={{ textAlign: "right" }}>P&L</th>
            <th style={{ textAlign: "right" }}>Credit Limit</th>
            <th style={{ textAlign: "right" }}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {data && data.data && data.data.length ? (
            data.data.map(item => {
              return (
                <tr key={item.date + Math.random()}>
                  <td>{<CurrentTime format="DD/MM/YYYY HH:mm:ss">{item.date}</CurrentTime>}</td>
                  <td>{item.actionDisplayName}</td>
                  <td>
                    <a className="no-href" onClick={() => openMarket(item.description?.market)}>
                      {
                        item.actionDisplayName == "Transfer" ?
                         item.pnl > 0 ?"Deposit" :"Withdraw":
                      item.description?.eventName + " - " + item.description?.marketName
                      }
                    </a>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <StringToRecolorNumber>{item.pnl}</StringToRecolorNumber>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.creditLimit ? <StringToRecolorNumber>{item.creditLimit}</StringToRecolorNumber> : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <StringToRecolorNumber>{item.balance}</StringToRecolorNumber>
                  </td>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </div>
  )
}
