import Modal from '../ModalCustom'
import './index.scss'
import ButtonCustom from '../ButtonCustom';
import { Button } from 'react-bootstrap';

export default ({ editingItem, closeFunc, confirmFunc }) => {

  return (
    editingItem ?
      <Modal className="modal-accept-lock" visible width="350px">
        <div className="modal-accept-lock__content">
          <h1>{`Are you sure you want to ${editingItem.isLocked ? "un" : ""}lock ${editingItem.name}?`}</h1>
          <div className="modal-accept-lock__controls">
            <Button
              className="cansel-button"
              variant="link"
              onClick={() => closeFunc(false)}
            >
              Cancel
                        </Button>
            <ButtonCustom onClick={confirmFunc} width='76px' height='32px' color='green'>Confirm</ButtonCustom>
          </div>
        </div>

      </Modal> :
      <></>
  )
}