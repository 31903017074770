import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import AlertCustom from "../../components/AlertCustom"
import ModalTransfer from "../../components/ModalTransfer"
import { useAuth } from "../../Providers/AuthProvider"
import { useStorage } from "../../Providers/StorageProvider"
import { numberWithCommas, positiveNumberWithCommas } from "../../utils/dataTransform"
import "./index.scss"

export default React.memo(() => {
  const [transferData, setTransferData] = useState([])
  const { requestsToApi } = useAuth()
  const [userForTransfer, setUserForTransfer] = useState(null)
  const { userName, userLevel, balance } = useStorage()
  const [alertMessage, setAlertMessage] = useState(null)

  const getTransferDataReq = async () => {
    try {
      const response = await requestsToApi.get(`agency/${userName}/agency-mgmt/transfer`)
      setTransferData(response.data.result)
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    if (!userForTransfer) getTransferDataReq()
  }, [userForTransfer])

  const balanceTextController = (balance, field) => {
    if (!balance) return "-"
    else if (balance > 0) {
      if (field === "take") return "-"
      else if (field === "give") {
        return positiveNumberWithCommas(balance)
      }
    } else {
      if (field === "take") {
        return positiveNumberWithCommas(balance)
      } else if (field === "give") return "-"
    }
  }

  const countSum = (title, key) => {
    let arr = transferData.map(item => item[title])
    if (key === "take") arr = arr.filter(item => item < 0)
    else if (key === "give") arr = arr.filter(item => item > 0)
    const sum = arr.reduce((a, b) => a + b, 0)
    return positiveNumberWithCommas(sum)
  }

  return (
    <div className="transfer-page">
      <div className="page-header">
        <h1>Transfer</h1>
      </div>
      {userLevel === "Agent" || userLevel === "MA" ? (
        <div style={{ marginBottom: "10px" }}>
          Available to take: {numberWithCommas(balance.cashBalance + balance.totalBalance + balance.availableBalance)}
        </div>
      ) : (
        <></>
      )}

      <Table striped hover id="transfer-table" variant="dark">
        <thead>
          <tr>
            <th scope="col" className="text-align-center">
              Login Name
            </th>
            <th scope="col" className="text-align-center">
              ID
            </th>
            <th scope="col">Take</th>
            <th scope="col">Give</th>
            {userLevel !== "Agent" ? <th scope="col">Give Available</th> : <></>}
            <th scope="col">Payment Threshold</th>
            <th scope="col">Credit Limit</th>
            {userLevel === "Agent" ? <th scope="col">Available Balance</th> : <></>}
            <th scope="col" className="text-align-center">
              {userLevel === "Agent" ? "Transfer" : "Transfer button"}
            </th>
          </tr>
        </thead>
        <tbody>
          {transferData ? (
            transferData.map(item => (
              <tr key={Math.random()}>
                <td className="text-align-center">{item.loginName}</td>
                <td className="text-align-center">{item.name}</td>
                <td className={balanceTextController(item.balanceUp, "take") !== "-" ? "-positive" : ""}>
                  {balanceTextController(item.balanceUp, "take")}
                </td>
                <td className={balanceTextController(item.balanceUp, "give") !== "-" ? "-negative" : ""}>
                  {balanceTextController(item.balanceUp, "give")}
                </td>
                {userLevel !== "Agent" ? <td>-</td> : <></>}
                <td>{item.paymentThreshold ? item.paymentThreshold : "-"}</td>
                <td>{numberWithCommas(item.creditLimit)}</td>
                {userLevel === "Agent" ? <td>{numberWithCommas(item.availableBalance)}</td> : <></>}

                <td className="text-align-center ">
                  <a
                    onClick={!!item.balanceUp ? () => setUserForTransfer(item) : () => {}}
                    disabled={!item.balanceUp}
                    className={!!item.balanceUp ? "" : "disabled"}
                  >
                    Transfer
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
          <tr>
            <td></td>
            <td className="text-align-center">Total</td>
            <td className="-positive">
              {countSum("balanceUp", "take") !== "0.00" ? countSum("balanceUp", "take") : "-"}
            </td>
            <td className="-negative">
              {countSum("balanceUp", "give") !== "0.00" ? countSum("balanceUp", "give") : "-"}
            </td>
            {userLevel !== "Agent" ? <td>-</td> : <></>}
            <td>{countSum("paymentThreshold") !== "0.00" ? countSum("paymentThreshold") : "-"}</td>

            <td>{countSum("creditLimit") !== "0.00" ? countSum("creditLimit") : "-"}</td>
            {userLevel === "Agent" ? (
              <td>{countSum("availableBalance") !== "0.00" ? countSum("availableBalance") : "-"}</td>
            ) : (
              <></>
            )}
            <td></td>
          </tr>
        </tbody>
      </Table>
      {!!userForTransfer ? (
        <ModalTransfer
          closeFunc={() => setUserForTransfer(null)}
          user={userForTransfer}
          successFunc={() => setAlertMessage("Transfer was successful!")}
        />
      ) : (
        <></>
      )}
      <AlertCustom type="green" timeout={3000} disableFunc={() => setAlertMessage(null)}>
        {alertMessage}
      </AlertCustom>
    </div>
  )
})
